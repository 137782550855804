import { createAction } from '@ngrx/store';


export const syncAuthorizationAction = createAction(
    '[Local Storage] Sync Authorization',
);

export const syncDeviceAction = createAction(
    '[Local Storage] Sync Device',
);
