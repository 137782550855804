import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { LocationWidget } from '../../../../schemas/location.schemas';


const logger = new Logger('LocationWidgetFormComponent');

@Component({
    selector: 'app-location-widget-form',
    templateUrl: './location-widget-form.component.html',
    styleUrls: ['./location-widget-form.component.scss'],
})
export class LocationWidgetFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    form: FormGroup;

    @Input() locationWidget?: LocationWidget | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor() {

        this.form = new FormGroup({
            title: new FormControl(null),
            icon: new FormControl(null),
            ordering: new FormControl(0, Validators.min(0)),
            rowspan: new FormControl(1, Validators.min(1)),
            colspan: new FormControl(1, Validators.min(1)),
            captcha: new FormControl(false),
            params: new FormControl(''),
        });

    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.locationWidget?.currentValue) {
            const locationWidgetParams = changes.locationWidget?.currentValue.params;
            const params = JSON.stringify(locationWidgetParams, null, 4);

            this.form.patchValue({
                ...changes.locationWidget?.currentValue,
                ordering: locationWidgetParams?.ordering ?? 0,
                captcha: locationWidgetParams?.captcha ?? false,
                rowspan: locationWidgetParams?.grid?.rowspan ?? 1,
                colspan: locationWidgetParams?.grid?.colspan ?? 1,
                params,
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const params = JSON.parse(this.form.value.params || '{}');
        params.grid = {};

        params.grid.rowspan = this.form.value.rowspan ?? 1;
        params.grid.colspan = this.form.value.colspan ?? 1;
        params.ordering = this.form.value.ordering ?? 0;
        params.captcha = this.form.value.captcha ?? false;

        const result = {
            ...this.locationWidget,
            ...this.form.value,
            params,
        };

        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }
}
