import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { GeoRouteApi } from '../services/api/geo/geo-route.api';


const logger = new Logger('GeoRouteResolver');

@Injectable({
    providedIn: 'root',
})
export class GeoRouteResolver implements Resolve<schemas.GeoRoute | null> {

    constructor(
        private geoRouteApi: GeoRouteApi,
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<schemas.GeoRoute | null> {
        const geoRouteId = Number(route.paramMap.get('routeId'));
        logger.debug('resolve (routeId)', geoRouteId);

        return this.geoRouteApi.fetchGeoRouteById(geoRouteId);
    }

}
