import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GeoUiModule } from '../../ui/geo/geo.module';
import { LoadingUiModule } from '../../ui/loading/loading.module';
import { ScrollUiModule } from '../../ui/scroll/scroll-ui.module';
import { GeoPageComponent } from './geo-page/geo-page.component';
import { GeoPrimitiveAddComponent } from './geo-primitive-add/geo-primitive-add.component';
import { GeoPrimitiveEditComponent } from './geo-primitive-edit/geo-primitive-edit.component';
import { GeoPrimitiveFormComponent } from './geo-primitive-form/geo-primitive-form.component';
import { GeoPrimitiveListComponent } from './geo-primitive-list/geo-primitive-list.component';
import { GeoRouteAddComponent } from './geo-route-add/geo-route-add.component';
import { GeoRouteEditComponent } from './geo-route-edit/geo-route-edit.component';
import { GeoRouteFormComponent } from './geo-route-form/geo-route-form.component';
import { GeoRouteListComponent } from './geo-route-list/geo-route-list.component';


@NgModule({
    declarations: [
        GeoRouteListComponent,
        GeoRouteAddComponent,
        GeoRouteFormComponent,
        GeoPrimitiveListComponent,
        GeoPrimitiveAddComponent,
        GeoPrimitiveFormComponent,
        GeoPrimitiveEditComponent,
        GeoPageComponent,
        GeoRouteEditComponent,
    ],
    imports: [
        CommonModule,
        ScrollUiModule,
        LoadingUiModule,
        GeoUiModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
    ],
})
export class GeoModule {}
