import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    ActivatedRoute,
    ParamMap,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { WidgetFacade } from '../../../facades/widget.facade';
import { LicenseCreate } from '../../../schemas/widget.schemas';


const logger = new Logger('LicenseAddComponent');

@Component({
    selector: 'app-license-add',
    templateUrl: './license-add.component.html',
    styleUrls: ['./license-add.component.scss'],
})
export class LicenseAddComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    cannotSubmit = false;
    formError?: schemas.ResponseError;
    paramMap?: ParamMap;

    constructor(
        private widgetFacade: WidgetFacade,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.paramMap.subscribe(paramMap =>
                this.paramMap = paramMap,
            ),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormSubmit(formData: LicenseCreate): void {
        const capsuleId = Number(this.paramMap?.get('capsuleId'));
        const locationId = Number(this.paramMap?.get('locationId'));

        logger.debug('onFormSubmit', formData);

        if (!capsuleId || !locationId) {
            return;
        }

        this.cannotSubmit = true;
        this.widgetFacade.createLicense({
            ...formData,
            capsuleId,
            locationId,
        }).subscribe(
            this.goBack.bind(this),
            error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        );
    }

    goBack(): void {
        this.router.navigate(['../..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
