import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Data,
    NavigationEnd,
    Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from '../schemas/app.schemas';


@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {

    // Subject emitting the breadcrumb hierarchy
    private readonly breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);

    // Observable exposing the breadcrumb hierarchy
    readonly breadcrumbs$ = this.breadcrumbsSubject.asObservable();

    constructor(private router: Router) {
        this.router.events.pipe(
            // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
            filter((event) => event instanceof NavigationEnd),
        ).subscribe(event => {
            // Construct the breadcrumb hierarchy
            const root = this.router.routerState.snapshot.root;
            const breadcrumbs: Breadcrumb[] = [{
                label: 'Home',
                url: '/',
            }];
            this.addBreadcrumb(root, [], breadcrumbs);

            // Emit the new hierarchy
            this.breadcrumbsSubject.next(breadcrumbs);
        });
    }

    private addBreadcrumb(
        route: ActivatedRouteSnapshot | null,
        parentUrl: string[],
        breadcrumbs: Breadcrumb[],
    ): void {
        if (route) {
            // Construct the route URL
            const routeUrl = parentUrl.concat(route.url.map(url => url.path));

            // Add an element for the current route part
            if (route.data.breadcrumb) {
                const breadcrumb = {
                    label: this.getLabel(route.data),
                    url: '/' + routeUrl.join('/'),
                };

                if (breadcrumb.label !== breadcrumbs[breadcrumbs.length - 1]?.label) {
                    breadcrumbs.push(breadcrumb);
                }
            }

            // Add another element for the next route part
            this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
        }
    }

    protected getLabel(data: Data): string {
        // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
        return typeof data.breadcrumb === 'function'
            ? data.breadcrumb(data)
            : data.breadcrumb;
    }

}
