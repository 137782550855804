<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

    <div class="form-group">
        <div class="title-form-group">ID рекламного места</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="adInventoryId"
                   [readonly]="isEdit"
                   [class.isReadOnly]="isEdit"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['adInventoryId'].invalid && form.controls['adInventoryId'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">ID локации, в которой должна отображаться рекомендация</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="locationId"
                   [readonly]="isEdit"
                   [class.isReadOnly]="isEdit"
            >
            <span class="invalid-feedback"
                  *ngIf="form.controls['locationId'].invalid && form.controls['locationId'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">ID рекомендованной локации</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="recLocationId"
                   [readonly]="isEdit"
                   [class.isReadOnly]="isEdit"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['recLocationId'].invalid && form.controls['recLocationId'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Порядок</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="order"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['order'].invalid && form.controls['order'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <button class="btn btn-primary"
                [disabled]="cannotSubmit || form.invalid || !!formError">
            Сохранить
        </button>
        <button class="btn btn-secondary" (click)="onFormCancel()">Отмена</button>
        <div *ngIf="formError">{{ formError.message }}</div>
    </div>

</form>
