<div class="upload-images">

    <div class="upload-images__description">
        {{ title || 'Параметры' }}: не более {{ maxCountImages }} изображений(я)
    </div>

    <cdk-virtual-scroll-viewport itemSize="1" class="virtual-scroll-viewport">
        <div class="upload-images__overflow">

            <div *ngIf="convertedImages && convertedImages.length < maxCountImages"
                 (click)="chooseFile()"
                 class="upload-images__choose">
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33 31H38V33H33V38H31V33H26V31H31V26H33V31Z"
                          fill="#52565F"/>
                    <rect x="1" y="1" width="62" height="62" rx="4" stroke="#52565F" stroke-width="2"
                          stroke-dasharray="4 4"/>
                </svg>
            </div>

            <div cdkDropList cdkDropListOrientation="horizontal" class="upload-images__previews"
                 [cdkDropListAutoScrollDisabled]="false" [cdkDropListAutoScrollStep]="10"
                 (cdkDropListDropped)="drop($event)">
                <div *ngFor="let image of convertedImages;let index = index"
                     class="upload-images__cdk-preview"
                     cdkDrag>
                    <ui-preview-image
                            [image]="image.original"
                            [canEdit]="!!image.cropper"
                            [isActive]="currentImage === image.original"
                            [order]="index+1"
                            (deletePreviewImage)="onDeletePreviewImage($event)"
                            (editImage)="editImage($event)"
                            (showImage)="showImage($event)">
                    </ui-preview-image>
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
    <div>
        <app-image-cropper
                *ngIf="indexCroppedImage !== undefined"
                [image]="convertedImages[indexCroppedImage]"
                [maintainAspectRatio]="maintainAspectRatio"
                (changeImage)="changeImage($event)">
        </app-image-cropper>
    </div>

    <div class="upload-images__preview" *ngIf="currentImage === previewImage">
        <img [src]="previewImage" alt="preview">
    </div>

    <input #fileInput type="file" name="file" multiple="multiple" accept="image/*"
           (change)="changeFile($event)">
</div>
