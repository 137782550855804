import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Widget,
    WidgetFindParams,
} from '../../../schemas/widget.schemas';


const logger = new Logger('WidgetPageComponent');

@Component({
    selector: 'app-widget-page',
    templateUrl: './widget-page.component.html',
    styleUrls: ['./widget-page.component.scss'],
})
export class WidgetPageComponent {

    widgetFindParams: WidgetFindParams = {
        limit: 0,
    };

    constructor(
        private router: Router,
    ) { }

    onWidgetClick(widget: Widget): void {
        this.router.navigate(['/widgets', widget.id])
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
