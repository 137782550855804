import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { tap } from 'rxjs/operators';
import { defaultLimit } from '../../../app.constants';
import { GeoRouteFacade } from '../../../facades/geo/geo-route.facade';


const logger = new Logger('GeoRouteListComponent');

@Component({
    selector: 'app-geo-route-list',
    templateUrl: './geo-route-list.component.html',
    styleUrls: ['./geo-route-list.component.scss'],
})
export class GeoRouteListComponent {

    isLoading = false;
    geoRoutes: schemas.GeoRoute[] = [];
    offset = 0;
    limit = defaultLimit;

    constructor(
        private geoRouteFacade: GeoRouteFacade,
        private router: Router,
    ) {
    }

    fetchContent(): void {
        this.isLoading = true;

        const params: schemas.GeoRouteFindParams = {
            offset: this.offset,
            limit: this.limit,
        };

        this.geoRouteFacade.fetchGeoRoutes(params).pipe(
            tap(routes => logger.debug('fetchGeoRoutes (routes)', routes)),
        ).subscribe(routes => {
            if (!routes) {
                this.isLoading = false;
                return;
            }

            this.geoRoutes.push(...routes);

            if (routes.length < this.limit) {
                this.offset += routes.length;
            } else {
                this.offset += this.limit;
            }
            this.isLoading = false;
        });
    };

    deleteGeoRoute(route: schemas.GeoRoute): void {
        if (confirm('Удалить маршрут ' + route.title + '?')) {
            this.geoRouteFacade.deleteGeoRouteById(route.id).subscribe(result => {
                if (result) {
                    this.geoRoutes = this.geoRoutes.filter(
                        item => item.id !== route.id,
                    );
                }
            });
        }
    }

    goToEditGeoRoute(route: schemas.GeoRoute): void {
        logger.debug('route', route);
        this.router.navigate(['geo', 'route', route.id]).then(() => logger.debug('go to route'));
    }
}
