import {
    CdkDragDrop,
    moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { MediaFacade } from '../../../facades/media.facade';
import { Logger } from '../../../services';
import { ImageWithCropper } from '../upload.schemas';


const logger = new Logger('UploadCroppedImagesComponent');

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-upload-cropped-images',
    templateUrl: './upload-cropped-images.component.html',
    styleUrls: ['./upload-cropped-images.component.scss'],
})
export class UploadCroppedImagesComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() title?: string;
    @Input() images: string[] = [];
    @Input() canEmitChanges = false;
    @Input() maxCountImages = 7;
    @Input() allowFormats = 'png,jpg';
    @Input() maxImageSize = 2;
    @Input() allowRatio: number = 16 / 9;
    @Input() maintainAspectRatio = false;

    @Output() uploadImages = new EventEmitter<string[]>();

    @ViewChild('fileInput') fileInputElementRef?: ElementRef;

    private fileInputElement?: HTMLElement;

    defaultCompressMaxHeight = 1000;
    defaultCompressMaxWidth = 1000;

    convertedImages: ImageWithCropper[] = [];
    currentImage = '';
    indexCroppedImage?: number;
    previewImage?: string;
    defaultCropperSize = 10000;
    uploadedImages: {
        uri: string;
        order: number;
    }[] = [];

    constructor(
        private toastrService: ToastrService,
        private imageCompress: NgxImageCompressService,
        private mediaFacade: MediaFacade,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        logger.debug('changes', changes);

        if (changes?.images?.currentValue) {
            this.convertedImages = this.convertData(this.images);
            this.indexCroppedImage = undefined;
        }

        if (changes?.canEmitChanges && changes.canEmitChanges.currentValue) {
            this.uploadCroppedImages()
                .then(result => {
                    logger.debug('result promise', result);
                    this.uploadedImages.sort((a, b) => a.order > b.order ? 1 : -1);
                    this.uploadedImages.forEach(image => {
                        this.images.push(image.uri);
                    });
                    this.convertedImages = this.convertData(this.images);
                    this.indexCroppedImage = undefined;
                    this.uploadImages.emit(this.images);
                });
        }
    }

    ngOnInit(): void {
        if (this.images) {
            this.convertedImages = this.convertData(this.images);
        }
    }

    ngAfterViewInit(): void {
        this.fileInputElement = this.fileInputElementRef?.nativeElement;
    }

    uploadCroppedImages(): Promise<string[]> {
        const requests: any = [];
        this.images = [];
        this.uploadedImages = [];
        let index = 0;

        this.convertedImages.forEach(image => {
            if (image.cropper) {
                const order = index;
                requests.push(
                    this.mediaFacade.uploadMedia(this.mediaFacade.dataURItoBlob(image.cropped), image.filename || '')
                        .pipe(
                            tap(result => logger.debug('result', result)),
                            tap((result: { uri: string; }) => {
                                if (result.uri) {
                                    this.uploadedImages.push({
                                        uri: result.uri,
                                        order,
                                    });
                                } else {
                                    this.toastrService.error('Не удалось загрузить изображение');
                                }
                            }),
                        ).toPromise(),
                );
            } else {
                this.uploadedImages.push({
                    uri: image.original,
                    order: index,
                });
            }
            index++;
        });

        return Promise.all(requests);
    }

    convertData(images: string[]): ImageWithCropper[] {
        this.convertedImages = [];

        images?.forEach(image => {
            this.convertedImages.push({
                filename: '',
                original: image,
                cropped: image,
            });
        });

        return this.convertedImages;
    }

    chooseFile(): void {
        if (this.fileInputElement) {
            this.fileInputElement.click();
        }
    }

    changeFile(event: any): void {
        const files = [...event.target.files];
        if (this.convertedImages.length + files.length <= this.maxCountImages) {
            files.forEach(file => {
                this.imageProcess(event, file);
            });
        } else {
            this.toastrService.error('Количество загружаемых фото превышает лимит');
        }
    }

    imageProcess(event: any, file: any): void {
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const result = String(reader.result);

            if (this.convertedImages.find(x => x.original === result)) {
                this.toastrService.error('Такое фото уже было загружено');
                return;
            }

            this.imageCompress
                .compressFile(result, 1, 100, 100, this.defaultCompressMaxWidth, this.defaultCompressMaxHeight)
                .then(
                    (compressedImage) => {
                        this.convertedImages.unshift(
                            {
                                filename: file.name,
                                original: compressedImage,
                                cropped: compressedImage,
                                cropper: {
                                    x1: 0,
                                    y1: 0,
                                    x2: this.defaultCropperSize,
                                    y2: this.defaultCropperSize,
                                },
                            },
                        );

                        this.currentImage = this.convertedImages[0].original;
                        this.indexCroppedImage = 0;
                    },
                );
        };
        reader.readAsDataURL(file);
    }

    onDeletePreviewImage(image: string): void {
        if (!this.convertedImages) {
            return;
        }

        this.convertedImages.splice(this.convertedImages.findIndex(x => x.original === image), 1);
        this.previewImage = undefined;
    }

    editImage(image: string): void {
        this.previewImage = undefined;

        if (!this.convertedImages) {
            return;
        }

        const index = this.convertedImages.findIndex(x => x.original === image);

        if (index > -1) {
            this.currentImage = this.convertedImages[index].original;
            this.indexCroppedImage = index;
        }
    }

    showImage(image: string): void {
        this.previewImage = image;
        this.currentImage = image;
        this.indexCroppedImage = undefined;
    }

    changeImage(image: ImageWithCropper): void {
        const index = this.convertedImages.findIndex(x => x.original === image.original);

        if (index > -1) {
            this.convertedImages.splice(index, 1, image);
        }
    }

    drop(event: CdkDragDrop<{ title: string; poster: string }[]>): void {
        moveItemInArray(this.convertedImages, event.previousIndex, event.currentIndex);

        const image = this.convertedImages[event.currentIndex];

        if (image.cropper) {
            this.indexCroppedImage = event.currentIndex;
            this.previewImage = undefined;
        } else {
            this.indexCroppedImage = undefined;
            this.currentImage = image.original;
            this.previewImage = image.original;
        }
    }
}
