<image-cropper
        [imageBase64]="image?.original || ''"
        [cropper]="cropper"
        [maintainAspectRatio]="maintainAspectRatio"
        [containWithinAspectRatio]="false"
        [cropperMinWidth]="200"
        [onlyScaleDown]="true"
        [roundCropper]=false
        [canvasRotation]="0"
        [transform]="{}"
        [alignImage]="'center'"
        [style.display]="true"
        [backgroundColor]="''"
        [imageQuality]="50"
        (imageLoaded)="loadedImage($event)"
        (imageCropped)="imageCropped($event)"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed($event)"
></image-cropper>
