import { Component } from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { WidgetPackageFacade } from '../../../../facades/widget-package.facade';
import { WidgetPackageCreate } from '../../../../schemas/widget.schemas';


const logger = new Logger('WidgetPackageAddComponent');

@Component({
    selector: 'app-widget-package-add',
    templateUrl: './widget-package-add.component.html',
    styleUrls: ['./widget-package-add.component.scss'],
})
export class WidgetPackageAddComponent {

    cannotSubmit = false;
    formError?: schemas.ResponseError;

    constructor(
        private packageFacade: WidgetPackageFacade,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    onFormSubmit(formData: WidgetPackageCreate): void {
        logger.debug('onFormSubmit', formData);

        this.cannotSubmit = true;
        this.packageFacade.createWidgetPackage(formData).subscribe({
            next: this.goBack.bind(this),
            error: error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        });
    }

    goBack(): void {
        this.router.navigate(['..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
