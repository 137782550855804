import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { Service } from '../../../schemas/service.schemas';


const logger = new Logger('ServiceDetailComponent');

@Component({
    selector: 'app-service-detail',
    templateUrl: './service-detail.component.html',
    styleUrls: ['./service-detail.component.scss'],
})
export class ServiceDetailComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    service?: Service;

    constructor(
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.data.subscribe((data: {
                service?: Service;
            }) => {
                this.service = data.service;
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    getManifest(): string {
        return this.service?.manifest
            ? JSON.stringify(this.service.manifest, null, 4)
            : '';
    }

}
