import { HttpClient } from '@angular/common/http';
import {
    Inject,
    Injectable,
} from '@angular/core';
import { first } from 'rxjs/operators';
import { Logger } from '../services';


const logger = new Logger('MediaFacade');

@Injectable({
    providedIn: 'root',
})
export class MediaFacade {
    private readonly environment: { [key: string]: any };

    constructor(
        @Inject('environment') environment: { [key: string]: any },
        private http: HttpClient,
    ) {
        this.environment = environment;
        logger.debug('this.environment', this.environment);
    }

    dataURItoBlob(dataURI: string): Blob {
        const binary = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: mimeString});
    }

    uploadMedia(file: Blob, filename: string): any {
        const httpOptions = {
            headers: {},
            processData: false,
            contentType: false,
        };

        const formData = new FormData();
        formData.append('file', file, filename);

        return this.http.post(this.environment.api.iterraUrl + '/media/public', formData, httpOptions).pipe(
            first(),
        );
    }
}
