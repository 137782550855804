<div class="locations">

    <button class="btn btn-secondary" (click)="loadNextLocations()" *ngIf="locations.length">
        Загрузить еще локации
    </button>

    <div class="locations__title">Структура</div>

    <div class="locations__content">
        <div *ngFor="let location of locations" class="location">

            <div class="location__info">
                <div class="location__number">
                    {{ location?.id }}
                </div>
                <div (click)="onLocationClick(location)" class="location__title">
                    {{ location?.title }}
                </div>
            </div>

            <span class="location__delete" (click)="delete(location)">удалить</span>
        </div>
    </div>

</div>
