import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    License,
    LicenseCreate,
    LicenseFindParams,
} from '../../../schemas/widget.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('LicenseApi');

@Injectable({
    providedIn: 'root',
})
export class LicenseApi {

    constructor(
        private apiService: ApiService,
    ) { }

    findLicenses(params: LicenseFindParams): Observable<License[]> {
        return this.apiService.getJson<License[]>(
            '/widget/license',
            params,
        ).pipe(
            tap(licenses => logger.debug('find (licenses)', licenses)),
        );
    }

    getLicenseById(id: number): Observable<License> {
        return this.apiService.getJson<License>(
            '/widget/license/' + id,
        ).pipe(
            tap(license => logger.debug('getLicenseById (license)', license)),
        );
    }

    createLicense(data: LicenseCreate): Observable<License> {
        return this.apiService.postJson<License>(
            '/widget/license',
            data,
        ).pipe(
            tap(license => logger.debug('createLicense (license)', license)),
        );
    }

    deleteLicenseById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/widget/license/' + id,
        ).pipe(
            tap(() => logger.debug('deleteLicenseById (id)', id)),
        );
    }

}
