import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    map,
    tap,
} from 'rxjs/operators';

import { Playlist } from '../../schemas';
import * as utils from '../../utils';
import { Logger } from '../logger.service';


const logger = new Logger('AudioApi');

@Injectable({
    providedIn: 'root',
})
export class AudioApi {

    constructor(
        private http: HttpClient,
    ) {
    }

    fetchPlaylistsByLocationId(locationId: number): Observable<Playlist[]> {
        const endpoint = 'assets/json/audio/' + locationId + '/playlists.json';

        return this.http.get<Playlist[]>(endpoint).pipe(
            map(utils.convertKeysToCamel),
            tap(playlists => logger.debug('fetchPlaylistsByLocationId (locationId)', locationId, playlists)),
        );
    }

}
