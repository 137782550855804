import { Routes } from '@angular/router';
import { ServiceAddComponent } from '../modules/service/service-add/service-add.component';
import { ServiceDetailComponent } from '../modules/service/service-detail/service-detail.component';
import { ServiceEditComponent } from '../modules/service/service-edit/service-edit.component';
import { ServicePageComponent } from '../modules/service/service-page/service-page.component';
import { ServiceResolver } from '../resolvers/service.resolver';


export const servicesRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: ServicePageComponent,
    },
    {
        path: 'add',
        component: ServiceAddComponent,
        data: {
            breadcrumb: 'Добавление сервиса',
        },
    },
    {
        path: ':serviceId',
        runGuardsAndResolvers: 'always',
        resolve: {
            service: ServiceResolver,
        },
        data: {
            breadcrumb: (data: any) => `${data.service.title} (ID=${data.service.id})`,
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: ServiceDetailComponent,
            },
            {
                path: 'edit',
                component: ServiceEditComponent,
                data: {
                    breadcrumb: 'Редактирование',
                },
            },
        ],
    },
];
