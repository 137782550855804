import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    Logger,
    utils,
} from '@scatch/ngx-app-lib';
import {
    of,
    timer,
} from 'rxjs';
import {
    catchError,
    delayWhen,
    filter,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import {
    Authorization,
    CurrentUser,
    Device,
} from '../../schemas/auth.schemas';
import { AuthApi } from '../../services/api/auth/auth.api';
import { DeviceAuthApi } from '../../services/api/auth/device-auth.api';
import { PhoneAuthApi } from '../../services/api/auth/phone-auth.api';
import * as authActions from '../actions/auth.actions';
import * as authSelectors from '../selectors/auth.selectors';


const logger = new Logger('AuthEffects');

@Injectable()
export class AuthEffects {

    constructor(
        private authApi: AuthApi,
        private phoneAuthApi: PhoneAuthApi,
        private deviceAuthApi: DeviceAuthApi,
        private jwtHelperService: JwtHelperService,
        private actions$: Actions,
        private store$: Store,
    ) {}

    createDeviceEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.createDeviceAction),
            tap(() => logger.debug('Create Device')),
            switchMap(() => this.deviceAuthApi.createDevice().pipe(
                map((device: Device) =>
                    authActions.setDeviceAction({
                        device: device.hash,
                    }),
                ),
            )),
        ),
    );

    setDeviceEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.setDeviceAction),
            filter(({device}) => !device),
            tap(() => logger.info('Need create Device')),
            map(() => authActions.createDeviceAction()),
        ),
    );

    signInEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPhoneCredentialsAction),
            map(() => authActions.signInAction()),
        ),
    );

    signInSuccessEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPhoneCredentialsSuccessAction),
            map(({authorization}) => authActions.signInSuccessAction({authorization})),
        ),
    );

    signInFailureEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPhoneCredentialsFailureAction),
            map(({error}) => authActions.signInFailureAction({error})),
        ),
    );

    signInByPhoneCredentialsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPhoneCredentialsAction),
            switchMap(({credentials}) =>
                this.phoneAuthApi.authorize(credentials).pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByPhoneCredentialsSuccessAction({authorization})),
                    catchError(response => of(authActions.signInByPhoneCredentialsFailureAction({
                        error: utils.formatErrors(response),
                    }))),
                ),
            ),
        ),
    );

    signOutEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signOutAction),
            map(() => authActions.signOutSuccessAction()),
            catchError(() => of(authActions.signOutFailureAction())),
        ),
    );

    refreshToken$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInSuccessAction,
                authActions.setAuthorizationAction,
            ),
            delayWhen(({authorization}) => {
                const token = this.jwtHelperService.decodeToken(authorization.accessToken);
                const expDate = new Date(token.exp * 1000);
                const expTime = expDate.getTime() - Date.now() - 60 * 1000;

                logger.debug('Refresh token after ', Math.max(0, expTime));

                return timer(Math.max(0, expTime));
            }),
            withLatestFrom(this.store$.pipe(
                select(authSelectors.selectIsAuth),
            )),
            filter(([, isAuth]) => !!isAuth),
            switchMap(([{authorization}]) =>
                this.authApi.refreshToken(authorization.refreshToken).pipe(
                    // tslint:disable-next-line:no-shadowed-variable
                    map((authorization: Authorization) =>
                        authActions.setAuthorizationAction({authorization})),
                ),
            ),
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    if ([401, 422].includes(error.status)) {
                        return of(authActions.signOutAction());
                    }
                }

                throw error;
            }),
        ),
    );

    setCurrentUserEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInSuccessAction,
                authActions.setAuthorizationAction,
            ),
            map(({authorization}) => {
                const token = this.jwtHelperService.decodeToken(authorization.accessToken);
                const ctx = utils.convertKeysToCamel(token.ctx);
                const currentUser: CurrentUser = {
                    id: ctx.userId,
                    roleId: ctx.roleId,
                };
                return authActions.setCurrentUserAction({
                    currentUser,
                });
            }),
            catchError(() => {
                return of(authActions.setCurrentUserAction({
                    currentUser: null,
                }));
            }),
        ),
    );

}
