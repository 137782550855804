<nav class="navbar" [ngClass]="currentBg()">

    <div class="navbar__nav" *ngIf="sidenavToggle">

        <div class="navbar__link" (click)="toggleSidenav()">
            <i class="ion ion-md-menu"></i>
        </div>

        <div>Ваш ID: {{ (currentUser$ | async)?.id}}</div>
        <button class="btn-secondary" (click)="signOut()">Выйти</button>
    </div>

</nav>
