import { Injectable } from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    ValidationErrors,
} from '@angular/forms';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';
import { ServiceApi } from '../services/api/capsule/service.api';


const logger = new Logger('ServiceValidator');

@Injectable({providedIn: 'root'})
export class UniqueTitleValidator implements AsyncValidator {

    private serviceId?: number;

    constructor(
        private readonly serviceApi: ServiceApi,
    ) {}

    setServiceId(id?: number): void {
        this.serviceId = id;
    }

    validate(
        control: AbstractControl,
    ): Observable<ValidationErrors | null> {
        return this.serviceApi.findServices({
            title: control.value,
        }).pipe(
            map(isExists => {
                if (!isExists?.length) {
                    return null;
                }

                if (!this.serviceId) {
                    return {unique: true};
                }

                for (const item of isExists) {
                    if (item.id !== this.serviceId) {
                        return {unique: true};
                    }
                }

                return null;
            }),
            catchError(() => of(null)),
        );
    }

}
