import {
    AfterViewInit,
    Component,
    OnDestroy,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { LayoutService } from '../layout.service';


const logger = new Logger('LayoutIterraComponent');

@Component({
    selector: 'app-layout-iterra',
    templateUrl: './layout-iterra.component.html',
    styles: [':host { display: block; height: 100%; }', ':host ::ng-deep .layout-loading .sidenav-link { transition: none !important; }'],
    styleUrls: ['./layout-iterra.component.scss'],
})
export class LayoutIterraComponent implements AfterViewInit, OnDestroy {

    public initialized = false;
    private subscriptions: Subscription[] = [];
    ios = false;

    constructor(
        private layoutService: LayoutService,
    ) {
        if (window.matchMedia('(display-mode: standalone)').matches && this.isIos()) {
            this.ios = this.isIos();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.initialized = true;
            this.layoutService.init();
            this.layoutService.update();
            this.layoutService.setAutoUpdate(true);
        });
    }

    isIos(): boolean {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }

    closeSidenav(): void {
        setTimeout(() => {
            this.layoutService.setCollapsed(true);
        });
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this.layoutService.destroy();
        });

        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }
}

