import { Component } from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    first,
    switchMap,
    tap,
} from 'rxjs/operators';
import { defaultLimit } from '../../../app.constants';
import { LocationFacade } from '../../../facades/location.facade';
import { RecommendationFacade } from '../../../facades/recommendation.facade';
import { Location } from '../../../schemas/location.schemas';


const logger = new Logger('RecommendationListComponent');


@Component({
    selector: 'app-recommendation-list',
    templateUrl: './recommendation-list.component.html',
    styleUrls: ['./recommendation-list.component.scss'],
})
export class RecommendationListComponent {

    locations: Location[] = [];
    recommendations: schemas.Recommendation[] = [];
    isLoading = false;
    offset = 0;
    limit = defaultLimit;

    constructor(
        private recommendationFacade: RecommendationFacade,
        private locationFacade: LocationFacade,
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    fetchContent(): void {
        this.isLoading = true;
        const params = {
            offset: this.offset,
            limit: this.limit,
        };
        this.recommendationFacade.fetchRecommendations(params).pipe(
            tap(recommendations => logger.debug('recommendations', recommendations)),
            switchMap(recommendations => {
                if (!recommendations || recommendations.length === 0) {
                    return of(null);
                }
                this.recommendations.push(...recommendations);

                if (recommendations.length < this.limit) {
                    this.offset += recommendations.length;
                } else {
                    this.offset += this.limit;
                }

                const idIn = recommendations?.map(rec => rec.recLocationId).join(',');
                return this.locationFacade.fetchLocations({idIn});
            }),
            first(),
        ).subscribe(locations => {

            if (!locations) {
                this.isLoading = false;
                return;
            }

            this.locations.push(...locations);
            this.isLoading = false;
        });
    }

    deleteRecommendation(recommendation: schemas.Recommendation): void {
        if (confirm('Удалить рекомендацию ID: ' + recommendation.id + '?')) {
            this.recommendationFacade.deleteRecommendationById(recommendation.id).subscribe(result => {
                if (result) {
                    this.recommendations = this.recommendations.filter(
                        item => item.id !== recommendation.id,
                    );
                }
            });
        }
    }

    getLocation(recommendation: schemas.Recommendation): Location | undefined {
        return this.locations.find(item => item.id === recommendation.recLocationId) || undefined;
    }

    recommendationClick(id: number): void {
        this.router.navigate([id], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }
}
