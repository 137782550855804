import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { WIDGET_TYPE_NAMES } from '../../../app.constants';
import { WidgetFacade } from '../../../facades/widget.facade';
import {
    Widget,
    WidgetFindParams,
} from '../../../schemas/widget.schemas';


const logger = new Logger('WidgetListComponent');

@Component({
    selector: 'app-widget-list',
    templateUrl: './widget-list.component.html',
    styleUrls: ['./widget-list.component.scss'],
})
export class WidgetListComponent implements OnInit, OnChanges {

    widgets: Widget[] = [];

    @Input() params?: WidgetFindParams | null;

    @Output() widgetClick = new EventEmitter();

    constructor(
        private widgetFacade: WidgetFacade,
    ) { }

    ngOnInit(): void {
        if (!this.params) {
            this.loadNextWidgets();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params?.currentValue) {
            this.params = changes.params?.currentValue;
            this.widgets = [];
            this.loadNextWidgets();
        }
    }

    loadNextWidgets(): void {
        this.widgetFacade.fetchWidgets({
            ...this.params,
            offset: this.widgets.length,
            limit: this.params?.limit || 0,
        }).subscribe(widgets => {
            this.widgets.push(...widgets);
        });
    }

    onWidgetClick(widget: Widget): void {
        this.widgetClick.emit(widget);
    }

    delete(widget: Widget): void {
        if (confirm('Удалить виджет ' + widget.title + '?')) {
            this.widgetFacade.deleteWidget(widget).subscribe(result => {
                if (result) {
                    this.widgets = this.widgets.filter(
                        item => item.id !== widget.id,
                    );
                }
            });
        }
    }

    getWidgetType(typeId: number): string {
        return WIDGET_TYPE_NAMES[typeId] || 'неизвестен';
    }

}
