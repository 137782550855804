import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { LocationApi } from './api/capsule/location.api';


const logger = new Logger('LocationService');

@Injectable({
    providedIn: 'root',
})
export class LocationService {

    constructor(
        private locationApi: LocationApi,
    ) { }

}
