import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UserFacade } from '../../../facades/user.facade';
import {
    User,
    UserFindParams,
} from '../../../schemas/user.schemas';


@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnChanges {

    users: User[] = [];

    @Input() params?: UserFindParams | null;

    @Output() userClick = new EventEmitter();

    constructor(
        private userFacade: UserFacade,
    ) { }

    ngOnInit(): void {
        if (!this.params) {
            this.loadNextUsers();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params?.currentValue) {
            this.params = changes.params?.currentValue;
            this.users = [];
            this.loadNextUsers();
        }
    }

    loadNextUsers(): void {
        this.userFacade.fetchUsers({
            ...this.params,
            offset: this.users.length,
            limit: this.params?.limit || 100,
        }).subscribe(users => {
            this.users.push(...users);
        });
    }

    onUserClick(user: User): void {
        this.userClick.emit(user);
    }

}
