import { Routes } from '@angular/router';
import { WidgetPackageAddComponent } from '../modules/widget/widget-package/widget-package-add/widget-package-add.component';
import { WidgetPackageDetailComponent } from '../modules/widget/widget-package/widget-package-detail/widget-package-detail.component';
import { WidgetPackageEditComponent } from '../modules/widget/widget-package/widget-package-edit/widget-package-edit.component';
import { WidgetPackagePageComponent } from '../modules/widget/widget-package/widget-package-page/widget-package-page.component';
import { WidgetPackageWidgetsLinkComponent } from '../modules/widget/widget-package/widget-package-widgets-link/widget-package-widgets-link.component';
import { WidgetPackageResolver } from '../resolvers/widget-package.resolver';


export const widgetPackagesRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: WidgetPackagePageComponent,
    },
    {
        path: 'add',
        component: WidgetPackageAddComponent,
        data: {
            breadcrumb: 'Добавление пакета виджетов',
        },
    },
    {
        path: ':widgetPackageId',
        runGuardsAndResolvers: 'always',
        resolve: {
            widgetPackage: WidgetPackageResolver,
        },
        data: {
            breadcrumb: (data: any) => `${data.widgetPackage.title} (ID=${data.widgetPackage.id})`,
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: WidgetPackageDetailComponent,
            },
            {
                path: 'edit',
                component: WidgetPackageEditComponent,
                data: {
                    breadcrumb: 'Редактирование',
                },
            },
            {
                path: 'link-widgets',
                component: WidgetPackageWidgetsLinkComponent,
                data: {
                    breadcrumb: 'Привязка виджетов',
                },
            },
        ],
    },
];
