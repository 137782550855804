import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Capsule,
    CapsuleFindParams,
} from '../../../schemas/capsule.schemas';


const logger = new Logger('CapsulePageComponent');

@Component({
    selector: 'app-capsule-page',
    templateUrl: './capsule-page.component.html',
    styleUrls: ['./capsule-page.component.scss'],
})
export class CapsulePageComponent {

    capsuleFindParams: CapsuleFindParams = {
        limit: 100,
    };

    constructor(
        private router: Router,
    ) { }

    onCapsuleClick(capsule: Capsule): void {
        this.router.navigate(['/capsules', capsule.id])
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
