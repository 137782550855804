import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GeoPrimitiveComponent } from './geo-primitive/geo-primitive.component';
import { GeoRouteComponent } from './geo-route/geo-route.component';


@NgModule({
    declarations: [
        GeoRouteComponent,
        GeoPrimitiveComponent,
    ],
    exports: [
        GeoRouteComponent,
        GeoPrimitiveComponent,
    ],
    imports: [
        CommonModule,
    ],
})
export class GeoUiModule {}
