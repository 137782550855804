import {
    Component,
    OnInit,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { CapsuleFacade } from '../../../facades/capsule.facade';
import { CapsuleCreate } from '../../../schemas/capsule.schemas';


const logger = new Logger('CapsuleAddComponent');

@Component({
    selector: 'app-capsule-add',
    templateUrl: './capsule-add.component.html',
    styleUrls: ['./capsule-add.component.scss'],
})
export class CapsuleAddComponent implements OnInit {

    cannotSubmit = false;
    formError?: schemas.ResponseError;

    constructor(
        private capsuleFacade: CapsuleFacade,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {}

    onFormSubmit(formData: CapsuleCreate): void {
        logger.debug('onFormSubmit', formData);

        this.cannotSubmit = true;
        this.capsuleFacade.createCapsule(formData).subscribe(
            capsule => this.router.navigate(['/capsules', capsule.id]),
            error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        );
    }

    goBack(): void {
        this.router.navigate(['..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
