import {
    Component,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
} from '@angular/core';
import {
    BehaviorSubject,
    Observable,
} from 'rxjs';
import { DynamicHostDirective } from '../dynamic-host.directive';
import { ComponentRenderOptions } from '../dynamic-overlay.schemas';
import { DynamicOverlayService } from '../dynamic-overlay.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'itw-dynamic-overlay',
    templateUrl: './dynamic-overlay.component.html',
    styleUrls: ['./dynamic-overlay.component.scss'],
})
export class DynamicOverlayComponent implements OnInit, OnDestroy {

    isVisible = false;

    private componentSubject = new BehaviorSubject<any | null>(null);

    @ViewChild(DynamicHostDirective, {static: true}) dynamicHost!: DynamicHostDirective;

    constructor(
        private readonly overlayService: DynamicOverlayService,
    ) { }

    ngOnInit(): void {
        this.overlayService.setComponentRender(this.renderComponent.bind(this));
    }

    ngOnDestroy(): void {
        this.overlayService.resetComponentRender();
    }

    renderComponent(
        componentType: Type<any> | null,
        options?: ComponentRenderOptions,
    ): Observable<any | null> {
        const viewContainerRef = this.dynamicHost.viewContainerRef;

        viewContainerRef.clear();

        this.isVisible = !!componentType;

        if (componentType) {
            const componentRef = viewContainerRef.createComponent<any>(componentType);
            const component = componentRef.instance;

            if (options?.params) {
                console.log(componentRef.instance);

                for (const [key, value] of Object.entries(options.params)) {
                    console.log(`${key}: ${value}`);

                    component[key] = value;
                }
            }

            this.componentSubject.next(component);
        }

        return this.componentSubject.asObservable();
    }

    close(): void {
        this.componentSubject.next(null);
        this.dynamicHost.viewContainerRef.clear();
        this.isVisible = false;
    }

}
