<div class="layout-wrapper layout-2" [ngClass]="{'layout-loading': !initialized}">

    <div class="layout-iterra-inner">
        <app-layout-iterra-sidenav></app-layout-iterra-sidenav>

        <div class="layout-container">

            <app-layout-iterra-navbar></app-layout-iterra-navbar>
            <app-breadcrumb></app-breadcrumb>

            <div class="iterra-container router-transitions flex-grow-1">
                <router-outlet></router-outlet>
            </div>

        </div>
    </div>

</div>
