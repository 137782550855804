import {
    HttpClient,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { utils } from '@scatch/ngx-app-lib';
import {
    Observable,
    throwError,
} from 'rxjs';
import {
    catchError,
    map,
    take,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';


export interface Headers {
    [header: string]: string | string[];
}

export interface Params {
    [key: string]: any;
}

export interface Options {
    headers?: Headers | HttpHeaders;
    params?: Params | HttpParams;

    [key: string]: any;
}

export const jsonAcceptHeader = {
    'Accept': 'application/json',
};

export const jsonHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
    ...jsonAcceptHeader,
};

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    protected prefix = '';

    constructor(
        private http: HttpClient,
    ) { }

    private makeUri(path: string): string {
        return `${environment.api.url}${this.prefix}${path}`;
    }

    protected formatErrors(response: any): Observable<never> {
        return throwError(response);
    }

    protected prepareOptions(options: Options): Options {
        const newOptions: Options = {...options};

        if (options.params) {
            newOptions.params = utils.convertKeysToSnake({
                ...options.params,
            });
        }

        return newOptions;
    }

    protected toStringArrayParams(params: Params): Params {
        const result: Params = {};

        Object.keys(params)
            .forEach((key) => {
                const value = params[key];

                result[key] = utils.isArray(value)
                    ? value.join(',')
                    : value;
            });

        return result;
    }

    get<T>(
        path: string,
        params: Params | HttpParams = {},
        options: Options = {},
    ): Observable<T> {
        return this.http.get<T>(
            this.makeUri(path),
            {
                ...options,
                params,
            },
        ).pipe(
            take(1),
            catchError(this.formatErrors.bind(this)),
        );
    }

    getJson<T>(
        path: string,
        params: Params | HttpParams = {},
        options: Options = {},
    ): Observable<T> {
        return this.get<T>(
            path,
            utils.convertKeysToSnake(params),
            this.prepareOptions({
                headers: jsonAcceptHeader,
                ...options,
            }),
        ).pipe(map(utils.convertKeysToCamel));
    }

    put<T>(
        path: string,
        body?: object,
        options?: Options,
    ): Observable<T> {
        return this.http.put<T>(
            this.makeUri(path),
            body,
            options,
        ).pipe(
            take(1),
            catchError(this.formatErrors.bind(this)),
        );
    }

    putJson<T>(
        path: string,
        body: object = {},
        options: Options = {},
    ): Observable<T> {
        return this.put<T>(
            path,
            utils.convertKeysToSnake(body),
            this.prepareOptions({
                headers: jsonHeaders,
                ...options,
            }),
        ).pipe(map(utils.convertKeysToCamel));
    }

    patch<T>(
        path: string,
        body?: object,
        options?: Options,
    ): Observable<T> {
        return this.http.patch<T>(
            this.makeUri(path),
            body,
            options,
        ).pipe(
            take(1),
            catchError(this.formatErrors.bind(this)),
        );
    }

    patchJson<T>(
        path: string,
        body: object = {},
        options: Options = {},
    ): Observable<T> {
        return this.patch<T>(
            path,
            utils.convertKeysToSnake(body),
            this.prepareOptions({
                headers: jsonHeaders,
                ...options,
            }),
        ).pipe(map(utils.convertKeysToCamel));
    }

    post<T>(
        path: string,
        body?: object,
        options?: Options,
    ): Observable<T> {
        return this.http.post<T>(
            this.makeUri(path),
            body,
            options,
        ).pipe(
            take(1),
            catchError(this.formatErrors.bind(this)),
        );
    }

    postJson<T>(
        path: string,
        body: object = {},
        options: Options = {},
    ): Observable<T> {
        return this.post<T>(
            path,
            utils.convertKeysToSnake(body),
            this.prepareOptions({
                headers: jsonHeaders,
                ...options,
            }),
        ).pipe(map(utils.convertKeysToCamel));
    }

    delete<T>(
        path: string,
        params: Params | HttpParams = {},
        options?: Options,
    ): Observable<T> {
        return this.http.delete<T>(
            this.makeUri(path),
            {
                ...options,
                params,
            },
        ).pipe(
            take(1),
            catchError(this.formatErrors.bind(this)),
        );
    }

    deleteJson<T>(
        path: string,
        params: Params | HttpParams = {},
        options: Options = {},
    ): Observable<T> {
        return this.delete<T>(
            path,
            utils.convertKeysToSnake(params),
            this.prepareOptions({
                headers: jsonAcceptHeader,
                ...options,
            }),
        ).pipe(map(utils.convertKeysToCamel));
    }

}
