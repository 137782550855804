import { Component } from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import {
    map,
    tap,
} from 'rxjs/operators';
import { GeoPrimitiveFacade } from '../../../facades/geo/geo-primitive.facade';


const logger = new Logger('GeoPrimitiveEditComponent');

@Component({
    selector: 'app-geo-primitive-edit',
    templateUrl: './geo-primitive-edit.component.html',
    styleUrls: ['./geo-primitive-edit.component.scss'],
})
export class GeoPrimitiveEditComponent {

    readonly geoPrimitive$ = this.route.data.pipe(
        map((data: { geoPrimitive?: schemas.GeoPrimitive }) => data.geoPrimitive),
        tap(data => logger.debug('data', data)),
    );

    cannotSubmit = false;
    formError?: schemas.ResponseError;

    constructor(
        private geoPrimitiveFacade: GeoPrimitiveFacade,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    onFormSubmit(formData: schemas.GeoPrimitiveUpdate & { id: number }): void {
        logger.debug('onFormSubmit', formData);

        this.cannotSubmit = true;

        this.geoPrimitiveFacade.updateGeoPrimitive(formData.id, formData).subscribe(
            () => this.router.navigate(['geo', 'primitive']),
            error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        );
    }

    goBack(): void {
        this.router.navigate(['..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }
}
