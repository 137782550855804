<form [formGroup]="signInForm" (input)="onFormChange()" (ngSubmit)="onFormSubmit()">

  <div class="form-group">

    <div class="title-form-group">Номер телефона</div>

    <div class="input-group">

      <input type="tel"
             class="form-control"
             formControlName="phone"
             mask="(000) 000-00-00"
             prefix="+7 "
             tabindex="1"
             required>

      <span class="invalid-feedback"
            *ngIf="signInForm.controls['phone'].invalid && signInForm.controls['phone'].touched">
        Номер телефона введен неверно
      </span>

    </div>

  </div>

  <div class="form-group">

    <div class="title-form-group">Пароль</div>

    <div class="input-group">

      <input type="password"
             class="form-control"
             formControlName="password"
             tabindex="2"
             required>

      <span class="invalid-feedback"
            *ngIf="signInForm.controls['password'].invalid && signInForm.controls['password'].touched">
        Некорректно заполненное поле
      </span>

    </div>

  </div>

  <div class="form-group">

    <button class="btn btn-primary sc-wide"
            [disabled]="cannotSubmit || signInForm.invalid || !!formError">
      Войти
    </button>

    <div *ngIf="formError">{{ formError.message }}</div>

  </div>

</form>
