import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { Location } from '../schemas/location.schemas';
import { LocationApi } from '../services/api/capsule/location.api';


const logger = new Logger('LocationResolver');

@Injectable({
    providedIn: 'root',
})
export class LocationResolver implements Resolve<Location> {

    constructor(
        private locationApi: LocationApi,
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<Location> {
        const locationId = Number(route.paramMap.get('locationId'));

        logger.debug('resolve (locationId)', locationId);

        return this.locationApi.fetchLocationById(locationId);
    }

}
