<div>
    <button class="btn btn-secondary" (click)="loadNextUsers()" *ngIf="users.length">Загрузить еще</button>

    <div class="content">
        <div *ngFor="let user of users" class="user">

            <div class="user__info">
                <div class="user__id">
                    {{ user.id }}
                </div>
                <div class="user__phone">
                    {{ user.phone }}
                </div>
            </div>

            <div (click)="onUserClick(user)">
                {{ user?.firstName }} {{ user?.lastName }}
            </div>

        </div>
    </div>

</div>
