import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    Dimensions,
    ImageCroppedEvent,
} from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { Logger } from '../../../services';
import { ImageWithCropper } from '../upload.schemas';


const logger = new Logger('ImageCropperComponent');

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent {
    @Input() image: ImageWithCropper | null = null;
    @Input() maintainAspectRatio = false;

    @Output() changeImage = new EventEmitter<ImageWithCropper>();

    cropper: any = {};
    canSaveCropperPosition = false;

    constructor(
        private toastrService: ToastrService,
    ) {
    }

    cropperReady(sourceImageDimensions: Dimensions): void {
        if (!this.image) {
            return;
        }

        this.cropper = this.image.cropper;
    }

    loadedImage(event: any): void {
        this.canSaveCropperPosition = false;
    }

    imageCropped(event: ImageCroppedEvent): void {
        if (!this.image) {
            return;
        }

        this.image.cropped = event.base64 || '';

        if (this.canSaveCropperPosition) {
            this.image.cropper = event.cropperPosition;
        }

        this.canSaveCropperPosition = true;
        this.changeImage.emit(this.image);
    }

    loadImageFailed($event: void): void {
        this.toastrService.error('Ошибка загрузки изображения', '');
    }
}
