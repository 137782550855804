<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

  <div class="form-group">
    <div class="title-form-group">Никнейм виджета (widget-nickname)</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="nickname"
             placeholder="widget-nickname">
      <span class="invalid-feedback"
            *ngIf="form.controls['nickname'].invalid && form.controls['nickname'].touched">
        Некорректно заполненное поле
        <span *ngIf="form.controls['nickname'].hasError('unique')">(такой уже есть)</span>
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Название пакета</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="title"
             placeholder="Введите название..."
             required>
      <span class="invalid-feedback"
            *ngIf="form.controls['title'].invalid && form.controls['title'].touched">
        Некорректно заполненное поле
        <span *ngIf="form.controls['title'].hasError('unique')">(такой уже есть)</span>
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Иконка виджета</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             placeholder="https://img.domain/icon.png"
             formControlName="icon">
      <span class="invalid-feedback"
            *ngIf="form.controls['icon'].invalid && form.controls['icon'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Адрес сервиса (https://my.service.com/widget)</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="uri"
             placeholder="https://my.service.com/widget"
             required>
      <span class="invalid-feedback"
            *ngIf="form.controls['uri'].invalid && form.controls['uri'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Типы виджета</div>
    <ng-multiselect-dropdown
            [placeholder]="'Выберите типы'"
            [settings]="dropdownSettings"
            [data]="widgetTypes"
            formControlName="widgetTypes"
    ></ng-multiselect-dropdown>
  </div>

  <div class="form-group">
    <button class="btn btn-primary"
            [disabled]="cannotSubmit || form.invalid || !!formError">
      Создать пакет виджетов
    </button>
    <button class="btn btn-secondary" (click)="goBack()">Отмена</button>
    <div *ngIf="formError">{{ formError.message }}</div>
  </div>

</form>
