import { Injectable } from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    mapTo,
} from 'rxjs/operators';
import { GeoPrimitiveApi } from '../../services/api/geo/geo-primitive.api';


const logger = new Logger('GeoPrimitiveFacade');

@Injectable({
    providedIn: 'root',
})
export class GeoPrimitiveFacade {

    constructor(
        private geoPrimitiveApi: GeoPrimitiveApi,
    ) {
    }

    fetchGeoPrimitives(params: schemas.GeoPrimitivesFindParams): Observable<schemas.GeoPrimitive[] | null> {
        return this.geoPrimitiveApi.fetchGeoPrimitives(params);
    }

    createGeoPrimitive(data: schemas.GeoPrimitiveCreate): Observable<schemas.GeoPrimitive | null> {
        return this.geoPrimitiveApi.createGeoPrimitive(data);
    }

    updateGeoPrimitive(id: number, data: schemas.GeoPrimitiveUpdate): Observable<schemas.GeoPrimitive | null> {
        return this.geoPrimitiveApi.updateGeoPrimitiveById(id, data);
    }

    deleteGeoPrimitiveId(id: number): Observable<boolean> {
        return this.geoPrimitiveApi.deleteGeoPrimitiveById(id).pipe(
            mapTo(true),
            catchError(() => of(false)),
        );
    }
}
