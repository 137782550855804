import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { WidgetPackage } from '../schemas/widget.schemas';
import { PackageApi } from '../services/api/widget/package.api';


const logger = new Logger('WidgetPackageResolver');

@Injectable({
    providedIn: 'root',
})
export class WidgetPackageResolver implements Resolve<WidgetPackage> {

    constructor(
        private packageApi: PackageApi,
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<WidgetPackage> {
        const widgetPackageId = Number(route.paramMap.get('widgetPackageId'));

        logger.debug('resolve (widgetPackageId)', widgetPackageId);

        return this.packageApi.fetchWidgetPackageById(widgetPackageId);
    }

}
