<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
    <div class="form-group">
        <div class="title-form-group">Имя бота</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="botName"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['botName'].invalid && form.controls['botName'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Токен бота</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="botToken"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['botToken'].invalid && form.controls['botToken'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Puzzle токен</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="puzzleToken"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['puzzleToken'].invalid && form.controls['puzzleToken'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="checkbox">
            <input type="checkbox" formControlName="canStart" id="canStart">
            <label for="canStart" style="margin-left: 15px">
                <i></i>
                <span>Включить бота</span>
            </label>
        </div>
    </div>

    <div class="form-group">
        <div class="checkbox">
            <input type="checkbox" formControlName="linkToLocation" id="linkToLocation">
            <label for="linkToLocation" style="margin-left: 15px">
                <i></i>
                <span>Переход в локацию из бота</span>
            </label>
        </div>
    </div>

    <div class="form-group">
        <div class="checkbox">
            <input type="checkbox" formControlName="messageSync" id="messageSync">
            <label for="messageSync" style="margin-left: 15px">
                <i></i>
                <span>Синхронизация сообщений</span>
            </label>
        </div>
    </div>

    <div class="form-group">
        <div class="checkbox">
            <input type="checkbox" formControlName="post" id="post">
            <label for="post" style="margin-left: 15px">
                <i></i>
                <span>Публикация сообщений</span>
            </label>
        </div>
    </div>

    <div class="form-group">
        <button class="btn btn-primary" [disabled]="cannotSubmit || form.invalid || !!formError">
            Сохранить
        </button>
        <button type="button" class="btn btn-secondary" (click)="onFormCancel()">Отмена</button>
        <div *ngIf="formError">{{ formError.message }}</div>
    </div>
</form>
