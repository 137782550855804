import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { CapsuleFacade } from '../../../facades/capsule.facade';
import {
    Capsule,
    CapsuleFindParams,
} from '../../../schemas/capsule.schemas';


const logger = new Logger('CapsuleListComponent');

@Component({
    selector: 'app-capsule-list',
    templateUrl: './capsule-list.component.html',
    styleUrls: ['./capsule-list.component.scss'],
})
export class CapsuleListComponent implements OnInit, OnChanges {

    capsules: Capsule[] = [];

    @Input() params?: CapsuleFindParams | null;

    @Output() capsuleClick = new EventEmitter();

    constructor(
        private capsuleFacade: CapsuleFacade,
    ) { }

    ngOnInit(): void {
        if (!this.params) {
            this.loadNextCapsules();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params?.currentValue) {
            this.params = changes.params?.currentValue;
            this.capsules = [];
            this.loadNextCapsules();
        }
    }

    loadNextCapsules(): void {
        this.capsuleFacade.fetchCapsules({
            ...this.params,
            afterId: this.capsules[this.capsules.length - 1]?.id || 0,
            offset: this.capsules.length,
            limit: this.params?.limit || 100,
        }).subscribe(capsules => {
            this.capsules.push(...capsules);
        });
    }

    onCapsuleClick(capsule: Capsule): void {
        this.capsuleClick.emit(capsule);
    }

    delete(capsule: Capsule): void {
        if (confirm('Удалить капсулу ' + capsule.title + '?')) {
            this.capsuleFacade.deleteCapsule(capsule).subscribe(result => {
                if (result) {
                    this.capsules = this.capsules.filter(
                        item => item.id !== capsule.id,
                    );
                }
            });
        }
    }

}
