import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    ActivatedRoute,
    ParamMap,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { LocationFacade } from '../../../../facades/location.facade';
import { LocationCreate } from '../../../../schemas/location.schemas';


const logger = new Logger('LocationAddComponent');

@Component({
    selector: 'app-location-add',
    templateUrl: './location-add.component.html',
    styleUrls: ['./location-add.component.scss'],
})
export class LocationAddComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    cannotSubmit = false;
    formError?: schemas.ResponseError;
    paramMap?: ParamMap;

    constructor(
        private locationFacade: LocationFacade,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.paramMap.subscribe(paramMap =>
                this.paramMap = paramMap,
            ),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormSubmit(formData: LocationCreate): void {
        const capsuleId = Number(this.paramMap?.get('capsuleId'));

        logger.debug('onFormSubmit', formData);

        if (!capsuleId) {
            return;
        }

        this.cannotSubmit = true;
        this.locationFacade.createLocation({
            ...formData,
            capsuleId,
        }).subscribe(
            location => this.router.navigate([location.id], {relativeTo: this.route.parent}),
            error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        );
    }

    goBack(): void {
        this.router.navigate(['../..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
