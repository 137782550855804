import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';
import {
    NotFoundComponent,
    NotFoundModule,
} from '@scatch/ngx-app-lib';
import { LayoutIterraComponent } from '../layout/layout-iterra/layout-iterra.component';
import { authRoutes } from './auth.routes';
import { capsulesRoutes } from './capsules.routes';
import { dashboardRoutes } from './dashboard.routes';
import { geoRoutes } from './geo.routes';
import { recommendationRoutes } from './recommendation.routes';
import { servicesRoutes } from './services.routes';
import { uploadRoutes } from './upload.routes';
import { usersRoutes } from './users.routes';
import { widgetPackagesRoutes } from './widget-packages.routes';
import { widgetsRoutes } from './widgets.routes';


const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/dashboard',
    },
    {
        path: 'dashboard',
        component: LayoutIterraComponent,
        children: [...dashboardRoutes],
    },
    {
        path: 'auth',
        children: [...authRoutes],
    },
    {
        path: 'capsules',
        component: LayoutIterraComponent,
        data: {breadcrumb: 'Капсулы'},
        children: [...capsulesRoutes],
    },
    {
        path: 'users',
        component: LayoutIterraComponent,
        data: {breadcrumb: 'Юзеры'},
        children: [...usersRoutes],
    },
    {
        path: 'services',
        component: LayoutIterraComponent,
        data: {breadcrumb: 'Сервисы'},
        children: [...servicesRoutes],
    },
    {
        path: 'widgets',
        component: LayoutIterraComponent,
        data: {breadcrumb: 'Виджеты'},
        children: [...widgetsRoutes],
    },
    {
        path: 'widget-packages',
        component: LayoutIterraComponent,
        data: {breadcrumb: 'Пакеты виджетов'},
        children: [...widgetPackagesRoutes],
    },
    {
        path: 'recommendation',
        component: LayoutIterraComponent,
        data: {breadcrumb: 'Рекомендации'},
        children: [...recommendationRoutes],
    },
    {
        path: 'geo',
        component: LayoutIterraComponent,
        data: {breadcrumb: 'GEO'},
        children: [...geoRoutes],
    },
    {
        path: 'upload',
        component: LayoutIterraComponent,
        data: { breadcrumb: 'Медиа' },
        children: [...uploadRoutes],
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [
        NotFoundModule,
        RouterModule.forChild(routes),
    ],
})
export class AppRoutingModule {}
