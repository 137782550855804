<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

    <div class="form-group">
        <div class="title-form-group">Тип</div>
        <select class="form-control" formControlName="type" required>
            <option value="">Выберите тип...</option>
            <option value="{{ type }}" *ngFor="let type of types">{{ type }}</option>
        </select>
    </div>

    <div class="form-group">
        <div class="title-form-group">Заголовок</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="title"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['title'].invalid && form.controls['title'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Рубрика</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="rubric"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['rubric'].invalid && form.controls['rubric'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Иконка</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="icon"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['icon'].invalid && form.controls['icon'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Описание</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="description">
            <span class="invalid-feedback"
                  *ngIf="form.controls['description'].invalid && form.controls['description'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Capsule ID</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="capsuleId"
                   [readOnly]="isEdit"
                   [class.isReadOnly]="isEdit"
                   required
            >
            <span class="invalid-feedback"
                  *ngIf="form.controls['capsuleId'].invalid && form.controls['capsuleId'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Content ID</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="contentId"
            >
            <span class="invalid-feedback"
                  *ngIf="form.controls['contentId'].invalid && form.controls['contentId'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">GEO JSON</div>
        <textarea formControlName="geoJson" class="form-control" cols="30" rows="5" required></textarea>
    </div>

    <div class="form-group">
        <button class="btn btn-primary" [disabled]="cannotSubmit || form.invalid || !!formError">
            Сохранить
        </button>
        <button class="btn btn-secondary" (click)="onFormCancel()">Отмена</button>
        <div *ngIf="formError">{{ formError.message }}</div>
    </div>

</form>
