import { Routes } from '@angular/router';
import { UploadMediaComponent } from '../modules/upload/upload-media/upload-media.component';


export const uploadRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: UploadMediaComponent,
            },
        ],
    },
];
