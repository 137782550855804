import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    Service,
    ServiceCreate,
    ServiceFindParams,
    ServiceUpdate,
} from '../../../schemas/service.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('ServiceApi');

@Injectable({
    providedIn: 'root',
})
export class ServiceApi {

    constructor(
        private apiService: ApiService,
    ) { }

    findServices(params: ServiceFindParams): Observable<Service[]> {
        return this.apiService.getJson<Service[]>(
            '/capsule/service',
            params,
        ).pipe(
            tap(services => logger.debug('findServices (services)', services)),
        );
    }

    fetchServiceById(id: number): Observable<Service> {
        return this.apiService.getJson<Service>(
            '/capsule/service/' + id,
        ).pipe(
            tap(service => logger.debug('fetchServiceById (service)', service)),
        );
    }

    createService(data: ServiceCreate): Observable<Service> {
        return this.apiService.postJson<Service>(
            '/capsule/service',
            data,
        ).pipe(
            tap(service => logger.debug('createService (service)', service)),
        );
    }

    updateServiceById(id: number, data: ServiceUpdate): Observable<Service> {
        return this.apiService.patchJson<Service>(
            '/capsule/service/' + id,
            data,
        ).pipe(
            tap(service => logger.debug('updateServiceById (service)', service)),
        );
    }

    deleteServiceById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/capsule/service/' + id,
        ).pipe(
            tap(() => logger.debug('deleteServiceById (id)', id)),
        );
    }

}
