import { Component } from '@angular/core';
import {
    FormControl,
    FormGroup,
} from '@angular/forms';
import { Logger } from '@scatch/ngx-app-lib';
import { tap } from 'rxjs/operators';
import { MediaFacade } from '../../../facades/media.facade';


const logger = new Logger('UploadMediaComponent');

@Component({
    selector: 'app-upload-media',
    templateUrl: './upload-media.component.html',
    styleUrls: ['./upload-media.component.scss'],
})
export class UploadMediaComponent {
    uploadForm: FormGroup;
    result?: string;

    canEmitChanges = false;

    constructor(
        private mediaFacade: MediaFacade,
    ) {
        this.uploadForm = new FormGroup({
            file: new FormControl(''),
        });
    }

    saveImages(): void {
        this.result = undefined;
        this.canEmitChanges = true;
    }

    changeUploadImages(images: string[]): void {
        this.result = JSON.stringify(images);
        this.canEmitChanges = false;
    }

    onMediaChange(e: any): void {
        this.result = undefined;
        const reader = new FileReader();
        let loadedFile;

        if (e.target.files && e.target.files.length) {
            const [file] = e.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                loadedFile = reader.result as string;

                this.mediaFacade.uploadMedia(this.mediaFacade.dataURItoBlob(loadedFile), file.name).pipe(
                    tap((result: { uri: string; }) => {
                        this.result = result.uri;
                    }),
                ).subscribe();
            };
        }
    }
}
