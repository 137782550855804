import { DatePipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
    utils,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { WidgetPackageFacade } from '../../../facades/widget-package.facade';
import {
    License,
    WidgetPackage,
} from '../../../schemas/widget.schemas';


const logger = new Logger('LicenseFormComponent');

@Component({
    selector: 'app-license-form',
    templateUrl: './license-form.component.html',
    styleUrls: ['./license-form.component.scss'],
})
export class LicenseFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    widgetPackages: WidgetPackage[] = [];
    form: FormGroup;

    @Input() license?: License | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor(
        private widgetPackageFacade: WidgetPackageFacade,
    ) {
        const nowDate = new Date();
        const nextDate = new Date();
        nextDate.setFullYear(nowDate.getFullYear() + 1);
        const datePipe = new DatePipe('en-US');
        const dateFrom = datePipe.transform(nowDate, 'dd.MM.yyyy');
        const dateTo = datePipe.transform(nextDate, 'dd.MM.yyyy');

        this.form = new FormGroup({

            title: new FormControl('', [
                Validators.required,
            ]),

            packageId: new FormControl('', [
                Validators.required,
            ]),

            dateFrom: new FormControl(dateFrom, [
                Validators.required,
            ]),

            dateTo: new FormControl(dateTo, [
                Validators.required,
            ]),

        });

    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );

        this.widgetPackageFacade.fetchWidgetPackages({
            limit: 1000,
        }).subscribe(widgetPackages => this.widgetPackages = widgetPackages);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.license?.currentValue) {
            this.form.patchValue({
                ...changes.license?.currentValue,
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        const title = values.title;
        const packageId = Number(values.packageId);

        if (packageId) {
            for (const widgetPackage of this.widgetPackages) {
                if (widgetPackage.id === packageId && widgetPackage.title !== title) {
                    this.form.patchValue({
                        title: widgetPackage.title,
                    });
                }
            }
        }

        this.formError = undefined;
    }

    onFormSubmit(): void {
        const packageId = this.form.value.packageId;
        const dateFrom = this.form.value.dateFrom;
        const dateTo = this.form.value.dateTo;
        const result = {
            ...this.license,
            ...this.form.value,
        };

        if (packageId) {
            result.packageId = parseInt(packageId, 10);
        }

        if (dateFrom) {
            result.dateFrom = utils.parseDate(dateFrom)?.toISOString().replace('Z', '');
        }

        if (dateTo) {
            result.dateTo = utils.parseDate(dateTo)?.toISOString().replace('Z', '');
        }

        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }

}
