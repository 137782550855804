import { Injectable } from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    mapTo,
} from 'rxjs/operators';
import { Location } from '../schemas/location.schemas';
import { RecommendationApi } from '../services/api/recommendation/recommendation.api';


const logger = new Logger('RecommendationFacade');

@Injectable({
    providedIn: 'root',
})
export class RecommendationFacade {

    constructor(
        private recommendationApi: RecommendationApi,
    ) {
    }

    fetchRecommendations(params: schemas.RecommendationFindParams): Observable<schemas.Recommendation[] | null> {
        return this.recommendationApi.fetchRecommendations(params);
    }

    fetchRecommendationLocations(params: schemas.RecommendationFindParams): Observable<Location[] | null> {
        return this.recommendationApi.fetchRecommendationLocations(params);
    }

    createRecommendation(rec: schemas.RecommendationCreate): Observable<schemas.Recommendation> {
        return this.recommendationApi.createRecommendation(rec);
    }

    updateRecommendationById(id: number, rec: schemas.RecommendationUpdate): Observable<schemas.Recommendation> {
        return this.recommendationApi.updateRecommendationById(id, rec);
    }

    deleteRecommendationById(id: number): Observable<boolean> {
        return this.recommendationApi.deleteRecommendationById(id).pipe(
            mapTo(true),
            catchError(() => of(false)),
        );
    }
}
