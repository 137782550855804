<div class="recommendations">
    <button routerLink="/recommendation/add" class="btn btn-primary">Добавить рекомендацию</button>

    <ui-simple-infinite-scroll (scrolled)="fetchContent()">

        <div slot="list">
            <div *ngFor="let recommendation of recommendations;" class="recommendation">
                <div class="recommendations__number">{{recommendation.id}}</div>
                <ui-location
                        [location]="getLocation(recommendation)"
                        (click)="recommendationClick(recommendation.id)"
                ></ui-location>
                <div class="recommendations__wrapper">
                    <div class="recommendations__label">
                        loc: <span>{{ recommendation.locationId }}</span>
                    </div>
                    <div class="recommendation__delete" (click)="deleteRecommendation(recommendation)">
                        Удалить
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isLoading" class="loading" slot="loading">
            <ui-simple-loading></ui-simple-loading>
        </div>

    </ui-simple-infinite-scroll>
</div>
