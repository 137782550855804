import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { LoadingUiModule } from '../../ui/loading/loading.module';
import { ScrollUiModule } from '../../ui/scroll/scroll-ui.module';
import { TelegramUiModule } from '../../ui/telegram/telegram.module';
import { TelegramBotAddComponent } from './telegram-bot-add/telegram-bot-add.component';
import { TelegramBotEditComponent } from './telegram-bot-edit/telegram-bot-edit.component';
import { TelegramBotFormComponent } from './telegram-bot-form/telegram-bot-form.component';
import { TelegramBotListComponent } from './telegram-bot-list/telegram-bot-list.component';


@NgModule({
    declarations: [
        TelegramBotAddComponent,
        TelegramBotEditComponent,
        TelegramBotFormComponent,
        TelegramBotListComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        LoadingUiModule,
        ReactiveFormsModule,
        ScrollUiModule,
        TelegramUiModule,
    ],
})
export class TelegramModule {}
