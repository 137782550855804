<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

    <div class="form-group">
        <div class="title-form-group">Заголовок</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="title"
                   placeholder="Текст"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['title'].invalid && form.controls['title'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">ID капсулы</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="capsuleId"
                   placeholder="1"
                   [readOnly]="isEdit"
                   [class.isReadOnly]="isEdit"
            >
            <span class="invalid-feedback"
                  *ngIf="form.controls['capsuleId'].invalid && form.controls['capsuleId'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">GEO Примитивы</div>
        <input type="text"
               class="form-control"
               formControlName="geoPrimitives"
               placeholder="Пример: 1,2,3,4"
        >
        <span class="invalid-feedback"
              *ngIf="form.controls['geoPrimitives'].invalid && form.controls['geoPrimitives'].touched">
        Некорректно заполненное поле
      </span>
    </div>

    <div class="form-group">
        <button class="btn btn-primary"
                [disabled]="cannotSubmit || form.invalid || !!formError">
            Сохранить
        </button>
        <button class="btn btn-secondary" (click)="onFormCancel()">Отмена</button>
        <div *ngIf="formError">{{ formError.message }}</div>
    </div>

</form>
