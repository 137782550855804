import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import {
    filter,
    map,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import * as authActions from '../actions/auth.actions';
import * as routerActions from '../actions/router.actions';
import * as authSelectors from '../selectors/auth.selectors';
import * as routerSelectors from '../selectors/router.selectors';


const logger = new Logger('RouterEffects');


@Injectable()
export class RouterEffects {

    constructor(
        private actions$: Actions,
        private store$: Store,
        private router: Router,
    ) {}

    goWithCurrentRedirectUriEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(routerActions.goWithCurrentRedirectUriAction),
            switchMap(({commands, extras}) => this.store$.pipe(
                select(routerSelectors.selectQueryParam('redirect_uri')),
                map(redirectUri => {
                    logger.debug('goWithCurrentRedirectUri:', commands, extras, redirectUri);

                    if (!extras) {
                        extras = {};
                    }
                    if (!extras.queryParams) {
                        extras.queryParams = {};
                    }
                    extras.queryParams.redirect_uri = redirectUri;

                    return {commands, extras};
                }),
                take(1),
            )),
            tap(({commands, extras}) =>
                this.router.navigate(commands, extras)
                    .catch(reason => logger.error('Navigate failed', reason)),
            ),
        ),
        {dispatch: false},
    );

    redirectToUriOrHomeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInSuccessAction,
            ),
            withLatestFrom(this.store$.pipe(
                select(routerSelectors.selectQueryParam('redirect_uri')),
            )),
            tap(([, redirectUri]) => logger.debug('redirectUri', redirectUri)),
            tap(([, redirectUri]) => this.router.navigateByUrl(redirectUri || '')),
        ),
        {dispatch: false},
    );

    goSignInIfNotAuthEffect$ = createEffect(
        () => this.store$.pipe(
            select(authSelectors.selectIsInit),
            filter(isInit => !!isInit),
            withLatestFrom(this.store$.pipe(
                select(authSelectors.selectIsAuth),
            )),
            filter(([, isAuth]) => !isAuth),
            tap(() => {
                const url = [
                    window.location.pathname,
                    window.location.search,
                    window.location.hash,
                ].join('');

                if (url.includes('/auth')) {
                    return;
                }

                const queryParams = (url && url !== '/' && url !== '/auth')
                    ? {redirect_uri: url}
                    : {};

                return this.router.navigate(
                    ['auth'],
                    {queryParams},
                );
            }),
        ),
        {dispatch: false},
    );

    goAuthEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signOutSuccessAction,
            ),
            tap(() => this.router.navigate(['auth'])),
        ),
        {dispatch: false},
    );

}
