import { Injectable } from '@angular/core';
import {
    NavigationEnd,
    Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
    filter,
    map,
} from 'rxjs/operators';


// https://semanticbits.com/route-history-service-in-angular/
// tslint:disable:variable-name
@Injectable()
export class RouteHistoryService {
    previousUrl$ = new BehaviorSubject<string | null>(null);
    currentUrl$ = new BehaviorSubject<string | null>(null);

    private _previousUrl: string | undefined;
    private _currentUrl: string | undefined;
    private readonly _routeHistory: string[];

    constructor(router: Router) {
        this.currentUrl$.next(router.url);
        this._routeHistory = [];
        router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
            map((event: NavigationEnd) => {
                this._setURLs(event);

                this.previousUrl$.next(
                    this.currentUrl$.value,
                );
                this.currentUrl$.next(event.urlAfterRedirects);

                console.log('routeHistory');
                console.log(event);
            }),
        );
    }

    private _setURLs(event: NavigationEnd): void {
        this._previousUrl = this._currentUrl;
        this._currentUrl = event.urlAfterRedirects;
        this._routeHistory.push(event.urlAfterRedirects);
    }

    get previousUrl(): string | undefined {
        return this._previousUrl;
    }

    get currentUrl(): string | undefined {
        return this._currentUrl;
    }

    get routeHistory(): string[] {
        return this._routeHistory;
    }

}
