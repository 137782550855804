import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    map,
    tap,
} from 'rxjs/operators';

import {
    LocationTag,
    Tag,
} from '../../schemas';
import * as utils from '../../utils';
import { Logger } from '../logger.service';


const logger = new Logger('LocationTagApi');

@Injectable({
    providedIn: 'root',
})
export class LocationTagApi {

    constructor(
        private http: HttpClient,
    ) { }


    fetchTagsByLocationIds(locationIds: number[] = []): Observable<LocationTag[]> {
        const endpoint = '/assets/json/locations/location-tags.json';

        return this.http.get<LocationTag[]>(endpoint).pipe(
            map(utils.convertKeysToCamel),
            tap(items => logger.debug('fetchTagsByLocationIds (items)', items)),
            map(items => {
                return locationIds.length
                    ? items.filter(
                        (item: LocationTag) => locationIds.includes(item.locationId),
                    )
                    : items;
            }),
        );
    }

    fetchTags(ids: number[]): Observable<Tag[]> {
        const endpoint = '/assets/json/locations/tags.json';

        return this.http.get<Tag[]>(endpoint).pipe(
            map(utils.convertKeysToCamel),
            tap(tags => logger.debug('fetchTags (tags)', tags)),
            map(tags => tags.filter(
                (tag: Tag) => ids.includes(tag.id),
            )),
        );
    }

}
