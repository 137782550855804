import {
    createAction,
    props,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';
import {
    Authorization,
    CurrentUser,
    PhoneCredentials,
} from '../../schemas/auth.schemas';


export const initCompleteAction = createAction(
    '[Auth] Init Complete',
);

export const signInAction = createAction(
    '[Auth] Sign In',
);

export const signInSuccessAction = createAction(
    '[Auth] Sign In Success',
    props<{ authorization: Authorization }>(),
);

export const signInFailureAction = createAction(
    '[Auth] Sign In Failure',
    props<{ error: schemas.ResponseError; }>(),
);

export const signInByPhoneCredentialsAction = createAction(
    '[Auth] Sign In By Phone Credentials',
    props<{ credentials: PhoneCredentials }>(),
);

export const signInByPhoneCredentialsSuccessAction = createAction(
    '[Auth] Sign In By Phone Credentials Success',
    props<{ authorization: Authorization }>(),
);

export const signInByPhoneCredentialsFailureAction = createAction(
    '[Auth] Sign In By Phone Credentials Failure',
    props<{ error: schemas.ResponseError; }>(),
);

export const signOutAction = createAction(
    '[Auth] Sign Out',
);

export const signOutSuccessAction = createAction(
    '[Auth] Sign Out Success',
);

export const signOutFailureAction = createAction(
    '[Auth] Sign Out Failure',
);

export const createDeviceAction = createAction(
    '[Auth] Create Device',
);

export const setDeviceAction = createAction(
    '[Auth] Set Device',
    props<{ device: string | null; }>(),
);

export const setAuthorizationAction = createAction(
    '[Auth] Set Authorization',
    props<{ authorization: Authorization }>(),
);

export const setCurrentUserAction = createAction(
    '[Auth] Set Current User',
    props<{ currentUser: CurrentUser | null }>(),
);
