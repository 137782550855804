import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { WidgetPackage } from '../../../../schemas/widget.schemas';
import { UniqueTitleValidator } from '../../../../validators/widget-package.validator';


const logger = new Logger('WidgetPackageFormComponent');

@Component({
    selector: 'app-widget-package-form',
    templateUrl: './widget-package-form.component.html',
    styleUrls: ['./widget-package-form.component.scss'],
})
export class WidgetPackageFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    form: FormGroup;

    @Input() widgetPackage?: WidgetPackage | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor(
        private readonly titleValidator: UniqueTitleValidator,
    ) {

        this.form = new FormGroup({

            title: new FormControl('', {
                validators: [
                    Validators.required,
                ],
                asyncValidators: [
                    this.titleValidator.validate.bind(this.titleValidator),
                ],
                updateOn: 'blur',
            }),

        });

    }

    ngOnInit(): void {
        this.titleValidator.setPackageId(this.widgetPackage?.id);
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.widgetPackage?.currentValue) {
            this.titleValidator.setPackageId(changes.widgetPackage?.currentValue?.id);
            this.form.patchValue({
                ...changes.widgetPackage.currentValue,
            });
        }
    }

    ngOnDestroy(): void {
        this.titleValidator.setPackageId();
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const result = {
            ...this.widgetPackage,
            ...this.form.value,
        };

        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }

}
