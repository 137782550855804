import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';


const logger = new Logger('GeoRouteFormComponent');

@Component({
    selector: 'app-geo-route-form',
    templateUrl: './geo-route-form.component.html',
    styleUrls: ['./geo-route-form.component.scss'],
})
export class GeoRouteFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    form: FormGroup;

    @Input() geoRoute?: schemas.GeoRoute | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;
    @Input() isEdit = false;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor() {

        this.form = new FormGroup({

            title: new FormControl('', [
                Validators.required,
            ]),

            capsuleId: new FormControl('', [
                Validators.required,
                Validators.pattern('[0-9]+'),
            ]),

            geoPrimitives: new FormControl('', [
                Validators.required,
            ]),
        });

    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        const geoPrimitives = changes.geoRoute?.currentValue?.geoPrimitives.join(',');

        if (changes.geoRoute?.currentValue) {
            this.form.patchValue({
                ...changes.geoRoute.currentValue,
                geoPrimitives,
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const geoPrimitives = this.form.value.geoPrimitives.split(',').map((text: string) => {
            return Number(text);
        });

        const result = {
            ...this.geoRoute,
            ...this.form.value,
            geoPrimitives,
        };

        logger.debug('result', result);

        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }

}
