import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    User,
    UserFindParams,
} from '../schemas/user.schemas';
import { UserApi } from '../services/api/user.api';


const logger = new Logger('UserFacade');

@Injectable({
    providedIn: 'root',
})
export class UserFacade {

    constructor(
        private store$: Store,
        private userApi: UserApi,
    ) { }

    fetchUsers(params: UserFindParams): Observable<User[]> {
        return this.userApi.findUsers(params);
    }

}
