import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { LocationFacade } from '../../../../facades/location.facade';
import {
    Location,
    LocationFindParams,
} from '../../../../schemas/location.schemas';


const logger = new Logger('LocationListComponent');

@Component({
    selector: 'app-location-list',
    templateUrl: './location-list.component.html',
    styleUrls: ['./location-list.component.scss'],
})
export class LocationListComponent implements OnInit, OnChanges {

    locations: Location[] = [];

    @Input() params?: LocationFindParams | null;

    @Output() locationClick = new EventEmitter();

    constructor(
        private locationFacade: LocationFacade,
    ) { }

    ngOnInit(): void {
        if (!this.params) {
            this.loadNextLocations();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params?.currentValue) {
            this.params = changes.params?.currentValue;
            this.locations = [];
            this.loadNextLocations();
        }
    }

    loadNextLocations(): void {
        this.locationFacade.fetchLocations({
            ...this.params,
            afterId: this.locations[this.locations.length - 1]?.id || 0,
            offset: this.locations.length,
            limit: this.params?.limit || 100,
        }).subscribe(locations => {
            this.locations.push(...locations);
        });
    }

    onLocationClick(location: Location): void {
        this.locationClick.emit(location);
    }

    delete(location: Location): void {
        if (confirm('Удалить локацию ' + location.title + '?')) {
            this.locationFacade.deleteLocation(location).subscribe(result => {
                if (result) {
                    this.locations = this.locations.filter(
                        item => item.id !== location.id,
                    );
                }
            });
        }
    }

}
