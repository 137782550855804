import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    Authorization,
    PhoneCredentials,
} from '../../../schemas/auth.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('PhoneAuthApi');

@Injectable({
    providedIn: 'root',
})
export class PhoneAuthApi {

    constructor(
        private apiService: ApiService,
    ) { }

    authorize(credentials: PhoneCredentials): Observable<Authorization> {
        return this.apiService.postJson<Authorization>(
            '/authentication/phone/authorize',
            credentials,
        ).pipe(
            tap(authorization => logger.debug('authorize (authorization)', authorization)),
        );
    }

}
