import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Service,
    ServiceFindParams,
} from '../../../schemas/service.schemas';


const logger = new Logger('ServicePageComponent');

@Component({
    selector: 'app-service-page',
    templateUrl: './service-page.component.html',
    styleUrls: ['./service-page.component.scss'],
})
export class ServicePageComponent {

    serviceFindParams: ServiceFindParams = {
        limit: 0,
    };

    constructor(
        private router: Router,
    ) { }

    onServiceClick(service: Service): void {
        this.router.navigate(['/services', service.id])
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
