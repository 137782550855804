import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimpleInfiniteScrollComponent } from './simple-infinite-scroll/simple-infinite-scroll.component';


@NgModule({
    declarations: [
        SimpleInfiniteScrollComponent,
    ],
    exports: [
        SimpleInfiniteScrollComponent,
    ],
    imports: [
        CommonModule,
    ],
})
export class ScrollUiModule {}
