import { Component } from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import {
    map,
    tap,
} from 'rxjs/operators';
import { GeoRouteFacade } from '../../../facades/geo/geo-route.facade';


const logger = new Logger('GeoRouteEditComponent');

@Component({
    selector: 'app-geo-route-edit',
    templateUrl: './geo-route-edit.component.html',
    styleUrls: ['./geo-route-edit.component.scss'],
})
export class GeoRouteEditComponent {

    readonly geoRoute$ = this.route.data.pipe(
        map((data: { geoRoute?: schemas.GeoRoute }) => data.geoRoute),
        tap(data => logger.debug('data', data)),
    );

    cannotSubmit = false;
    formError?: schemas.ResponseError;

    constructor(
        private geoRouteFacade: GeoRouteFacade,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    onFormSubmit(formData: schemas.GeoRouteUpdate & { id: number }): void {
        logger.debug('onFormSubmit', formData);

        this.cannotSubmit = true;
        this.geoRouteFacade.updateGeoRoute(formData.id, formData).subscribe({
            next: () => this.router.navigate(['geo', 'route']),
            error: error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        });
    }

    goBack(): void {
        this.router.navigate(['geo', 'route'])
            .catch(reason => logger.error('Navigate failed', reason));
    }
}
