import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { schemas } from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { AuthFacade } from '../../../facades/auth.facade';


@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    signInForm: FormGroup;
    cannotSubmit = false;
    formError: schemas.ResponseError | null = null;

    constructor(
        private authFacade: AuthFacade,
    ) {

        this.signInForm = new FormGroup({

            phone: new FormControl('', [
                Validators.required,
                Validators.pattern(/^([0-9]{8,14}|)$/),
            ]),

            password: new FormControl('', [
                Validators.required,
            ]),

        });

    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.authFacade.error$.subscribe(error =>
                this.formError = error,
            ),
        );
        this.subscriptions.push(
            this.authFacade.isLoading$.subscribe(isLoading =>
                this.cannotSubmit = isLoading,
            ),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(): void {
        this.formError = null;
    }

    onFormSubmit(): void {
        this.signInForm.value.phone = '+7' + this.signInForm.value.phone;
        this.authFacade.signInByPhoneCredentials(this.signInForm.value);
    }

}
