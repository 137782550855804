<div class="preview-image">

    <div [ngStyle]="{'background-image': 'url(' + image + ')'}"
         class="preview-image__background"
         [class.preview-image__active]="isActive"
    >
        <div class="preview-image__controls">
            <i *ngIf="!canEdit" (click)="onClickImage(false)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0 8C0 12.4183 3.58172 16 8 16C9.84871 16 11.551 15.3729 12.9056 14.3199L18.2929 19.7071L19.7071 18.2929L14.3199 12.9056C15.3729 11.551 16 9.84871 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM7 4V7H4V9H7V12H9V9H12V7H9V4H7Z"
                          fill="white"/>
                </svg>
            </i>

            <i *ngIf="canEdit" (click)="onClickImage(true)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M14.4374 0C15.0921 0 15.7197 0.26142 16.1781 0.723423L19.279 3.82432C19.7407 4.286 20.0001 4.91217 20.0001 5.56508C20.0001 6.21799 19.7407 6.84416 19.279 7.30584L7.95751 18.6238C7.25902 19.4295 6.2689 19.9245 5.1346 20.0023H0V19.0023L0.00324765 14.7873C0.0884382 13.7328 0.578667 12.7523 1.3265 12.0934L12.6954 0.724628C13.1564 0.26083 13.7834 0 14.4374 0ZM5.06398 18.0048C5.59821 17.967 6.09549 17.7184 6.49479 17.2616L14.0567 9.69972L10.3023 5.94519L2.6961 13.5496C2.29095 13.9079 2.04031 14.4092 2 14.8678V18.0029L5.06398 18.0048ZM11.7167 4.53115L15.4709 8.2855L17.8648 5.89162C17.9514 5.80502 18.0001 5.68756 18.0001 5.56508C18.0001 5.4426 17.9514 5.32514 17.8648 5.23854L14.7611 2.13486C14.6755 2.04855 14.5589 2 14.4374 2C14.3158 2 14.1992 2.04855 14.1136 2.13486L11.7167 4.53115Z"
                          fill="white"/>
                </svg>
            </i>
        </div>
    </div>

    <i (click)="onDeletePreviewImage()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect width="24" height="24" rx="12" fill="black" fill-opacity="1"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.0001 13.4142L7.70718 17.7071L6.29297 16.2929L10.5859 12L6.29297 7.70711L7.70718 6.29289L12.0001 10.5858L16.293 6.29289L17.7072 7.70711L13.4143 12L17.7072 16.2929L16.293 17.7071L12.0001 13.4142V13.4142Z"
                  fill="white"/>
        </svg>
    </i>

    <div class="preview-image__order" (click)="onOrderImage(image)" *ngIf="order">
        {{ order }}
    </div>

</div>
