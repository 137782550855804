import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';


const logger = new Logger('MediaFacade');

@Injectable({
    providedIn: 'root',
})
export class MediaFacade {

    constructor(
        private http: HttpClient,
    ) {
    }

    dataURItoBlob(dataURI: string): Blob {
        const binary = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: mimeString});
    }

    uploadMedia(file: Blob, filename: string): any {
        const httpOptions = {
            headers: {},
            processData: false,
            contentType: false,
        };

        const formData = new FormData();
        formData.append('file', file, filename);

        return this.http.post(environment.api.iterraUrl + 'media/public', formData, httpOptions).pipe(
            first(),
        );
    }
}
