import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-preview-image',
    templateUrl: './preview-image.component.html',
    styleUrls: ['./preview-image.component.scss'],
})
export class PreviewImageComponent {

    @Input() image?: string;
    @Input() canEdit = false;
    @Input() isActive = false;
    @Input() order?: number;

    @Output() deletePreviewImage = new EventEmitter<string>();
    @Output() showImage = new EventEmitter<string>();
    @Output() editImage = new EventEmitter<string>();
    @Output() orderImage = new EventEmitter<string>();

    onDeletePreviewImage(): void {
        this.deletePreviewImage.emit(this.image);
    }

    onClickImage(canEdit: boolean): void {
        if (canEdit) {
            this.editImage.emit(this.image);
        } else {
            this.showImage.emit(this.image);
        }
    }

    onOrderImage(image?: string): void {
        if (!image) {
            return;
        }
        this.orderImage.emit(image);
    }
}
