import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { ServiceFacade } from '../../../facades/service.facade';
import {
    Service,
    ServiceFindParams,
} from '../../../schemas/service.schemas';


const logger = new Logger('ServiceListComponent');

@Component({
    selector: 'app-service-list',
    templateUrl: './service-list.component.html',
    styleUrls: ['./service-list.component.scss'],
})
export class ServiceListComponent implements OnInit, OnChanges {

    services: Service[] = [];

    @Input() params?: ServiceFindParams | null;

    @Output() serviceClick = new EventEmitter();

    constructor(
        private serviceFacade: ServiceFacade,
    ) { }

    ngOnInit(): void {
        if (!this.params) {
            this.loadNextServices();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params?.currentValue) {
            this.params = changes.params?.currentValue;
            this.services = [];
            this.loadNextServices();
        }
    }

    loadNextServices(): void {
        this.serviceFacade.fetchServices({
            ...this.params,
            offset: this.services.length,
            limit: this.params?.limit || 0,
        }).subscribe(services => {
            this.services.push(...services);
        });
    }

    onServiceClick(service: Service): void {
        this.serviceClick.emit(service);
    }

    delete(service: Service): void {
        if (confirm('Удалить сервис ' + service.title + '?')) {
            this.serviceFacade.deleteService(service).subscribe(result => {
                if (result) {
                    this.services = this.services.filter(
                        item => item.id !== service.id,
                    );
                }
            });
        }
    }

}
