import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { WIDGET_TYPE_NAMES } from '../../../../app.constants';
import { WidgetFacade } from '../../../../facades/widget.facade';
import { Widget } from '../../../../schemas/widget.schemas';


const logger = new Logger('WidgetPackageWidgetsComponent');

@Component({
    selector: 'app-widget-package-widgets',
    templateUrl: './widget-package-widgets.component.html',
    styleUrls: ['./widget-package-widgets.component.scss'],
})
export class WidgetPackageWidgetsComponent implements OnInit, OnChanges {

    @Input() widgetPackageId?: number;

    @Output() widgetClick = new EventEmitter();

    widgets: Widget[] = [];

    constructor(
        private readonly widgetFacade: WidgetFacade,
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.widgetPackageId?.currentValue) {
            const widgetPackageId = changes.widgetPackageId?.currentValue;

            if (widgetPackageId) {
                this.widgetFacade.fetchWidgetPackageWidgets(widgetPackageId).subscribe(
                    widgets => {
                        widgets.sort((a, b) => a.typeId - b.typeId);
                        this.widgets = widgets;
                    },
                );
            }
        }
    }

    onWidgetClick(widget: Widget): void {
        this.widgetClick.emit(widget);
    }

    getWidgetTypeName(widgetTypeId: number): string {
        return WIDGET_TYPE_NAMES[widgetTypeId] || 'неизвестный';
    }
}
