import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { WidgetFacade } from '../../../facades/widget.facade';
import {
    Widget,
    WidgetType,
} from '../../../schemas/widget.schemas';
import { WidgetApi } from '../../../services/api/widget/widget.api';
import { UniqueNicknameValidator } from '../../../validators/widget.validator';


const logger = new Logger('WidgetFormComponent');

@Component({
    selector: 'app-widget-form',
    templateUrl: './widget-form.component.html',
    styleUrls: ['./widget-form.component.scss'],
})
export class WidgetFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    widgetTypes: WidgetType[] = [];
    form: FormGroup;

    @Input() widget?: Widget | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor(
        private readonly nicknameValidator: UniqueNicknameValidator,
        private readonly widgetApi: WidgetApi,
        private readonly widgetFacade: WidgetFacade,
    ) {

        this.form = new FormGroup({

            nickname: new FormControl('', {
                validators: [
                    Validators.pattern('^[a-z]+([-]?[a-z0-9]+)*$'),
                    Validators.required,
                ],
                asyncValidators: [
                    nicknameValidator.validate.bind(nicknameValidator),
                ],
                updateOn: 'blur',
            }),

            title: new FormControl('', [
                Validators.required,
            ]),

            subtitle: new FormControl(null),

            icon: new FormControl(null),

            typeId: new FormControl(''),

            manifest: new FormControl(''),

        });

        widgetFacade.fetchWidgetTypes().subscribe(
            widgetTypes => this.widgetTypes = widgetTypes,
        );
    }

    ngOnInit(): void {
        this.nicknameValidator.setWidgetId(this.widget?.id);
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.widget?.currentValue) {
            const manifest = JSON.stringify(changes.widget?.currentValue?.manifest, null, 4);
            this.nicknameValidator.setWidgetId(changes.widget?.currentValue?.id);
            this.form.patchValue({
                ...changes.widget.currentValue,
                manifest,
            });
        }
    }

    ngOnDestroy(): void {
        this.nicknameValidator.setWidgetId();
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const manifest = JSON.parse(this.form.value.manifest || '{}');
        const result = {
            ...this.widget,
            ...this.form.value,
            manifest,
        };

        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }

}
