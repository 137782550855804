import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ImageCropperModule } from 'ngx-image-cropper';

import { UploadUiModule } from '../../ui/upload/upload.module';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { UploadCroppedImagesComponent } from './upload-cropped-images/upload-cropped-images.component';


@NgModule({
    declarations: [
        UploadCroppedImagesComponent,
        ImageCropperComponent,
    ],
    exports: [
        UploadCroppedImagesComponent,
    ],
    imports: [
        CommonModule,
        UploadUiModule,
        ReactiveFormsModule,
        ImageCropperModule,
        DragDropModule,
        ScrollingModule,
    ],
})
export class UploadModule {}
