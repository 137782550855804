import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';
import {
    WidgetPackage,
    WidgetPackageCreate,
    WidgetPackageFindParams,
    WidgetPackageUpdate,
} from '../schemas/widget.schemas';
import { PackageApi } from '../services/api/widget/package.api';


const logger = new Logger('WidgetPackageFacade');

@Injectable({
    providedIn: 'root',
})
export class WidgetPackageFacade {

    constructor(
        private store$: Store,
        private packageApi: PackageApi,
    ) { }

    fetchWidgetPackages(params: WidgetPackageFindParams): Observable<WidgetPackage[]> {
        return this.packageApi.findWidgetPackages(params);
    }

    fetchWidgetPackageById(id: number): Observable<WidgetPackage> {
        return this.packageApi.fetchWidgetPackageById(id);
    }

    createWidgetPackage(widgetPackage: WidgetPackageCreate): Observable<WidgetPackage> {
        return this.packageApi.createWidgetPackage(widgetPackage);
    }

    updateWidgetPackage(id: number, widgetPackage: WidgetPackageUpdate): Observable<WidgetPackage> {
        return this.packageApi.updateWidgetPackageById(id, widgetPackage);
    }

    deleteWidgetPackage(widgetPackage: WidgetPackage): Observable<boolean> {
        return this.packageApi.deleteWidgetPackageById(widgetPackage.id).pipe(
            map(() => true),
            catchError(() => of(false)),
        );
    }

}
