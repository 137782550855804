import { Routes } from '@angular/router';
import { TelegramBotAddComponent } from '../modules/telegram/telegram-bot-add/telegram-bot-add.component';
import { TelegramBotEditComponent } from '../modules/telegram/telegram-bot-edit/telegram-bot-edit.component';
import { TelegramBotListComponent } from '../modules/telegram/telegram-bot-list/telegram-bot-list.component';
import { TelegramBotResolver } from '../resolvers/telegram-bot.resolver';


export const telegramBotsRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: TelegramBotListComponent,
            },
            {
                path: 'add',
                component: TelegramBotAddComponent,
            },
            {
                path: ':botId',
                component: TelegramBotEditComponent,
                resolve: {
                    telegramBot: TelegramBotResolver,
                },
            },
        ],
    },
];
