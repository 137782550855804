import {
    Component,
    OnDestroy,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import {
    map,
    tap,
} from 'rxjs/operators';
import { TelegramFacade } from '../../../facades/telegram.facade';
import {
    TelegramBot,
    TelegramBotEdit,
} from '../../../schemas/telegram.schemas';


const logger = new Logger('TelegramBotEditComponent');

@Component({
    selector: 'app-telegram-bot-edit',
    templateUrl: './telegram-bot-edit.component.html',
    styleUrls: ['./telegram-bot-edit.component.scss'],
})
export class TelegramBotEditComponent implements OnDestroy {
    private subscriptions: Subscription[] = [];

    readonly bot$ = this.activatedRoute.data.pipe(
        map((data: { telegramBot?: TelegramBot }) => data.telegramBot),
        tap(data => logger.debug('data', data)),
    );

    public cannotSubmit = false;
    public formError?: schemas.ResponseError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private telegramFacade: TelegramFacade,
    ) {
    }

    onFormSubmit(formData: TelegramBotEdit & { id: number }): void {
        logger.debug('onFormSubmit', formData);

        this.cannotSubmit = true;
        this.subscriptions.push(
            this.telegramFacade.updateTelegramBotById(formData.id, formData).subscribe(
                {
                    next: () => {
                        this.goBack();
                    },
                    error: (error) => {
                        this.formError = error;
                        this.cannotSubmit = false;
                    },
                },
            ),
        );
    }

    goBack(): void {
        this.router.navigate(['..'], {relativeTo: this.activatedRoute})
            .catch(reason => logger.error('Navigate failed', reason));
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
        logger.debug('destroy');
    }
}
