<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

  <div class="form-group">
    <div class="title-form-group">Название пакета</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="title"
             placeholder="Введите название..."
             required>
      <span class="invalid-feedback"
            *ngIf="form.controls['title'].invalid && form.controls['title'].touched">
        Некорректно заполненное поле
        <span *ngIf="form.controls['title'].hasError('unique')">(такой уже есть)</span>
      </span>
    </div>
  </div>

  <div class="form-group">
    <button class="btn btn-primary"
            [disabled]="cannotSubmit || form.invalid || !!formError">
      Сохранить
    </button>
    <button class="btn btn-secondary" (click)="onFormCancel()">Отмена</button>
    <div *ngIf="formError">{{ formError.message }}</div>
  </div>

</form>
