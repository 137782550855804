<div class="bot">
    <div class="bot__info" (click)="onClickBot()">
        <div class="bot__id">ID: {{ bot?.id }}</div>
        {{ bot?.botName }}
    </div>
    <div (click)="onDeleteBot()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22" fill="inherit">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7 0H13C14.1046 0 15 0.89543 15 2V3H18C19.1046 3 20 3.89543 20 5V7C20 8.10457 19.1046 9 18 9H17.9199L17 20C17 21.1046 16.1046 22 15 22H5C3.89543 22 3 21.1046 3.00345 20.083L2.07987 9H2C0.89543 9 0 8.10457 0 7V5C0 3.89543 0.89543 3 2 3H5V2C5 0.89543 5.89543 0 7 0ZM2 5H5H15H18V7H2V5ZM4.08649 9H15.9132L15.0035 19.917L15 20H5L4.08649 9ZM13 2V3H7V2H13ZM11.4142 14L13.7071 16.2929L12.2929 17.7071L10 15.4142L7.70711 17.7071L6.29289 16.2929L8.58579 14L6.29289 11.7071L7.70711 10.2929L10 12.5858L12.2929 10.2929L13.7071 11.7071L11.4142 14Z"
                  fill="inherit"/>
        </svg>
    </div>
</div>
