import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    User,
    UserFindParams,
} from '../../schemas/user.schemas';
import { ApiService } from './api.service';


const logger = new Logger('UserApi');

@Injectable({
    providedIn: 'root',
})
export class UserApi {

    constructor(
        private apiService: ApiService,
    ) { }

    findUsers(params: UserFindParams): Observable<User[]> {
        return this.apiService.getJson<User[]>(
            '/user',
            params,
        ).pipe(
            tap(users => logger.debug('findUsers (users)', users)),
        );
    }

    getUserById(id: number): Observable<User> {
        return this.apiService.getJson<User>(
            '/user/' + id,
        ).pipe(
            tap(user => logger.debug('getUserById (user)', user)),
        );
    }

}
