import { Component } from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { tap } from 'rxjs/operators';
import { defaultLimit } from '../../../app.constants';
import { GeoPrimitiveFacade } from '../../../facades/geo/geo-primitive.facade';


const logger = new Logger('GeoPrimitiveListComponent');

@Component({
    selector: 'app-geo-primitive-list',
    templateUrl: './geo-primitive-list.component.html',
    styleUrls: ['./geo-primitive-list.component.scss'],
})
export class GeoPrimitiveListComponent {

    isLoading = false;
    offset = 0;
    limit = defaultLimit;
    geoPrimitives: schemas.GeoPrimitive[] = [];

    constructor(
        private geoPrimitiveFacade: GeoPrimitiveFacade,
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    fetchContent(): void {
        this.isLoading = true;

        const params: schemas.GeoRouteFindParams = {
            offset: this.offset,
            limit: this.limit,
        };

        this.geoPrimitiveFacade.fetchGeoPrimitives(params).pipe(
            tap(geo => logger.debug('fetchGeoPrimitives (geo)', geo)),
        ).subscribe(geo => {
            if (!geo) {
                this.isLoading = false;
                return;
            }

            this.geoPrimitives.push(...geo);

            if (geo.length < this.limit) {
                this.offset += geo.length;
            } else {
                this.offset += this.limit;
            }
            this.isLoading = false;
        });
    };

    goToAddGeoPrimitive(): void {
        this.router.navigate(['add'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

    goToEditGeoPrimitive(id: number): void {
        this.router.navigate([id], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

    deleteGeo(geo: schemas.GeoPrimitive): void {
        if (confirm('Удалить GEO примитив ID: ' + geo.id + '?')) {
            this.geoPrimitiveFacade.deleteGeoPrimitiveId(geo.id).subscribe(result => {
                if (result) {
                    this.geoPrimitives = this.geoPrimitives.filter(
                        item => item.id !== geo.id,
                    );
                }
            });
        }
    }
}
