import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TelegramBot } from '../../../schemas/telegram.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-telegram-bot',
    templateUrl: './telegram-bot.component.html',
    styleUrls: ['./telegram-bot.component.scss'],
})
export class TelegramBotComponent {
    @Input() public bot?: TelegramBot;

    @Output() clickBot = new EventEmitter<TelegramBot>();
    @Output() deleteBot = new EventEmitter<TelegramBot>();

    public onClickBot(): void {
        this.clickBot.emit(this.bot);
    }

    public onDeleteBot(): void {
        this.deleteBot.emit(this.bot);
    }
}
