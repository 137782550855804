import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';
import {
    Service,
    ServiceCreate,
    ServiceFindParams,
    ServiceUpdate,
} from '../schemas/service.schemas';
import { ServiceApi } from '../services/api/capsule/service.api';


const logger = new Logger('ServiceFacade');

@Injectable({
    providedIn: 'root',
})
export class ServiceFacade {

    constructor(
        private store$: Store,
        private serviceApi: ServiceApi,
    ) { }

    fetchServices(params: ServiceFindParams): Observable<Service[]> {
        return this.serviceApi.findServices(params);
    }

    fetchServiceById(id: number): Observable<Service> {
        return this.serviceApi.fetchServiceById(id);
    }

    createService(service: ServiceCreate): Observable<Service> {
        return this.serviceApi.createService(service);
    }

    updateService(id: number, service: ServiceUpdate): Observable<Service> {
        return this.serviceApi.updateServiceById(id, service);
    }

    deleteService(service: Service): Observable<boolean> {
        return this.serviceApi.deleteServiceById(service.id).pipe(
            map(() => true),
            catchError(() => of(false)),
        );
    }

}
