<div *ngIf="widgetPackage" class="buttons">
  <button class="btn btn-primary" [routerLink]="['edit']">Редактировать</button>
  <button class="btn btn-primary" [routerLink]="['link-widgets']">Привязать виджеты</button>
</div>
<br>
<div>
  <div>ID - {{ widgetPackage?.id }}</div>
  <div>{{ widgetPackage?.title }}</div>
</div>
<br>
<div>
  <div>Виджеты:</div>
  <app-widget-package-widgets [widgetPackageId]="widgetPackage?.id"></app-widget-package-widgets>
</div>
