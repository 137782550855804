import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    WidgetPackage,
    WidgetPackageCreate,
    WidgetPackageFindParams,
    WidgetPackageUpdate,
} from '../../../schemas/widget.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('PackageApi');

@Injectable({
    providedIn: 'root',
})
export class PackageApi {

    constructor(
        private apiService: ApiService,
    ) { }

    findWidgetPackages(params: WidgetPackageFindParams): Observable<WidgetPackage[]> {
        return this.apiService.getJson<WidgetPackage[]>(
            '/widget/widget-package',
            params,
        ).pipe(
            tap(packages => logger.debug('findWidgetPackages (packages)', packages)),
        );
    }

    fetchWidgetPackageById(id: number): Observable<WidgetPackage> {
        return this.apiService.getJson<WidgetPackage>(
            '/widget/widget-package/' + id,
        ).pipe(
            tap(widgetPackage => logger.debug('fetchWidgetPackageById (widgetPackage)', widgetPackage)),
        );
    }

    createWidgetPackage(data: WidgetPackageCreate): Observable<WidgetPackage> {
        return this.apiService.postJson<WidgetPackage>(
            '/widget/widget-package',
            data,
        ).pipe(
            tap(widgetPackage => logger.debug('createWidgetPackage (widgetPackage)', widgetPackage)),
        );
    }

    updateWidgetPackageById(id: number, data: WidgetPackageUpdate): Observable<WidgetPackage> {
        return this.apiService.patchJson<WidgetPackage>(
            '/widget/widget-package/' + id,
            data,
        ).pipe(
            tap(widgetPackage => logger.debug('updateWidgetPackageById (widgetPackage)', widgetPackage)),
        );
    }

    deleteWidgetPackageById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/widget/widget-package/' + id,
        ).pipe(
            tap(() => logger.debug('deleteWidgetPackageById (id)', id)),
        );
    }

}
