import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './not-found.component';


const components = [
    NotFoundComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
    ],
    exports: [...components],
})
export class NotFoundModule {}
