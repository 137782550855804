<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

  <div class="form-group">
    <div class="title-form-group">Виджеты</div>
    <ng-multiselect-dropdown
            [placeholder]="'Выберите виджеты'"
            [settings]="dropdownSettings"
            [data]="widgets"
            formControlName="widgets"
    ></ng-multiselect-dropdown>
  </div>

  <div class="form-group">
    <button class="btn btn-primary"
            [disabled]="cannotSubmit || form.invalid || !!formError">
      Сохранить
    </button>
    <button class="btn btn-secondary" (click)="goBack()">Отмена</button>
    <div *ngIf="formError">{{ formError.message }}</div>
  </div>

</form>
