import {
    Injectable,
    Type,
} from '@angular/core';
import {
    Observable,
    of,
} from 'rxjs';
import {
    ComponentRenderMethod,
    ComponentRenderOptions,
} from './dynamic-overlay.schemas';


const defaultComponentRender = () => of(null);

@Injectable({
    providedIn: 'root',
})
export class DynamicOverlayService {

    private componentRender: ComponentRenderMethod = defaultComponentRender;

    renderComponent(
        componentType: Type<any> | null,
        options?: ComponentRenderOptions,
    ): Observable<any | null> {
        return this.componentRender(componentType, options);
    }

    destroyComponent(component: Type<any>): void {}

    clear(): void {
        this.componentRender(null);
    }

    setComponentRender(
        componentRender: ComponentRenderMethod,
    ): void {
        this.componentRender = componentRender;
    }

    resetComponentRender(): void {
        this.componentRender = defaultComponentRender;
    }

}
