import { Routes } from '@angular/router';
import { CapsuleAddComponent } from '../modules/capsule/capsule-add/capsule-add.component';
import { CapsuleDetailComponent } from '../modules/capsule/capsule-detail/capsule-detail.component';
import { CapsuleEditComponent } from '../modules/capsule/capsule-edit/capsule-edit.component';
import { CapsulePageComponent } from '../modules/capsule/capsule-page/capsule-page.component';
import { LocationWidgetEditComponent } from '../modules/capsule/location-widget/location-widget-edit/location-widget-edit.component';
import { LocationAddComponent } from '../modules/capsule/location/location-add/location-add.component';
import { LocationDetailComponent } from '../modules/capsule/location/location-detail/location-detail.component';
import { LocationEditComponent } from '../modules/capsule/location/location-edit/location-edit.component';
import { LicenseAddComponent } from '../modules/widget/license-add/license-add.component';
import { LicenseDetailComponent } from '../modules/widget/license-detail/license-detail.component';
import { CapsuleResolver } from '../resolvers/capsule.resolver';
import { LicenseResolver } from '../resolvers/license.resolver';
import { LocationWidgetResolver } from '../resolvers/location-widget.resolver';
import { LocationResolver } from '../resolvers/location.resolver';
import { telegramBotsRoutes } from './telegram.routes';


export const capsulesRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: CapsulePageComponent,
    },
    {
        path: 'add',
        component: CapsuleAddComponent,
        data: {
            breadcrumb: 'Добавление новой капсулы',
        },
    },
    {
        path: ':capsuleId',
        runGuardsAndResolvers: 'always',
        resolve: {
            capsule: CapsuleResolver,
        },
        data: {
            breadcrumb: (data: any) => `${data.capsule.title} (ID:${data.capsule.id})`,
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: CapsuleDetailComponent,
            },
            {
                path: 'edit',
                component: CapsuleEditComponent,
                data: {
                    breadcrumb: 'Редактирование',
                },
            },
            {
                path: 'telegram-bots',
                data: {
                    breadcrumb: 'Telegram Боты',
                },
                children: [...telegramBotsRoutes],
            },
            {
                path: 'locations',
                children: [
                    // {
                    //     path: '',
                    //     pathMatch: 'full',
                    //     component: LocationPageComponent,
                    // },
                    {
                        path: 'add',
                        component: LocationAddComponent,
                        data: {
                            breadcrumb: 'Добавление новой локации',
                        },
                    },
                    {
                        path: ':locationId',
                        runGuardsAndResolvers: 'always',
                        resolve: {
                            location: LocationResolver,
                        },
                        data: {
                            breadcrumb: (data: any) => `${data.location.title} (ID:${data.location.id})`,
                        },
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                component: LocationDetailComponent,
                            },
                            {
                                path: 'edit',
                                component: LocationEditComponent,
                                data: {
                                    breadcrumb: 'Редактирование',
                                },
                            },
                            {
                                path: 'licenses',
                                children: [
                                    // {
                                    //     path: '',
                                    //     pathMatch: 'full',
                                    //     component: LicensePageComponent,
                                    // },
                                    {
                                        path: 'add',
                                        component: LicenseAddComponent,
                                        data: {
                                            breadcrumb: 'Добавление виджета',
                                        },
                                    },
                                    {
                                        path: ':licenseId',
                                        runGuardsAndResolvers: 'always',
                                        resolve: {
                                            license: LicenseResolver,
                                        },
                                        data: {
                                            breadcrumb: (data: any) => `${data.license.title} (ID:${data.license.id})`,
                                        },
                                        children: [
                                            {
                                                path: '',
                                                pathMatch: 'full',
                                                component: LicenseDetailComponent,
                                            },
                                            {
                                                path: 'location-widgets',
                                                children: [
                                                    {
                                                        path: ':locationWidgetId',
                                                        runGuardsAndResolvers: 'always',
                                                        resolve: {
                                                            locationWidget: LocationWidgetResolver,
                                                        },
                                                        children: [
                                                            {
                                                                path: 'edit',
                                                                component: LocationWidgetEditComponent,
                                                                data: {
                                                                    breadcrumb: 'Редактирование',
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
