import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Logger } from '@scatch/ngx-app-lib';
import {
    fromEvent,
    of,
} from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import * as appActions from '../actions/app.actions';
import * as authActions from '../actions/auth.actions';
import * as localStorageActions from '../actions/local-storage.actions';


const logger = new Logger('LocalStorageEffects');

@Injectable()
export class LocalStorageEffects {

    constructor(
        private actions$: Actions,
    ) {}

    saveDeviceEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.setDeviceAction),
            filter(({device}) => !!device),
            tap(({device}) => logger.debug('Save Device', device)),
            tap(({device}) => device && localStorage.setItem('device', device)),
        ),
        {dispatch: false},
    );

    syncDeviceEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                appActions.initAction,
                localStorageActions.syncDeviceAction,
            ),
            map(() => {
                const device = localStorage.getItem('device');

                logger.debug('Sync Device', device);

                return authActions.setDeviceAction({device});
            }),
        ),
    );

    saveAuthorizationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInSuccessAction,
                authActions.setAuthorizationAction,
            ),
            tap(({authorization}) => {
                logger.info('Save authorization to local storage', authorization);
                localStorage.setItem('authorization', JSON.stringify(authorization));
            }),
        ),
        {dispatch: false},
    );

    syncAuthorizationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(localStorageActions.syncAuthorizationAction),
            map(() => {
                const authorization = localStorage.getItem('authorization');

                logger.debug('syncAuthorizationEffect (authorization)', authorization);

                if (authorization) {
                    return authActions.setAuthorizationAction({
                        authorization: JSON.parse(authorization),
                    });
                }

                return authActions.signOutAction();
            }),
            catchError(() => of(authActions.signOutAction())),
        ),
    );

    initAuthorizationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(appActions.initAction),
            map(() => {
                const authorization = localStorage.getItem('authorization');

                logger.debug('initAuthorizationEffect (authorization)', authorization);

                if (authorization) {
                    return authActions.setAuthorizationAction({
                        authorization: JSON.parse(authorization),
                    });
                }

                return authActions.initCompleteAction();
            }),
            catchError(() => of(authActions.signOutAction())),
        ),
    );

    listenLocalStorageEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(appActions.initAction),
            switchMap(() => fromEvent(window, 'storage')),
            switchMap(() => [
                localStorageActions.syncDeviceAction(),
                localStorageActions.syncAuthorizationAction(),
            ]),
        ),
    );

    signOutEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signOutAction),
            tap(() => {
                localStorage.removeItem('authorization');
            }),
        ),
        {dispatch: false},
    );

}
