import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import {
    forkJoin,
    Observable,
    of,
} from 'rxjs';
import {
    concatMap,
    map,
    mapTo,
} from 'rxjs/operators';
import {
    ADMIN_ROLE_ID,
    ROLE_WIDGET_TYPES,
} from '../app.constants';
import {
    Capsule,
    CapsuleCreate,
    CapsuleUpdate,
} from '../schemas/capsule.schemas';
import { AuthApi } from './api/auth/auth.api';
import { CapsuleApi } from './api/capsule/capsule.api';


const logger = new Logger('CapsuleService');

@Injectable({
    providedIn: 'root',
})
export class CapsuleService {

    constructor(
        private authApi: AuthApi,
        private capsuleApi: CapsuleApi,
    ) { }

    createCapsule(data: CapsuleCreate): Observable<Capsule> {
        return this.capsuleApi.createCapsule(data).pipe(
            concatMap((capsule: Capsule) => {
                const tasks = [];

                tasks.push(
                    this.authApi.createUserRole({
                        userId: capsule.userId,
                        roleId: ADMIN_ROLE_ID,
                        capsuleId: capsule.id,
                        locationId: 0,
                    }),
                );

                for (const item of ROLE_WIDGET_TYPES) {
                    tasks.push(
                        this.capsuleApi.createRoleWidgetType({
                            roleId: item.roleId,
                            capsuleId: capsule.id,
                            widgetTypeId: item.widgetTypeId,
                        }),
                    );
                }

                return forkJoin(tasks).pipe(
                    mapTo(capsule),
                );
            }),
        );
    }

    updateCapsule(id: number, capsule: CapsuleUpdate): Observable<Capsule> {
        return this.capsuleApi.fetchCapsuleById(id).pipe(
            concatMap((originCapsule: Capsule) =>
                forkJoin([
                    this.capsuleApi.updateCapsuleById(id, capsule),
                    this.authApi.findUserRoles({
                        userIdIn: `${capsule.userId}`,
                        roleIdIn: `${ADMIN_ROLE_ID}`,
                        capsuleIdIn: `${id}`,
                        locationIdIn: '0',
                    }),
                ]).pipe(
                    concatMap(([updatedCapsule, userRoles]) => {
                        if (updatedCapsule.userId === originCapsule.userId) {
                            if (userRoles.length > 0) {
                                return of(updatedCapsule);
                            }

                            return this.authApi.createUserRole({
                                userId: updatedCapsule.userId,
                                roleId: ADMIN_ROLE_ID,
                                capsuleId: updatedCapsule.id,
                                locationId: 0,
                            }).pipe(
                                map(() => updatedCapsule),
                            );
                        }

                        const tasks = [
                            this.authApi.createUserRole({
                                userId: updatedCapsule.userId,
                                roleId: ADMIN_ROLE_ID,
                                capsuleId: updatedCapsule.id,
                                locationId: 0,
                            }),
                            this.authApi.deleteUserRoles({
                                userId: originCapsule.userId,
                                roleId: ADMIN_ROLE_ID,
                                capsuleId: originCapsule.id,
                                locationId: 0,
                            }),
                        ];

                        return forkJoin(tasks).pipe(
                            map(() => updatedCapsule),
                        );
                    }),
                ),
            ),
        );
    }

}
