import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicHostDirective } from './dynamic-host.directive';
import { DynamicOverlayComponent } from './dynamic-overlay/dynamic-overlay.component';
import { DynamicOverlayService } from './dynamic-overlay.service';


const components = [
    DynamicHostDirective,
    DynamicOverlayComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ...components,
    ],
    providers: [
        DynamicOverlayService,
    ],
})
export class DynamicOverlayModule {}
