import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    mapTo,
} from 'rxjs/operators';
import {
    Location,
    LocationCreate,
    LocationFindParams,
    LocationUpdate,
    LocationWidget,
    LocationWidgetFindParams,
    LocationWidgetUpdate,
} from '../schemas/location.schemas';
import { LocationApi } from '../services/api/capsule/location.api';
import { LocationService } from '../services/location.service';


const logger = new Logger('LocationFacade');

@Injectable({
    providedIn: 'root',
})
export class LocationFacade {

    constructor(
        private store$: Store,
        private locationApi: LocationApi,
        private locationService: LocationService,
    ) { }

    fetchLocations(params: LocationFindParams): Observable<Location[]> {
        return this.locationApi.findLocations(params);
    }

    fetchLocationById(id: number): Observable<Location> {
        return this.locationApi.fetchLocationById(id);
    }

    createLocation(location: LocationCreate): Observable<Location> {
        return this.locationApi.createLocation(location);
    }

    updateLocation(location: LocationUpdate): Observable<Location> {
        return this.locationApi.updateLocationById(location.id, location);
    }

    deleteLocation(location: Location): Observable<boolean> {
        return this.locationApi.deleteLocationById(location.id).pipe(
            mapTo(true),
            catchError(() => of(false)),
        );
    }

    fetchLocationWidgets(params: LocationWidgetFindParams): Observable<LocationWidget[]> {
        return this.locationApi.findLocationWidgets(params);
    }

    updateLocationWidget(locationWidget: LocationWidgetUpdate): Observable<LocationWidget> {
        return this.locationApi.updateLocationWidgetById(locationWidget.id, locationWidget);
    }

}
