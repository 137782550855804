import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TelegramBotComponent } from './telegram-bot/telegram-bot.component';


@NgModule({
    declarations: [
        TelegramBotComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        TelegramBotComponent,
    ],
})
export class TelegramUiModule {}
