import {
    Component,
    OnInit,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { WidgetFacade } from '../../../facades/widget.facade';
import { WidgetType } from '../../../schemas/widget.schemas';
import { WidgetService } from '../../../services/widget.service';
import { UniqueTitleValidator } from '../../../validators/widget-package.validator';
import { UniqueNicknameValidator } from '../../../validators/widget.validator';


const logger = new Logger('WidgetIframeFactoryComponent');

@Component({
    selector: 'app-widget-iframe-factory',
    templateUrl: './widget-iframe-factory.component.html',
    styleUrls: ['./widget-iframe-factory.component.scss'],
})
export class WidgetIframeFactoryComponent implements OnInit {

    form: FormGroup;
    cannotSubmit = false;
    formError?: schemas.ResponseError;
    widgetTypes: WidgetType[] = [];
    dropdownSettings: IDropdownSettings = {};

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly widgetFacade: WidgetFacade,
        private readonly widgetService: WidgetService,
        private readonly nicknameValidator: UniqueNicknameValidator,
        private readonly titleValidator: UniqueTitleValidator,
    ) {

        this.form = new FormGroup({

            nickname: new FormControl('', {
                validators: [
                    Validators.pattern('^[a-z]+([-]?[a-z0-9]+)*$'),
                    Validators.required,
                ],
                asyncValidators: [
                    this.nicknameValidator.validate.bind(this.nicknameValidator),
                ],
                updateOn: 'blur',
            }),

            title: new FormControl('', {
                validators: [
                    Validators.required,
                ],
                asyncValidators: [
                    this.titleValidator.validate.bind(this.titleValidator),
                ],
                updateOn: 'blur',
            }),

            icon: new FormControl(null),

            uri: new FormControl('', [
                Validators.required,
            ]),

            widgetTypes: new FormControl([]),
        });

        widgetFacade.fetchWidgetTypes().subscribe(
            widgetTypes => this.widgetTypes = widgetTypes,
        );
    }

    ngOnInit(): void {
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'title',
            selectAllText: 'Выбрать все',
            unSelectAllText: 'Отменить все',
            itemsShowLimit: 3,
        };
    }

    onFormSubmit(): void {
        this.cannotSubmit = true;
        this.widgetService.createIframeWidgetsPackage({
            ...this.form.value,
        }).subscribe({
            next: this.goBack.bind(this),
            error: error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        });
    }

    goBack(): void {
        this.router.navigate(['..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
