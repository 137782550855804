import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocationComponent } from './location/location.component';


@NgModule({
    declarations: [
        LocationComponent,
    ],
    exports: [
        LocationComponent,
    ],
    imports: [
        CommonModule,
    ],
})
export class LocationUiModule {}
