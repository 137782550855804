import {
    createReducer,
    on,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';
import {
    Authorization,
    CurrentUser,
} from '../../schemas/auth.schemas';
import * as authActions from '../actions/auth.actions';


export const DEFAULT_AUTH_FEATURE_NAME = 'auth';

export interface AuthReducerState {
    isInit: boolean;
    isLoading: boolean;
    authorization: Authorization | null;
    currentUser: CurrentUser | null;
    error: schemas.ResponseError | null;
    device: string | null;
}

const initialState: AuthReducerState = {
    isInit: false,
    isLoading: false,
    authorization: null,
    currentUser: null,
    error: null,
    device: null,
};

export const authReducer = createReducer(
    initialState,
    on(
        authActions.signInAction,
        authActions.signOutAction,
        state => ({
            ...state,
            isLoading: true,
        }),
    ),
    on(
        authActions.signInSuccessAction,
        authActions.setAuthorizationAction,
        (state, {authorization}) => ({
            ...state,
            isInit: true,
            isLoading: false,
            authorization,
        }),
    ),
    on(
        authActions.signInFailureAction,
        (state, {error}) => ({
            ...state,
            isInit: true,
            isLoading: false,
            error,
        }),
    ),
    on(
        authActions.signOutSuccessAction,
        authActions.signOutFailureAction,
        state => ({
            ...initialState,
            isInit: true,
            device: state.device,
        }),
    ),
    on(
        authActions.setDeviceAction,
        (state, {device}) => ({
            ...state,
            device,
        }),
    ),
    on(
        authActions.setCurrentUserAction,
        (state, {currentUser}) => ({
            ...state,
            currentUser,
        }),
    ),
    on(
        authActions.initCompleteAction,
        state => ({
            ...state,
            isInit: true,
        }),
    ),
);
