import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
} from '@angular/forms';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subscription } from 'rxjs';
import { WIDGET_TYPE_NAMES } from '../../../../app.constants';
import { WidgetPackageFacade } from '../../../../facades/widget-package.facade';
import { WidgetFacade } from '../../../../facades/widget.facade';
import {
    Widget,
    WidgetPackage,
} from '../../../../schemas/widget.schemas';


const logger = new Logger('WidgetPackageWidgetsLinkComponent');

@Component({
    selector: 'app-widget-package-widgets-link',
    templateUrl: './widget-package-widgets-link.component.html',
    styleUrls: ['./widget-package-widgets-link.component.scss'],
})
export class WidgetPackageWidgetsLinkComponent implements OnInit, OnDestroy {

    form: FormGroup;
    widgetPackage?: WidgetPackage;
    cannotSubmit = false;
    formError?: schemas.ResponseError;
    subscriptions: Subscription[] = [];
    widgets: Widget[] = [];
    dropdownSettings: IDropdownSettings = {};

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly widgetFacade: WidgetFacade,
        private readonly packageFacade: WidgetPackageFacade,
    ) {
        this.form = new FormGroup({
            widgets: new FormControl([]),
        });

        this.dropdownSettings = {
            enableCheckAll: false,
            singleSelection: false,
            idField: 'id',
            textField: 'title',
            itemsShowLimit: 3,
        };

        widgetFacade.fetchWidgets({
            offset: 0,
            limit: 0,
        }).subscribe(
            widgets => this.widgets = widgets.map(widget => ({
                ...widget,
                title: [widget.title, `(${WIDGET_TYPE_NAMES[widget.typeId]})`].join(' '),
            })),
        );
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.data.subscribe((data: {
                widgetPackage?: WidgetPackage;
            }) => {
                this.widgetPackage = data.widgetPackage;
            }),
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        if (!this.widgetPackage) {
            return;
        }
        const widgets = this.form.value.widgets.map((widget: Widget) => widget.id);
        this.cannotSubmit = true;
        this.packageFacade.updateWidgetPackage(
            this.widgetPackage.id,
            {
                widgets,
            },
        ).subscribe({
            next: this.goBack.bind(this),
            error: error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        });
    }

    goBack(): void {
        this.router.navigate(['..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
