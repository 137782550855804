import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    Capsule,
    CapsuleCreate,
    CapsuleFindParams,
    CapsuleUpdate,
    RoleWidgetType,
    RoleWidgetTypeCreate,
} from '../../../schemas/capsule.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('CapsuleApi');

@Injectable({
    providedIn: 'root',
})
export class CapsuleApi {

    constructor(
        private apiService: ApiService,
    ) { }

    findCapsules(params: CapsuleFindParams): Observable<Capsule[]> {
        return this.apiService.getJson<Capsule[]>(
            '/capsule',
            params,
        ).pipe(
            tap(capsules => logger.debug('findCapsules (capsules)', capsules)),
        );
    }

    fetchCapsuleById(id: number): Observable<Capsule> {
        return this.apiService.getJson<Capsule>(
            '/capsule/' + id,
        ).pipe(
            tap(capsule => logger.debug('fetchCapsuleById (capsule)', capsule)),
        );
    }

    createCapsule(data: CapsuleCreate): Observable<Capsule> {
        return this.apiService.postJson<Capsule>(
            '/capsule',
            data,
        ).pipe(
            tap(capsule => logger.debug('createCapsule (capsule)', capsule)),
        );
    }

    updateCapsuleById(id: number, data: CapsuleUpdate): Observable<Capsule> {
        return this.apiService.patchJson<Capsule>(
            '/capsule/' + id,
            data,
        ).pipe(
            tap(capsule => logger.debug('updateCapsuleById (capsule)', capsule)),
        );
    }

    deleteCapsuleById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/capsule/' + id,
        ).pipe(
            tap(() => logger.debug('deleteCapsuleById (id)', id)),
        );
    }

    createRoleWidgetType(data: RoleWidgetTypeCreate): Observable<RoleWidgetType> {
        return this.apiService.postJson<RoleWidgetType>(
            '/capsule/role-widget-type',
            data,
        ).pipe(
            tap(roleWidgetType => logger.debug('createRoleWidgetType (roleWidgetType)', roleWidgetType)),
        );
    }

}
