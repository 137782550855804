import { createAction } from '@ngrx/store';


export const initAction = createAction(
    '[App] Init',
);

export const NoopAction = createAction(
    '[App] Noop',
);
