import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AuthInterceptorProvider } from './interceptors/auth.interceptor';
import { DeviceHashInterceptorProvider } from './interceptors/device-hash.interceptor';
import { ErrorHandlerInterceptorProvider } from './interceptors/error-handler.interceptor';
import { LayoutModule } from './layout/layout.module';
import { AuthModule } from './modules/auth/auth.module';
import { CapsuleModule } from './modules/capsule/capsule.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { GeoModule } from './modules/geo/geo.module';
import { RecommendationModule } from './modules/recommendation/recommendation.module';
import { ServiceModule } from './modules/service/service.module';
import { TelegramModule } from './modules/telegram/telegram.module';
import { UploadMediaModule } from './modules/upload/upload-media.module';
import { UserModule } from './modules/user/user.module';
import { WidgetModule } from './modules/widget/widget.module';
import { AppRoutingModule } from './routing/routing.module';
import {
    effects,
    metaReducers,
    reducers,
} from './store';
import { AppStoreModule } from './store/store.module';


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot([], {
            paramsInheritanceStrategy: 'always',
        }),
        EffectsModule.forRoot(effects),
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        ToastrModule.forRoot({
            tapToDismiss: true,
            progressBar: true,
            progressAnimation: 'decreasing',
            positionClass: 'toast-top-center',
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: request => request as any,
            },
        }),
        NgxMaskModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        NgbModule,
        AppStoreModule,
        AppRoutingModule,
        AuthModule,
        CapsuleModule,
        DashboardModule,
        WidgetModule,
        UserModule,
        ServiceModule,
        LayoutModule,
        RecommendationModule,
        GeoModule,
        UploadMediaModule,
        TelegramModule,
    ],
    providers: [
        AppService,
        ErrorHandlerInterceptorProvider,
        DeviceHashInterceptorProvider,
        AuthInterceptorProvider,
        { provide: 'environment', useValue: environment }
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {}
