import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';
import {
    License,
    LicenseCreate,
    LicenseFindParams,
    Widget,
    WidgetCreate,
    WidgetFindParams,
    WidgetType,
    WidgetTypeFindParams,
    WidgetUpdate,
} from '../schemas/widget.schemas';
import { LicenseApi } from '../services/api/widget/license.api';
import { PackageApi } from '../services/api/widget/package.api';
import { WidgetApi } from '../services/api/widget/widget.api';
import { WidgetService } from '../services/widget.service';


const logger = new Logger('WidgetFacade');

@Injectable({
    providedIn: 'root',
})
export class WidgetFacade {

    constructor(
        private store$: Store,
        private licenseApi: LicenseApi,
        private packageApi: PackageApi,
        private widgetApi: WidgetApi,
        private widgetService: WidgetService,
    ) { }

    fetchLicenses(params: LicenseFindParams): Observable<License[]> {
        return this.licenseApi.findLicenses(params);
    }


    fetchWidgetPackageWidgets(widgetPackageId: number): Observable<Widget[]> {
        return this.widgetApi.fetchWidgetsByWidgetPackageId(widgetPackageId);
    }

    createLicense(data: LicenseCreate): Observable<License> {
        return this.widgetService.createLicense(data);
    }

    deleteLicense(license: License): Observable<boolean> {
        return this.licenseApi.deleteLicenseById(license.id).pipe(
            map(() => true),
            catchError(() => of(false)),
        );
    }

    fetchWidgetTypes(params: WidgetTypeFindParams = {}): Observable<WidgetType[]> {
        return this.widgetApi.findWidgetTypes(params);
    }

    fetchWidgets(params: WidgetFindParams): Observable<Widget[]> {
        return this.widgetApi.findWidgets(params);
    }

    deleteWidget(widget: Widget): Observable<boolean> {
        return this.widgetApi.deleteWidgetById(widget.id).pipe(
            map(() => true),
            catchError(() => of(false)),
        );
    }

    createWidget(data: WidgetCreate): Observable<Widget> {
        return this.widgetApi.createWidget(data);
    }

    updateWidget(id: number, data: WidgetUpdate): Observable<Widget> {
        return this.widgetApi.updateWidgetById(id, data);
    }

}
