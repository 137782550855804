import {
    Component,
    OnInit,
} from '@angular/core';


@Component({
    selector: 'app-geo-page',
    templateUrl: './geo-page.component.html',
    styleUrls: ['./geo-page.component.scss'],
})
export class GeoPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
