import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    Authorization,
    UserRole,
    UserRoleCreate,
    UserRoleDeleteParams,
    UserRoleFindParams,
} from '../../../schemas/auth.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('AuthApi');

@Injectable({
    providedIn: 'root',
})
export class AuthApi {

    constructor(
        private apiService: ApiService,
    ) { }

    refreshToken(refreshToken: string): Observable<Authorization> {
        return this.apiService.postJson<Authorization>(
            '/authorization/authorize/refresh',
            {refreshToken},
        ).pipe(
            tap(authorization => logger.debug('refresh (authorization)', authorization)),
        );
    }

    findUserRoles(params: UserRoleFindParams): Observable<UserRole[]> {
        return this.apiService.getJson<UserRole[]>(
            '/authorization/user-role',
            params,
        ).pipe(
            tap(userRoles => logger.debug('findUserRoles (userRoles)', userRoles)),
        );
    }

    createUserRole(data: UserRoleCreate): Observable<UserRole> {
        return this.apiService.postJson<UserRole>(
            '/authorization/user-role',
            data,
        ).pipe(
            tap(userRole => logger.debug('createUserRole (userRole)', userRole)),
        );
    }

    deleteUserRoles(params: UserRoleDeleteParams): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/authorization/user-role',
            params,
        ).pipe(
            tap(() => logger.debug('deleteUserRoles (params)', params)),
        );
    }

}
