import { Routes } from '@angular/router';
import { RecommendationAddComponent } from '../modules/recommendation/recommendation-add/recommendation-add.component';
import { RecommendationEditComponent } from '../modules/recommendation/recommendation-edit/recommendation-edit.component';
import { RecommendationListComponent } from '../modules/recommendation/recommendation-list/recommendation-list.component';
import { RecommendationResolver } from '../resolvers/recommendation.resolver';


export const recommendationRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: RecommendationListComponent,
    },
    {
        path: 'add',
        component: RecommendationAddComponent,
        data: {
            breadcrumb: 'Добавление новой рекомендации',
        },
    },
    {
        path: ':recommendationId',
        component: RecommendationEditComponent,
        resolve: {
            recommendation: RecommendationResolver,
        },
        data: {
            breadcrumb: (data: any) => `Редактирование (rec-ID:${data.recommendation.id})`,
        },
    },
];
