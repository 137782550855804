import {
    from,
    Observable,
    of,
} from 'rxjs';


export function toObservable<T>(
    data: Observable<T> | Promise<T> | T,
): Observable<T> {
    return (data instanceof Observable || data instanceof Promise)
        ? from(data)
        : of(data);
}
