import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { License } from '../schemas/widget.schemas';
import { LicenseApi } from '../services/api/widget/license.api';


const logger = new Logger('LicenseResolver');

@Injectable({
    providedIn: 'root',
})
export class LicenseResolver implements Resolve<License> {

    constructor(
        private licenseApi: LicenseApi,
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<License> {
        const licenseId = Number(route.paramMap.get('licenseId'));

        logger.debug('resolve (licenseId)', licenseId);

        return this.licenseApi.getLicenseById(licenseId);
    }

}
