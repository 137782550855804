import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { LocationWidgetModule } from '../capsule/location-widget/location-widget.module';
import { LicenseAddComponent } from './license-add/license-add.component';
import { LicenseDetailComponent } from './license-detail/license-detail.component';
import { LicenseFormComponent } from './license-form/license-form.component';
import { LicenseListComponent } from './license-list/license-list.component';
import { WidgetAddComponent } from './widget-add/widget-add.component';
import { WidgetEditComponent } from './widget-edit/widget-edit.component';
import { WidgetFormComponent } from './widget-form/widget-form.component';
import { WidgetIframeFactoryComponent } from './widget-iframe-factory/widget-iframe-factory.component';
import { WidgetListComponent } from './widget-list/widget-list.component';
import { WidgetPackageModule } from './widget-package/widget-package.module';
import { WidgetPageComponent } from './widget-page/widget-page.component';


const components = [
    LicenseAddComponent,
    LicenseDetailComponent,
    LicenseFormComponent,
    LicenseListComponent,
    WidgetAddComponent,
    WidgetEditComponent,
    WidgetFormComponent,
    WidgetIframeFactoryComponent,
    WidgetListComponent,
    WidgetPageComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        LocationWidgetModule,
        NgMultiSelectDropDownModule,
        WidgetPackageModule,
    ],
    exports: [
        ...components,
    ],
})
export class WidgetModule {}
