<div>

  <div class="content">
    <div *ngFor="let widgetPackage of widgetPackages">
      <div class="widget-package">
        <div class="widget-package__info">
          <div class="widget-package__number">
            {{ widgetPackage.id }}
          </div>
          <div (click)="onWidgetPackageClick(widgetPackage)" class="widget-package__title">
            {{ widgetPackage.title }}
          </div>
        </div>
        <span class="widget-package__delete" (click)="delete(widgetPackage)">удалить</span>
      </div>
    </div>
  </div>

</div>
