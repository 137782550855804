<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

    <div class="form-group">
        <div class="title-form-group">Название капсулы</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="title"
                   placeholder="Введите название..."
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['title'].invalid && form.controls['title'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Никнейм капсулы (/capsule-name)</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="nickname"
                   placeholder="capsule-name">
            <span class="invalid-feedback"
                  *ngIf="form.controls['nickname'].invalid && form.controls['nickname'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Владелец капсулы (ID юзера)</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="userId"
                   placeholder="1"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['userId'].invalid && form.controls['userId'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Yandex Counter ID</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="yandexCounterId"
                   placeholder="Счетчик яндекс метрики, например, 12345678"
                   required>
            <span class="invalid-feedback"
                  *ngIf="form.controls['yandexCounterId'].invalid && form.controls['yandexCounterId'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
        <button class="btn btn-primary"
                [disabled]="cannotSubmit || form.invalid || !!formError">
            Сохранить
        </button>
        <button class="btn btn-secondary" (click)="onFormCancel()">Отмена</button>
        <div *ngIf="formError">{{ formError.message }}</div>
    </div>

</form>
