import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { Capsule } from '../../../schemas/capsule.schemas';


const logger = new Logger('CapsuleFormComponent');

@Component({
    selector: 'app-capsule-form',
    templateUrl: './capsule-form.component.html',
    styleUrls: ['./capsule-form.component.scss'],
})
export class CapsuleFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    form: FormGroup;

    @Input() capsule?: Capsule | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor() {

        this.form = new FormGroup({

            title: new FormControl('', [
                Validators.required,
            ]),

            nickname: new FormControl('', [
                Validators.pattern('^[a-z]+([-]?[a-z0-9]+)*$'),
                Validators.required,
            ]),

            userId: new FormControl('', [
                Validators.required,
                Validators.pattern('[0-9]+'),
            ]),

            yandexCounterId: new FormControl('', [
                Validators.required,
                Validators.pattern('[0-9]+'),
            ]),

        });

    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.capsule?.currentValue) {
            this.form.patchValue({
                ...changes.capsule.currentValue,
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const userId = this.form.value.userId;
        const result = {
            ...this.capsule,
            ...this.form.value,
        };

        if (userId) {
            result.userId = parseInt(userId, 10);
        }

        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }

}
