<div>
  <div class="content">
    <div *ngFor="let widget of widgets">
      <div class="widget">
        <div class="widget__info">
<!--          <div class="widget__id">-->
<!--            {{ widget.id }}-->
<!--          </div>-->
          <div>
            <div (click)="onWidgetClick(widget)" class="widget__title">
              {{ widget.title }} ({{ getWidgetTypeName(widget.typeId) }})
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
