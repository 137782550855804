import { Routes } from '@angular/router';
import { GeoPageComponent } from '../modules/geo/geo-page/geo-page.component';
import { GeoPrimitiveAddComponent } from '../modules/geo/geo-primitive-add/geo-primitive-add.component';
import { GeoPrimitiveEditComponent } from '../modules/geo/geo-primitive-edit/geo-primitive-edit.component';
import { GeoPrimitiveListComponent } from '../modules/geo/geo-primitive-list/geo-primitive-list.component';
import { GeoRouteAddComponent } from '../modules/geo/geo-route-add/geo-route-add.component';
import { GeoRouteEditComponent } from '../modules/geo/geo-route-edit/geo-route-edit.component';
import { GeoRouteListComponent } from '../modules/geo/geo-route-list/geo-route-list.component';
import { GeoPrimitiveResolver } from '../resolvers/geo-primitive.resolver';
import { GeoRouteResolver } from '../resolvers/geo-route.resolver';


export const geoRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: GeoPageComponent,
    },
    {
        path: 'route',
        children: [
            {
                path: '',
                data: {
                    breadcrumb: 'Маршруты',
                },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        component: GeoRouteListComponent,
                    },
                    {
                        path: 'add',
                        component: GeoRouteAddComponent,
                        data: {
                            breadcrumb: 'Добавление нового маршрута',
                        },
                    },
                    {
                        path: ':routeId',
                        children: [
                            {
                                path: '',
                                component: GeoRouteEditComponent,
                                runGuardsAndResolvers: 'always',
                                resolve: {
                                    geoRoute: GeoRouteResolver,
                                },
                                data: {
                                    breadcrumb: (data: any) => `Редактирование маршрута (ID:${data.geoRoute.id})`,
                                },
                            },
                        ],

                    },
                ],
            },
        ],
    },
    {
        path: 'primitive',
        children: [
            {
                path: '',
                data: {
                    breadcrumb: 'Примитивы',
                },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        component: GeoPrimitiveListComponent,
                    },
                    {
                        path: 'add',
                        component: GeoPrimitiveAddComponent,
                        data: {
                            breadcrumb: 'Добавление примитива',
                        },
                    },
                    {
                        path: ':geoPrimitiveId',
                        children: [
                            {
                                path: '',
                                component: GeoPrimitiveEditComponent,
                                runGuardsAndResolvers: 'always',
                                resolve: {
                                    geoPrimitive: GeoPrimitiveResolver,
                                },
                                data: {
                                    breadcrumb: (data: any) => `Редактирование примитива (ID:${data.geoPrimitive.id})`,
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
