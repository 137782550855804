import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { Capsule } from '../../../../schemas/capsule.schemas';
import { Location } from '../../../../schemas/location.schemas';
import {
    License,
    LicenseFindParams,
} from '../../../../schemas/widget.schemas';


const logger = new Logger('LocationDetailComponent');

@Component({
    selector: 'app-location-detail',
    templateUrl: './location-detail.component.html',
    styleUrls: ['./location-detail.component.scss'],
})
export class LocationDetailComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    licenseFindParams?: LicenseFindParams;
    location?: Location;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.data.subscribe((data: {
                capsule?: Capsule;
                location?: Location;
            }) => {
                this.location = data.location;
                this.licenseFindParams = {
                    locationId: this.location?.id,
                };
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onLicenseClick(license: License): void {
        this.router.navigate(['licenses', license.id], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
