<div>

    <form [formGroup]="uploadForm">
        <div class="form-group">
            <div class="title-form-group">Загрузить медиа</div>
            <input type="file" formControlName="file" (change)="onMediaChange($event)">
        </div>
    </form>

    <div class="form-group">
        <div class="title-form-group">Фотографии</div>
        <app-upload-cropped-images
                [canEmitChanges]="canEmitChanges"
                [maintainAspectRatio]="false"
                (uploadImages)="changeUploadImages($event)">
        </app-upload-cropped-images>
        <button class="btn btn-primary" (click)="saveImages()">Сохранить фото</button>
    </div>

    <div *ngIf="result">
        <p>Результат загрузки:</p>
        <p class="result">{{ result }}</p>
    </div>
</div>
