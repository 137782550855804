import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WidgetModule } from '../../widget/widget.module';
import { LocationAddComponent } from './location-add/location-add.component';
import { LocationDetailComponent } from './location-detail/location-detail.component';
import { LocationEditComponent } from './location-edit/location-edit.component';
import { LocationFormComponent } from './location-form/location-form.component';
import { LocationListComponent } from './location-list/location-list.component';


const components = [
    LocationAddComponent,
    LocationDetailComponent,
    LocationEditComponent,
    LocationFormComponent,
    LocationListComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        WidgetModule,
    ],
    exports: [
        ...components,
    ],
})
export class LocationModule {}
