<div class="telegram-list">
    <ui-simple-infinite-scroll (scrolled)="fetchContent()">
        <div slot="list">
            <div *ngFor="let bot of bots">
                <ui-telegram-bot
                        [bot]="bot"
                        (clickBot)="clickBot($event)"
                        (deleteBot)="deleteBot($event)">
                </ui-telegram-bot>
            </div>
        </div>
    </ui-simple-infinite-scroll>

    <div class="button-add">
        <i (click)="goToAddBot()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 9H20V11H11V20H9V11H0V9H9V0H11V9Z"
                      fill="inherit"/>
            </svg>
        </i>
    </div>
</div>
