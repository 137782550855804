import { Routes } from '@angular/router';
import { SignInComponent } from '../modules/auth/sign-in/sign-in.component';


export const authRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: SignInComponent,
    },
];
