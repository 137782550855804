import {
    Component,
    Input,
} from '@angular/core';
import { schemas } from '@scatch/ngx-app-lib';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-geo-primitive',
    templateUrl: './geo-primitive.component.html',
    styleUrls: ['./geo-primitive.component.scss'],
})
export class GeoPrimitiveComponent {

    @Input() geo?: schemas.GeoPrimitive;

}
