import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UploadModule } from '@scatch/ngx-app-lib';
import { UploadMediaComponent } from './upload-media/upload-media.component';


@NgModule({
    declarations: [
        UploadMediaComponent,
    ],
    exports: [
        UploadMediaComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UploadModule
    ],
})
export class UploadMediaModule {}
