import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    map,
    tap,
} from 'rxjs/operators';

import {
    WorkTable,
    WorkTableFindParams,
    WorkTableWidget,
    WorkTableWidgetsCount,
    WorkTableWidgetsFindParams,
} from '../../schemas';
import * as utils from '../../utils';
import { Logger } from '../logger.service';


const logger = new Logger('WorkTableApi');

@Injectable({
    providedIn: 'root',
})
export class WorkTableApi {

    constructor(
        private http: HttpClient,
    ) { }

    findWorkTables(params: WorkTableFindParams): Observable<WorkTable[]> {
        return this.http.get<WorkTable[]>(
            'assets/json/work-tables.json',
            {
                params: utils.convertKeysToSnake(params),
            },
        ).pipe(
            map(utils.convertKeysToCamel),
            tap(workTables => logger.debug('findWorkTables (workTables)', workTables)),
            map(workTables => workTables.filter(
                (workTable: WorkTable) => params.ids?.includes(workTable.id)
                    || (params.title && workTable.title.includes(params.title)),
            )),
        );
    }

    fetchWorkTable(id: number): Observable<WorkTable> {
        return this.http.get<WorkTable>(
            'assets/json/work-tables.json',
        ).pipe(
            map(utils.convertKeysToCamel),
            tap(workTables => logger.debug('fetchWorkTable (workTables)', workTables)),
            map(workTables => {
                for (const workTable of workTables) {
                    if (workTable.id === id) {
                        return workTable;
                    }
                }
                return null;
            }),
        );
    }

    findWorkTableWidgets(params: WorkTableWidgetsFindParams): Observable<WorkTableWidget[]> {
        return this.http.get<WorkTableWidget[]>(
            'assets/json/work-tables/widgets.json',
            {
                params: utils.convertKeysToSnake(params),
            },
        ).pipe(
            map(utils.convertKeysToCamel),
            tap(workTableWidgets => logger.debug('findWorkTableWidgets (workTableWidgets)', workTableWidgets)),
            map(workTableWidgets => workTableWidgets.filter(
                (workTableWidget: WorkTableWidget) =>
                    params.ids?.includes(workTableWidget.id)
                    || (params.workTableId && params.workTableId === workTableWidget.workTableId)
                    || (params.workTableIds && params.workTableIds.includes(workTableWidget.workTableId))
                    || (params.widgetId && params.widgetId === workTableWidget.widgetId),
            )),
        );
    }

    fetchWorkTablesWidgetsCount(workTableIds: number[]): Observable<WorkTableWidgetsCount[]> {
        return this.http.get<WorkTableWidget[]>(
            'assets/json/work-tables/widgets.json',
        ).pipe(
            map(utils.convertKeysToCamel),
            tap(workTableWidgets => logger.debug('fetchWorkTablesWidgetsCount (workTableWidgets)', workTableWidgets)),
            map(workTableWidgets => workTableWidgets.filter(
                (workTableWidget: WorkTableWidget) =>
                    workTableIds.includes(workTableWidget.workTableId),
            )),
            map(workTableWidgets => {
                return workTableIds.map(
                    workTableId => ({
                        workTableId,
                        total: workTableWidgets.reduce(
                            (init: number, workTableWidget: WorkTableWidget) =>
                                workTableWidget.workTableId === workTableId ? init + 1 : init, 0),
                    }),
                );

            }),
        );
    }

}
