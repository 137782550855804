import {
    AfterViewInit,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { AuthFacade } from '../../facades/auth.facade';
import { LayoutService } from '../layout.service';


@Component({
    selector: 'app-layout-iterra-sidenav',
    templateUrl: './layout-iterra-sidenav.component.html',
    styles: [':host { display: block; }'],
    styleUrls: ['./layout-iterra-sidenav.component.scss'],
})
export class LayoutIterraSidenavComponent implements AfterViewInit {

    @Input() orientation = 'vertical';
    @HostBinding('class.layout-sidenav') hostClassVertical = false;
    @HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false;
    @HostBinding('class.flex-grow-0') hostClassFlex = false;

    displayCloseFullScreen;
    activeRouteUrl: string;

    constructor(
        private router: Router,
        private appService: AppService,
        private layoutService: LayoutService,
        private authFacade: AuthFacade,
    ) {
        this.activeRouteUrl = this.router.url;
        this.hostClassVertical = this.orientation !== 'horizontal';
        this.hostClassHorizontal = !this.hostClassVertical;
        this.hostClassFlex = this.hostClassHorizontal;
        this.displayCloseFullScreen = this.layoutService.getFullScreen();
    }

    ngAfterViewInit(): void {
        // Safari bugfix
        this.layoutService._redrawLayoutSidenav();
    }

    signOut(): void {
        this.authFacade.signOut();
    }

    getClasses(): string {
        let bg = this.appService.layoutSidenavBg;

        if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
            bg = bg
                .replace(' sidenav-dark', '')
                .replace(' sidenav-light', '')
                .replace('-darker', '')
                .replace('-dark', '');
        }

        return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
    }

    isActive(url: string): boolean {
        return this.router.isActive(url, true);
    }

    isMenuActive(url: string): boolean {
        return this.router.isActive(url, false);
    }

    isMenuOpen(url: string): boolean {
        return this.router.isActive(url, false) && this.orientation !== 'horizontal';
    }

    toggleSidenav(): void {
        this.layoutService.toggleCollapsed();
    }

    closeFullScreen(): void {

        const docWithBrowsersExitFunctions = document as Document & {
            mozCancelFullScreen(): Promise<void>;
            webkitExitFullscreen(): Promise<void>;
            msExitFullscreen(): Promise<void>;
        };
        if (docWithBrowsersExitFunctions.exitFullscreen) {
            docWithBrowsersExitFunctions?.exitFullscreen();
        } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
            docWithBrowsersExitFunctions?.mozCancelFullScreen();
        } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            docWithBrowsersExitFunctions?.webkitExitFullscreen();
        } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
            docWithBrowsersExitFunctions?.msExitFullscreen();
        }

        this.layoutService.setFullScreen(false);

    }
}

