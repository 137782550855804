import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    Widget,
    WidgetCreate,
    WidgetFindParams,
    WidgetType,
    WidgetTypeFindParams,
    WidgetUpdate,
} from '../../../schemas/widget.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('WidgetApi');

@Injectable({
    providedIn: 'root',
})
export class WidgetApi {

    constructor(
        private apiService: ApiService,
    ) { }

    fetchWidgetsByWidgetPackageId(id: number): Observable<Widget[]> {
        return this.apiService.getJson<Widget[]>(
            '/widget/widget-package/' + id + '/widget',
        ).pipe(
            tap(widgets => logger.debug('fetchWidgetsByWidgetPackageId (widgets)', widgets)),
        );
    }

    findWidgetTypes(params: WidgetTypeFindParams = {}): Observable<WidgetType[]> {
        return this.apiService.getJson<WidgetType[]>(
            '/widget/widget-type',
            params,
        ).pipe(
            tap(widgetTypes => logger.debug('findWidgetTypes (widgetTypes)', widgetTypes)),
        );
    }

    findWidgets(params: WidgetFindParams = {}): Observable<Widget[]> {
        return this.apiService.getJson<Widget[]>(
            '/widget',
            params,
        ).pipe(
            tap(widgets => logger.debug('findWidgets (widgets)', widgets)),
        );
    }

    fetchWidgetById(id: number): Observable<Widget> {
        return this.apiService.getJson<Widget>(
            '/widget/' + id,
        ).pipe(
            tap(widget => logger.debug('fetchWidgetById (widget)', widget)),
        );
    }

    createWidget(data: WidgetCreate): Observable<Widget> {
        return this.apiService.postJson<Widget>(
            '/widget',
            data,
        ).pipe(
            tap(widget => logger.debug('createWidget (widget)', widget)),
        );
    }

    updateWidgetById(id: number, data: WidgetUpdate): Observable<Widget> {
        return this.apiService.patchJson<Widget>(
            '/widget/' + id,
            data,
        ).pipe(
            tap(widget => logger.debug('updateWidgetById (widget)', widget)),
        );
    }

    deleteWidgetById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/widget/' + id,
        ).pipe(
            tap(() => logger.debug('deleteWidgetById (id)', id)),
        );
    }

}
