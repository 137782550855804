import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { WidgetPackageFacade } from '../../../../facades/widget-package.facade';
import {
    WidgetPackage,
    WidgetPackageFindParams,
} from '../../../../schemas/widget.schemas';


const logger = new Logger('WidgetPackageListComponent');

@Component({
    selector: 'app-widget-package-list',
    templateUrl: './widget-package-list.component.html',
    styleUrls: ['./widget-package-list.component.scss'],
})
export class WidgetPackageListComponent implements OnInit, OnChanges {

    widgetPackages: WidgetPackage[] = [];

    @Input() params?: WidgetPackageFindParams | null;

    @Output() widgetPackageClick = new EventEmitter();

    constructor(
        private packageFacade: WidgetPackageFacade,
    ) { }

    ngOnInit(): void {
        if (!this.params) {
            this.loadNextWidgetPackages();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params?.currentValue) {
            this.params = changes.params?.currentValue;
            this.widgetPackages = [];
            this.loadNextWidgetPackages();
        }
    }

    loadNextWidgetPackages(): void {
        this.packageFacade.fetchWidgetPackages({
            ...this.params,
            offset: this.widgetPackages.length,
            limit: this.params?.limit || 0,
        }).subscribe(widgetPackages => {
            this.widgetPackages.push(...widgetPackages);
        });
    }

    onWidgetPackageClick(widgetPackage: WidgetPackage): void {
        this.widgetPackageClick.emit(widgetPackage);
    }

    delete(widgetPackage: WidgetPackage): void {
        if (confirm('Удалить пакет ' + widgetPackage.title + '?')) {
            this.packageFacade.deleteWidgetPackage(widgetPackage).subscribe(result => {
                if (result) {
                    this.widgetPackages = this.widgetPackages.filter(
                        item => item.id !== widgetPackage.id,
                    );
                }
            });
        }
    }

}
