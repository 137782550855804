import { HttpErrorResponse } from '@angular/common/http';
import { ResponseError } from '../schemas';


export function formatErrors(response: HttpErrorResponse): ResponseError {
    return {
        message: response.error?.message || response.message || 'Something went wrong!',
    };
}

export const requestAnimFrame = ((): (callback: () => void) => void => {
    return window.requestAnimationFrame ||
        // @ts-ignore
        window.webkitRequestAnimationFrame ||
        // @ts-ignore
        window.mozRequestAnimationFrame ||
        (callback => {
            window.setTimeout(callback, 1000 / 60);
        });
})();

/**
 * @param input string
 * @param format string
 *
 * Custom formats can be passed like:
 * parseDate('01-31-2010', 'mm-dd-yyyy');
 */
export function parseDate(
    input: string,
    format: string = 'dd.mm.yyyy',
): Date | undefined {
    const parts = input.match(/(\d+)/g);
    const fmt: { [key: string]: number; } = {};
    let i = 0;

    if (!parts?.length) {
        return;
    }

    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, part => {
        fmt[part] = i++;
        return part;
    });

    return new Date(
        Number(parts[fmt.yyyy]),
        Number(parts[fmt.mm]) - 1,
        Number(parts[fmt.dd]),
    );
}

export function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}
