import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { RecommendationApi } from '../services/api/recommendation/recommendation.api';


const logger = new Logger('LocationResolver');

@Injectable({
    providedIn: 'root',
})
export class RecommendationResolver implements Resolve<schemas.Recommendation | null> {

    constructor(
        private recommendationApi: RecommendationApi,
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<schemas.Recommendation | null> {
        const recommendationId = Number(route.paramMap.get('recommendationId'));
        logger.debug('resolve (recommendationId)', recommendationId);

        return this.recommendationApi.fetchRecommendationById(recommendationId);
    }

}
