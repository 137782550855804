import {
    Component,
    OnDestroy,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { TelegramFacade } from '../../../facades/telegram.facade';
import { TelegramBotCreate } from '../../../schemas/telegram.schemas';


const logger = new Logger('TelegramBotAddComponent');

@Component({
    selector: 'app-telegram-bot-add',
    templateUrl: './telegram-bot-add.component.html',
    styleUrls: ['./telegram-bot-add.component.scss'],
})
export class TelegramBotAddComponent implements OnDestroy {
    private subscriptions: Subscription[] = [];

    public cannotSubmit = false;
    public formError?: schemas.ResponseError;

    private readonly capsuleId?: number;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private telegramFacade: TelegramFacade,
    ) {
        this.capsuleId = Number(this.activatedRoute.snapshot.paramMap.get('capsuleId'));
    }

    onFormSubmit(formData: TelegramBotCreate): void {
        logger.debug('onFormSubmit', formData);
        if (!this.capsuleId) {
            return;
        }

        const data: TelegramBotCreate = {
            ...formData,
            capsuleId: this.capsuleId,
        };

        this.cannotSubmit = true;
        this.subscriptions.push(
            this.telegramFacade.createTelegramBot(data).subscribe({
                    next: () => this.goBack(),
                    error: (error: schemas.ResponseError | undefined) => {
                        this.formError = error;
                        this.cannotSubmit = false;
                    },
                },
            ),
        );
    }

    goBack(): void {
        this.router.navigate(['..'], { relativeTo: this.activatedRoute })
            .catch(reason => logger.error('Navigate failed', reason));
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
        logger.debug('destroy');
    }
}
