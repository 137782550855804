import { Injectable } from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from '../api.service';


const logger = new Logger('GeoPrimitiveApi');

@Injectable({
    providedIn: 'root',
})
export class GeoPrimitiveApi {

    constructor(
        private apiService: ApiService,
    ) {
    }

    fetchGeoPrimitives(params: schemas.GeoRouteFindParams): Observable<schemas.GeoPrimitive[] | null> {
        return this.apiService.getJson<schemas.GeoPrimitive[] | null>(
            '/geo',
            params,
        ).pipe(
            tap(geo => logger.debug('fetchGeoPrimitives (geo)', geo)),
        );
    }

    fetchGeoPrimitiveById(id: number): Observable<schemas.GeoPrimitive | null> {
        return this.apiService.getJson<schemas.GeoPrimitive | null>(
            '/geo/' + id,
        ).pipe(
            tap(geo => logger.debug('fetchGeoPrimitiveById (geo)', geo)),
        );
    }

    createGeoPrimitive(data: schemas.GeoPrimitiveCreate): Observable<schemas.GeoPrimitive | null> {
        return this.apiService.postJson<schemas.GeoPrimitive | null>(
            '/geo',
            data,
        ).pipe(
            tap(geo => logger.debug('createGeoPrimitive (geo)', geo)),
        );
    }

    updateGeoPrimitiveById(id: number, data: schemas.GeoPrimitiveUpdate): Observable<schemas.GeoPrimitive> {
        return this.apiService.patchJson<schemas.GeoPrimitive>(
            '/geo/' + id,
            data,
        ).pipe(
            tap(geo => logger.debug('updateGeoPrimitiveById (geo)', geo)),
        );
    }

    deleteGeoPrimitiveById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/geo/' + id,
        ).pipe(
            tap(() => logger.debug('deleteGeoPrimitiveById (id)', id)),
        );
    }
}
