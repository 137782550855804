import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { TelegramBot } from '../../../schemas/telegram.schemas';


const logger = new Logger('TelegramBotFormComponent');

@Component({
    selector: 'app-telegram-bot-form',
    templateUrl: './telegram-bot-form.component.html',
    styleUrls: ['./telegram-bot-form.component.scss'],
})
export class TelegramBotFormComponent implements OnInit, OnChanges, OnDestroy {
    private subscriptions: Subscription[] = [];
    public form: FormGroup;

    @Input() bot?: TelegramBot | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor() {
        this.form = new FormGroup({
            botName: new FormControl('', [
                Validators.required,
            ]),
            botToken: new FormControl('', [
                Validators.required,
            ]),
            puzzleToken: new FormControl('', [
                Validators.required,
            ]),
            canStart: new FormControl('', [
                Validators.required,
            ]),
            linkToLocation: new FormControl('', [
                Validators.required,
            ]),
            messageSync: new FormControl('', [
                Validators.required,
            ]),
            post: new FormControl('', [
                Validators.required,
            ]),
        });
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.bot?.currentValue) {
            console.log('changes.bot?.currentValue', changes.bot?.currentValue);
            const botData = changes.bot?.currentValue;
            this.form.patchValue({
                ...botData,
                linkToLocation: botData?.manifest?.features?.linkToLocation ?? false,
                messageSync: botData?.manifest?.features?.messageSync ?? false,
                post: botData?.manifest?.features?.post ?? false,
            });
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const botData = this.form.value;
        const manifest = this.bot?.manifest || {};

        manifest.features = {
            ...manifest?.features,
            linkToLocation: botData.linkToLocation ?? false,
            messageSync: botData.messageSync ?? false,
            post: botData.post ?? false,
        };

        const result = {
            ...this.bot,
            ...this.form.value,
            manifest,
        };
        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }
}
