<div>
    <button class="btn btn-secondary" (click)="loadNextCapsules()" *ngIf="capsules.length">
        Загрузить еще
    </button>

    <div class="content">
        <div *ngFor="let capsule of capsules">
            <div class="capsule">
                <div class="capsule__info">
                    <div class="capsule__number">
                        {{ capsule.id }}
                    </div>
                    <div (click)="onCapsuleClick(capsule)" class="capsule__title">
                        {{ capsule?.title }}
                    </div>
                </div>
                <span class="capsule__delete" (click)="delete(capsule)">удалить</span>
            </div>
        </div>
    </div>

</div>
