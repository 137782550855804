import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CapsuleAddComponent } from './capsule-add/capsule-add.component';
import { CapsuleDetailComponent } from './capsule-detail/capsule-detail.component';
import { CapsuleEditComponent } from './capsule-edit/capsule-edit.component';
import { CapsuleFormComponent } from './capsule-form/capsule-form.component';
import { CapsuleListComponent } from './capsule-list/capsule-list.component';
import { CapsulePageComponent } from './capsule-page/capsule-page.component';
import { LocationWidgetModule } from './location-widget/location-widget.module';
import { LocationModule } from './location/location.module';


const components = [
    CapsuleAddComponent,
    CapsuleDetailComponent,
    CapsuleEditComponent,
    CapsuleFormComponent,
    CapsuleListComponent,
    CapsulePageComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        LocationWidgetModule,
        LocationModule,
    ],
    exports: [
        ...components,
    ],
})
export class CapsuleModule {}
