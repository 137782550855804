export const GUEST_ROLE_ID = 1;
export const USER_ROLE_ID = 10;
export const ADMIN_ROLE_ID = 1000;

export const GUEST_WIDGET_TYPE_ID = 1;
export const USER_WIDGET_TYPE_ID = 10;
export const ADMIN_WIDGET_TYPE_ID = 1000;

export const WIDGET_TYPE_NAMES: { [key: number]: string } = {
    [GUEST_WIDGET_TYPE_ID]: 'Гость',
    [USER_WIDGET_TYPE_ID]: 'Юзер',
    [ADMIN_WIDGET_TYPE_ID]: 'Админ',
};

export const WIDGET_TYPE_SUFFIXES: { [key: number]: string } = {
    [GUEST_WIDGET_TYPE_ID]: 'guest',
    [USER_WIDGET_TYPE_ID]: '',
    [ADMIN_WIDGET_TYPE_ID]: 'admin',
};

export const ROLE_WIDGET_TYPES: {
    roleId: number;
    widgetTypeId: number
}[] = [
    {
        roleId: GUEST_ROLE_ID,
        widgetTypeId: GUEST_WIDGET_TYPE_ID,
    },
    {
        roleId: USER_ROLE_ID,
        widgetTypeId: USER_WIDGET_TYPE_ID,
    },
    {
        roleId: ADMIN_ROLE_ID,
        widgetTypeId: GUEST_WIDGET_TYPE_ID,
    },
    {
        roleId: ADMIN_ROLE_ID,
        widgetTypeId: USER_WIDGET_TYPE_ID,
    },
    {
        roleId: ADMIN_ROLE_ID,
        widgetTypeId: ADMIN_WIDGET_TYPE_ID,
    },
];

export const defaultLimit = 100;
