import { Component } from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-simple-loading',
    templateUrl: './simple-loading.component.html',
    styleUrls: ['./simple-loading.component.scss'],
})
export class SimpleLoadingComponent {

}
