import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    TelegramBot,
    TelegramBotCreate,
    TelegramBotEdit,
    TelegramBotFindParams,
} from '../../../schemas/telegram.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('TelegramApi');

@Injectable({
    providedIn: 'root',
})
export class TelegramApi {

    constructor(
        private apiService: ApiService,
    ) {
    }

    createTelegramBot(data: TelegramBotCreate): Observable<TelegramBot | null> {
        return this.apiService.postJson<TelegramBot>(
            '/telegram/bots',
            data,
        ).pipe(
            tap((telegramBot) => logger.debug('createTelegramBot (telegramBot)', telegramBot)),
        );
    }

    fetchTelegramBots(params: TelegramBotFindParams): Observable<TelegramBot[] | null> {
        return this.apiService.getJson<TelegramBot[]>(
            '/telegram/bots',
            params,
        ).pipe(
            tap(bots => logger.debug('fetchTelegramBots (bots)', bots)),
        );
    }

    deleteTelegramBotById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/telegram/bots/' + id,
        ).pipe(
            tap(() => logger.debug('deleteTelegramBotById (id)', id)),
        );
    }

    fetchTelegramBotById(id: number): Observable<TelegramBot | null> {
        return this.apiService.getJson<TelegramBot>(
            '/telegram/bots/' + id,
        ).pipe(
            tap(bot => logger.debug('fetchTelegramBotById (botId)', bot)),
        );
    }

    updateTelegramBotById(id: number, data: TelegramBotEdit): Observable<TelegramBot | null> {
        return this.apiService.patchJson<TelegramBot>(
            '/telegram/bots/' + id,
            data,
        ).pipe(
            tap(bot => logger.debug('updateTelegramBotById (bot)', bot)),
        );
    }
}
