<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

  <div class="form-group">
    <div class="title-form-group">Выберите виджет из списка</div>
    <div class="input-group">
      <select class="form-control" formControlName="packageId" required>
        <option value="">Выберите...</option>
        <option value="{{ widgetPackage.id }}"
                *ngFor="let widgetPackage of widgetPackages">{{ widgetPackage.title }}</option>
      </select>
      <span class="invalid-feedback"
            *ngIf="form.controls['packageId'].invalid && form.controls['packageId'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Период</div>
    <div class="input-group">

      <input type="text"
             class="form-control mr-3"
             formControlName="dateFrom"
             placeholder="dd.mm.yyyy"
             [dropSpecialCharacters]="false"
             mask="00.00.0000"
             required>

      <input type="text"
             class="form-control"
             formControlName="dateTo"
             placeholder="dd.mm.yyyy"
             [dropSpecialCharacters]="false"
             mask="00.00.0000"
             required>

      <span class="invalid-feedback"
            *ngIf="(form.controls['dateFrom'].invalid && form.controls['dateFrom'].touched) || (form.controls['dateTo'].invalid && form.controls['dateTo'].touched)">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <button class="btn btn-primary sc-wide"
            [disabled]="cannotSubmit || form.invalid || !!formError">
      Сохранить
    </button>
    <button class="btn btn-secondary ml-3" (click)="onFormCancel()">Отмена</button>
    <div *ngIf="formError">{{ formError.message }}</div>
  </div>

</form>
