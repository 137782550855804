import {
    Component,
    OnInit,
} from '@angular/core';
import {
    Event as RouterEvent,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { LayoutService } from './layout/layout.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'admin';

    constructor(
        private router: Router,
        private appService: AppService,
        private layoutService: LayoutService,
    ) {
        this.router.events.subscribe(this.navigationInterceptor.bind(this));
        if (typeof (document as any).documentMode === 'number' && (document as any).documentMode < 11) {
            const style = document.createElement('style');
            style.textContent = `
        * {
          -ms-animation: none !important;
          animation: none !important;
          -ms-transition: none !important;
          transition: none !important;
        }`;
            document.head.appendChild(style);
        }
    }

    private navigationInterceptor(e: RouterEvent): void {
        if (e instanceof NavigationStart) {
            document.body.classList.add('app-loading');
        }

        if (e instanceof NavigationEnd) {
            this.appService.scrollTop(0, 0);
        }

        if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
            if (this.layoutService.isSmallScreen() && !this.layoutService.isCollapsed()) {
                setTimeout(() => this.layoutService.setCollapsed(true, true), 10);
            }

            document.body.classList.remove('app-loading');
        }
    }

    ngOnInit(): void {
        if (environment.production) {
            Logger.enableProductionMode();
        }
    }

}
