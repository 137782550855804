import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingUiModule } from '../../ui/loading/loading.module';
import { LocationUiModule } from '../../ui/location/locations.module';
import { ScrollUiModule } from '../../ui/scroll/scroll-ui.module';
import { RecommendationAddComponent } from './recommendation-add/recommendation-add.component';
import { RecommendationFormComponent } from './recommendation-form/recommendation-form.component';
import { RecommendationListComponent } from './recommendation-list/recommendation-list.component';
import { RecommendationEditComponent } from './recommendation-edit/recommendation-edit.component';


@NgModule({
    declarations: [
        RecommendationListComponent,
        RecommendationFormComponent,
        RecommendationAddComponent,
        RecommendationEditComponent,
    ],
    imports: [
        CommonModule,
        ScrollUiModule,
        LoadingUiModule,
        LocationUiModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
    ],
})
export class RecommendationModule {}
