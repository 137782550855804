<div>

  <div class="content">
    <div *ngFor="let service of services">
      <div class="service">
        <div class="service__info">
          <div class="service__number">
            {{ service.id }}
          </div>
          <div (click)="onServiceClick(service)" class="service__title">
            {{ service.title }}
          </div>
        </div>
        <span class="service__delete" (click)="delete(service)">удалить</span>
      </div>
    </div>
  </div>

</div>
