import {
    Component,
    Input,
} from '@angular/core';
import { schemas } from '@scatch/ngx-app-lib';


@Component({
    selector: 'ui-geo-route',
    templateUrl: './geo-route.component.html',
    styleUrls: ['./geo-route.component.scss'],
})
export class GeoRouteComponent {

    @Input() geoRoute?: schemas.GeoRoute;

}
