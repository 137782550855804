import { Routes } from '@angular/router';
import { UserPageComponent } from '../modules/user/user-page/user-page.component';


export const usersRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: UserPageComponent,
    },
];
