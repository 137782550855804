import { Component } from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { map } from 'rxjs/operators';
import { RecommendationFacade } from '../../../facades/recommendation.facade';


const logger = new Logger('RecommendationEditComponent');

@Component({
    selector: 'app-recommendation-edit',
    templateUrl: './recommendation-edit.component.html',
    styleUrls: ['./recommendation-edit.component.scss'],
})
export class RecommendationEditComponent {

    readonly recommendation$ = this.route.data.pipe(
        map((data: { recommendation?: schemas.Recommendation }) => data.recommendation),
    );

    cannotSubmit = false;
    formError?: schemas.ResponseError;

    constructor(
        private recommendationFacade: RecommendationFacade,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    onFormSubmit(formData: schemas.RecommendationUpdate & { id: number }): void {
        logger.debug('onFormSubmit', formData);

        const data: schemas.RecommendationUpdate = {
            order: formData.order,
        };

        this.cannotSubmit = true;
        this.recommendationFacade.updateRecommendationById(formData.id, data).subscribe({
            next: this.goBack.bind(this),
            error: error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        });
    }

    goBack(): void {
        this.router.navigate(['..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }
}
