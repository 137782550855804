import { NavigationExtras } from '@angular/router';
import {
    createAction,
    props,
} from '@ngrx/store';


export const goWithCurrentRedirectUriAction = createAction(
    '[Router] Go With Current Redirect Uri',
    props<{ commands: any[], extras?: NavigationExtras }>(),
);
