<div [ngClass]="getClasses()" class="sidenav">

    <!-- Inner -->
    <div class="sidenav-inner">

        <sidenav-router-link
                icon="ion ion-ios-settings"
                route="/capsules"
                (click)="toggleSidenav()"
                [active]="activeRouteUrl.startsWith('/capsules')">
            Капсулы
        </sidenav-router-link>

        <sidenav-router-link
                icon="ion ion-ios-settings"
                route="/users"
                (click)="toggleSidenav()"
                [active]="activeRouteUrl.startsWith('/users')">
            Юзеры
        </sidenav-router-link>

        <sidenav-router-link
                icon="ion ion-ios-settings"
                route="/widgets"
                (click)="toggleSidenav()"
                [active]="activeRouteUrl.startsWith('/widgets')">
            Виджеты
        </sidenav-router-link>

        <sidenav-router-link
                icon="ion ion-ios-settings"
                route="/widget-packages"
                (click)="toggleSidenav()"
                [active]="activeRouteUrl.startsWith('/widget-packages')">
            Пакеты виджетов
        </sidenav-router-link>

        <sidenav-router-link
                icon="ion ion-ios-settings"
                route="/services"
                (click)="toggleSidenav()"
                [active]="activeRouteUrl.startsWith('/services')">
            Сервисы
        </sidenav-router-link>

        <sidenav-router-link
                icon="ion ion-ios-settings"
                route="/recommendation"
                (click)="toggleSidenav()"
                [active]="activeRouteUrl.startsWith('/recommendation')">
            Рекомендации
        </sidenav-router-link>

        <sidenav-router-link
                icon="ion ion-ios-settings"
                route="/geo"
                (click)="toggleSidenav()"
                [active]="activeRouteUrl.startsWith('/geo')">
            GEO
        </sidenav-router-link>

        <sidenav-router-link
                icon="ion ion-ios-settings"
                route="/upload"
                (click)="toggleSidenav()"
                [active]="activeRouteUrl.startsWith('/upload')">
            Медиа
        </sidenav-router-link>

    </div>
</div>
