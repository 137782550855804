import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { Widget } from '../schemas/widget.schemas';
import { WidgetApi } from '../services/api/widget/widget.api';


const logger = new Logger('WidgetResolver');

@Injectable({
    providedIn: 'root',
})
export class WidgetResolver implements Resolve<Widget> {

    constructor(
        private widgetApi: WidgetApi,
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<Widget> {
        const widgetId = Number(route.paramMap.get('widgetId'));

        logger.debug('resolve (widgetId)', widgetId);

        return this.widgetApi.fetchWidgetById(widgetId);
    }

}
