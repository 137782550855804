import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { WidgetFacade } from '../../../facades/widget.facade';
import {
    License,
    LicenseFindParams,
} from '../../../schemas/widget.schemas';


const logger = new Logger('LicenseListComponent');

@Component({
    selector: 'app-license-list',
    templateUrl: './license-list.component.html',
    styleUrls: ['./license-list.component.scss'],
})
export class LicenseListComponent implements OnInit, OnChanges {

    licenses: License[] = [];

    @Input() params?: LicenseFindParams | null;

    @Output() licenseClick = new EventEmitter();

    constructor(
        private widgetFacade: WidgetFacade,
    ) { }

    ngOnInit(): void {
        if (!this.params) {
            this.loadNextLicenses();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params?.currentValue) {
            this.params = changes.params?.currentValue;
            this.licenses = [];
            this.loadNextLicenses();
        }
    }

    loadNextLicenses(): void {
        this.widgetFacade.fetchLicenses({
            ...this.params,
            afterId: this.licenses[this.licenses.length - 1]?.id || 0,
            offset: this.licenses.length,
            limit: this.params?.limit || 100,
        }).subscribe(licenses => {
            this.licenses.push(...licenses);
        });
    }

    onLicenseClick(license: License): void {
        this.licenseClick.emit(license);
    }

    delete(license: License): void {
        if (confirm('Удалить лицензию ' + license.title + '?')) {
            this.widgetFacade.deleteLicense(license).subscribe(result => {
                if (result) {
                    this.licenses = this.licenses.filter(
                        item => item.id !== license.id,
                    );
                }
            });
        }
    }

}
