<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

  <div class="form-group">
    <div class="title-form-group">Никнейм виджета (widget-nickname)</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="nickname"
             placeholder="widget-nickname">
      <span class="invalid-feedback"
            *ngIf="form.controls['nickname'].invalid && form.controls['nickname'].touched">
        Некорректно заполненное поле
        <span *ngIf="form.controls['nickname'].hasError('unique')">(такой уже есть)</span>
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Заголовок виджета</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="title"
             placeholder="Введите заголовок..."
             required>
      <span class="invalid-feedback"
            *ngIf="form.controls['title'].invalid && form.controls['title'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Подзаголовок</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="subtitle">
      <span class="invalid-feedback"
            *ngIf="form.controls['subtitle'].invalid && form.controls['subtitle'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Иконка виджета</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             placeholder="https://img.domain/icon.png"
             formControlName="icon">
      <span class="invalid-feedback"
            *ngIf="form.controls['icon'].invalid && form.controls['icon'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Тип виджета</div>
    <div class="input-group">
      <select class="form-control" formControlName="typeId" required>
        <option value="" selected>Выберите...</option>
        <option [ngValue]="widgetType.id"
                *ngFor="let widgetType of widgetTypes">
          {{ widgetType.title }}
        </option>
      </select>
      <span class="invalid-feedback"
            *ngIf="form.controls['typeId'].invalid && form.controls['typeId'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Манифест</div>
    <textarea formControlName="manifest" class="form-control" cols="30" rows="5"></textarea>
  </div>

  <div class="form-group">
    <button class="btn btn-primary"
            [disabled]="cannotSubmit || form.invalid || !!formError">
      Сохранить
    </button>
    <button class="btn btn-secondary" (click)="onFormCancel()">Отмена</button>
    <div *ngIf="formError">{{ formError.message }}</div>
  </div>

</form>
