import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';


const logger = new Logger('RecommendationFormComponent');

@Component({
    selector: 'app-recommendation-form',
    templateUrl: './recommendation-form.component.html',
    styleUrls: ['./recommendation-form.component.scss'],
})
export class RecommendationFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    form: FormGroup;

    @Input() recommendation?: schemas.Recommendation | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;
    @Input() isEdit = false;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor() {

        this.form = new FormGroup({

            adInventoryId: new FormControl('', [
                Validators.required,
                Validators.pattern('[0-9]+'),
            ]),

            locationId: new FormControl('', [
                Validators.required,
                Validators.pattern('[0-9]+'),
            ]),

            recLocationId: new FormControl('', [
                Validators.required,
                Validators.pattern('[0-9]+'),
            ]),

            order: new FormControl('', [
                Validators.required,
                Validators.pattern('[0-9]+'),
            ]),
        });

    }

    ngOnInit(): void {
        this.form.controls.adInventoryId.setValue(1);
        this.form.controls.locationId.setValue(0);

        if (!this.recommendation?.order) {
            this.form.controls.order.setValue(0);
        }

        logger.debug('recommendation', this.recommendation);

        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.recommendation?.currentValue) {
            this.form.patchValue({
                ...changes.recommendation.currentValue,
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const result = {
            ...this.recommendation,
            ...this.form.value,
        };

        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }

}
