import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewImageComponent } from './preview-image/preview-image.component';



@NgModule({
    declarations: [
        PreviewImageComponent
    ],
    exports: [
        PreviewImageComponent
    ],
    imports: [
        CommonModule
    ]
})
export class UploadUiModule { }
