import {
    DEFAULT_ROUTER_FEATURENAME,
    routerReducer,
    RouterReducerState,
} from '@ngrx/router-store';
import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { AuthEffects } from './effects/auth.effects';
import { LocalStorageEffects } from './effects/local-storage.effects';
import { RouterEffects } from './effects/router.effects';
import {
    authReducer,
    AuthReducerState,
    DEFAULT_AUTH_FEATURE_NAME,
} from './reducers/auth.reducer';


export interface State {
    [DEFAULT_AUTH_FEATURE_NAME]: AuthReducerState;
    [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState;
}

export const reducers: ActionReducerMap<State> = {
    [DEFAULT_AUTH_FEATURE_NAME]: authReducer,
    [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [debug]
    : [];

export const effects = [
    AuthEffects,
    LocalStorageEffects,
    RouterEffects,
];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        console.log('state', state);
        console.log('action', action);

        return reducer(state, action);
    };
}
