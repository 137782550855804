<div>
    <button class="btn btn-secondary" (click)="loadNextLicenses()" *ngIf="licenses.length">Загрузить еще</button>

    <div class="content">
        <div *ngFor="let license of licenses">
            <div class="license">
                <div class="license__info">
                    <div class="license__id">
                        {{ license.id }}
                    </div>
                    <div>
                        <div (click)="onLicenseClick(license)" class="license__title">
                            {{ license.title }}
                        </div>
                        <div class="license__date">
                            {{ license.dateFrom | date:'dd.MM.YYYY' }} - {{ license.dateTo | date:'dd.MM.YYYY' }}
                        </div>
                    </div>
                </div>
                <span class="license__delete" (click)="delete(license)">удалить</span>
            </div>
        </div>
    </div>

</div>
