import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';


const logger = new Logger('GeoPrimitiveFormComponent');

@Component({
    selector: 'app-geo-primitive-form',
    templateUrl: './geo-primitive-form.component.html',
    styleUrls: ['./geo-primitive-form.component.scss'],
})
export class GeoPrimitiveFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    form: FormGroup;
    types: string[] = ['point', 'polygon', 'line'];

    @Input() geoPrimitive?: schemas.GeoPrimitive | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;
    @Input() isEdit = false;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor() {

        this.form = new FormGroup({

            title: new FormControl('', [
                Validators.required,
            ]),

            description: new FormControl('', []),

            rubric: new FormControl('', [
                Validators.required,
            ]),

            icon: new FormControl('', [
                Validators.required,
            ]),

            capsuleId: new FormControl('', [
                Validators.required,
                Validators.pattern('[0-9]+'),
            ]),

            contentId: new FormControl('', [
                Validators.required,
                Validators.pattern('[0-9]+'),
            ]),

            type: new FormControl('', [
                Validators.required,
            ]),

            geoJson: new FormControl('', [
                Validators.required,
            ]),
        });

    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }


    ngOnChanges(changes: SimpleChanges): void {
        logger.debug('changes', changes);
        if (changes.geoPrimitive?.currentValue) {
            const geoJson = JSON.stringify(changes.geoPrimitive?.currentValue.geoJson, null, 4);
            this.form.patchValue({
                ...changes.geoPrimitive?.currentValue,
                geoJson,
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const geoJson = JSON.parse(this.form.value.geoJson || '{}');
        const result = {
            ...this.geoPrimitive,
            ...this.form.value,
            geoJson,
        };
        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }
}
