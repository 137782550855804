<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

  <div class="form-group">
    <div class="title-form-group">Название локации</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="title"
             placeholder="Введите название..."
             tabindex="1"
             required>
      <span class="invalid-feedback"
            *ngIf="form.controls['title'].invalid && form.controls['title'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Иконка локации</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="icon"
             tabindex="2">
      <span class="invalid-feedback"
            *ngIf="form.controls['icon'].invalid && form.controls['icon'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Родительская локация (ID локации)</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="parentId"
             placeholder="0"
             tabindex="3">
      <span class="invalid-feedback"
            *ngIf="form.controls['parentId'].invalid && form.controls['parentId'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Гео примитив (ID гео примитива)</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="geoId"
             placeholder="0"
             tabindex="4">
      <span class="invalid-feedback"
            *ngIf="form.controls['geoId'].invalid && form.controls['geoId'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Координаты локации (широта)</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="lat"
             placeholder="52.262492"
             tabindex="5">
      <span class="invalid-feedback"
            *ngIf="form.controls['lat'].invalid && form.controls['lat'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Координаты локации (долгота)</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="lon"
             placeholder="104.261278"
             tabindex="6">
      <span class="invalid-feedback"
            *ngIf="form.controls['lon'].invalid && form.controls['lon'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Рубрика локации</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="rubric"
             tabindex="7">
      <span class="invalid-feedback"
            *ngIf="form.controls['rubric'].invalid && form.controls['rubric'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

    <div class="form-group">
        <div class="title-form-group">Порядок сортировки</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="ordering"
                   tabindex="8">
            <span class="invalid-feedback"
                  *ngIf="form.controls['ordering'].invalid && form.controls['ordering'].touched">
        Некорректно заполненное поле
      </span>
        </div>
    </div>

    <div class="form-group">
    <div class="checkbox">
      <input type="checkbox" formControlName="isShowStruct" id="isShowStruct">
      <label for="isShowStruct" style="margin-left: 15px">
        <i></i>
        <span>Показывать в структуре</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="checkbox">
      <input type="checkbox" formControlName="isShowMap" id="isShowMap">
      <label for="isShowMap" style="margin-left: 15px">
        <i></i>
        <span>Показывать на карте</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="checkbox">
      <input type="checkbox" formControlName="isEmpty" id="isEmpty">
      <label for="isEmpty" style="margin-left: 15px">
        <i></i>
        <span>Пустая</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="checkbox">
      <input type="checkbox" formControlName="isPublic" id="isPublic">
      <label for="isPublic" style="margin-left: 15px">
        <i></i>
        <span>Публичная</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="checkbox">
      <input type="checkbox" formControlName="isDynamic" id="isDynamic">
      <label for="isDynamic" style="margin-left: 15px">
        <i></i>
        <span>Динамическая</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Манифест</div>
    <textarea formControlName="manifest" class="form-control" cols="30" rows="5"></textarea>
  </div>

  <div class="form-group">
    <button class="btn btn-primary sc-wide"
            [disabled]="cannotSubmit || form.invalid || !!formError">
      Сохранить
    </button>
    <span class="btn btn-secondary ml-3" (click)="onFormCancel()">Отмена</span>
    <div *ngIf="formError">{{ formError.message }}</div>
  </div>

</form>
