import { Injectable } from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    mapTo,
} from 'rxjs/operators';
import { GeoRouteApi } from '../../services/api/geo/geo-route.api';


const logger = new Logger('GeoRouteFacade');

@Injectable({
    providedIn: 'root',
})
export class GeoRouteFacade {

    constructor(
        private geoRouteApi: GeoRouteApi,
    ) {
    }

    fetchGeoRoutes(params: schemas.GeoRouteFindParams): Observable<schemas.GeoRoute[] | null> {
        return this.geoRouteApi.fetchGeoRoutes(params);
    }

    createGeoRoute(data: schemas.GeoRouteCreate): Observable<schemas.GeoRoute | null> {
        return this.geoRouteApi.createGeoRoute(data);
    }

    updateGeoRoute(id: number, data: schemas.GeoRouteUpdate): Observable<schemas.GeoRoute | null> {
        return this.geoRouteApi.updateGeoRouteById(id, data);
    }

    deleteGeoRouteById(id: number): Observable<boolean> {
        return this.geoRouteApi.deleteGeoRouteById(id).pipe(
            mapTo(true),
            catchError(() => of(false)),
        );
    }
}
