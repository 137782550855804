import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { Service } from '../../../schemas/service.schemas';
import { UniqueTitleValidator } from '../../../validators/service.validator';


const logger = new Logger('ServiceFormComponent');

@Component({
    selector: 'app-service-form',
    templateUrl: './service-form.component.html',
    styleUrls: ['./service-form.component.scss'],
})
export class ServiceFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    form: FormGroup;

    @Input() service?: Service | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor(
        private readonly titleValidator: UniqueTitleValidator,
    ) {

        this.form = new FormGroup({

            title: new FormControl('', {
                validators: [
                    Validators.required,
                ],
                asyncValidators: [
                    this.titleValidator.validate.bind(this.titleValidator),
                ],
                updateOn: 'blur',
            }),

            isPublic: new FormControl(false),

            manifest: new FormControl(''),

            capsuleId: new FormControl(0, [
                Validators.pattern('\\d+'),
            ]),

        });

    }

    ngOnInit(): void {
        this.titleValidator.setServiceId(this.service?.id);
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.service?.currentValue) {
            const manifest = JSON.stringify(changes.service?.currentValue?.manifest, null, 4);
            this.titleValidator.setServiceId(changes.service?.currentValue?.id);
            this.form.patchValue({
                ...changes.service.currentValue,
                manifest,
            });
        }
    }

    ngOnDestroy(): void {
        this.titleValidator.setServiceId();
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const manifest = JSON.parse(this.form.value.manifest || '{}');
        const result = {
            ...this.service,
            ...this.form.value,
            manifest,
        };

        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }

}
