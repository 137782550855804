import {
    Component,
    OnDestroy,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TelegramFacade } from '../../../facades/telegram.facade';
import {
    TelegramBot,
    TelegramBotFindParams,
} from '../../../schemas/telegram.schemas';


const logger = new Logger('TelegramBotListComponent');

@Component({
    selector: 'app-telegram-bot-list',
    templateUrl: './telegram-bot-list.component.html',
    styleUrls: ['./telegram-bot-list.component.scss'],
})
export class TelegramBotListComponent implements OnDestroy {
    private subscriptions: Subscription[] = [];

    public bots: TelegramBot[] = [];
    public findParams: TelegramBotFindParams = {
        offset: 0,
        limit: 20,
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private telegramFacade: TelegramFacade,
        private toastrService: ToastrService,
    ) {
        this.findParams.capsuleId = Number(this.activatedRoute.snapshot.paramMap.get('capsuleId'));
    }

    public fetchContent(): void {
        this.subscriptions.push(
            this.telegramFacade.fetchTelegramBots(this.findParams).subscribe({
                next: (bots) => {
                    if (bots && bots.length > 0) {
                        this.bots.push(...bots);

                        if (this.findParams.offset !== undefined) {
                            this.findParams.offset += this.bots.length;
                        }
                    }
                },
                error: (error) => {
                    this.toastrService.error(error);
                    logger.debug('fetchTelegramBots error', error);
                },
            }),
        );
    }

    public clickBot(bot: TelegramBot): void {
        this.router.navigate([bot.id], { relativeTo: this.activatedRoute }).then();
    }

    public deleteBot(bot: TelegramBot): void {
        if (!bot) {
            return;
        }

        this.subscriptions.push(
            this.telegramFacade.deleteTelegramBotById(bot.id).subscribe({
                next: () => {
                    this.bots = this.bots.filter((item) => item.id !== bot.id);
                    this.toastrService.success('Telegram Бот успешно удален');
                },
                error: (error) => {
                    this.toastrService.error(error);
                },
            }),
        );
    }

    public goToAddBot(): void {
        this.router.navigate(['add'], { relativeTo: this.activatedRoute }).then();
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
        logger.debug('destroy');
    }
}
