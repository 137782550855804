import { Injectable } from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from '../api.service';


const logger = new Logger('GeoRouteApi');

@Injectable({
    providedIn: 'root',
})
export class GeoRouteApi {

    constructor(
        private apiService: ApiService,
    ) {
    }

    fetchGeoRoutes(params: schemas.GeoRouteFindParams): Observable<schemas.GeoRoute[] | null> {
        return this.apiService.getJson<schemas.GeoRoute[] | null>(
            '/geo/route',
            params,
        ).pipe(
            tap(routes => logger.debug('fetchGeoRoutes (routes)', routes)),
        );
    }

    fetchGeoRouteById(id: number): Observable<schemas.GeoRoute | null> {
        return this.apiService.getJson<schemas.GeoRoute | null>(
            '/geo/route/' + id,
        ).pipe(
            tap(route => logger.debug('fetchGeoRouteById (route)', route)),
        );
    }

    deleteGeoRouteById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/geo/route/' + id,
        ).pipe(
            tap(() => logger.debug('deleteGeoRouteById (id)', id)),
        );
    }

    createGeoRoute(data: schemas.GeoRouteCreate): Observable<schemas.GeoRoute | null> {
        return this.apiService.postJson<schemas.GeoRoute | null>(
            '/geo/route',
            data,
        ).pipe(
            tap(route => logger.debug('createGeoRoute (route)', route)),
        );
    }

    updateGeoRouteById(id: number, data: schemas.GeoRouteUpdate): Observable<schemas.GeoRoute> {
        return this.apiService.patchJson<schemas.GeoRoute>(
            '/geo/route/' + id,
            data,
        ).pipe(
            tap(route => logger.debug('updateGeoRouteById (route)', route)),
        );
    }
}
