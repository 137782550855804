import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    Location,
    LocationCreate,
    LocationFindParams,
    LocationUpdate,
    LocationWidget,
    LocationWidgetCreate,
    LocationWidgetFindParams,
    LocationWidgetUpdate,
} from '../../../schemas/location.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('LocationApi');

@Injectable({
    providedIn: 'root',
})
export class LocationApi {

    constructor(
        private apiService: ApiService,
    ) { }

    findLocations(params: LocationFindParams): Observable<Location[]> {
        return this.apiService.getJson<Location[]>(
            '/capsule/location',
            params,
        ).pipe(
            tap(locations => logger.debug('findLocations (locations)', locations)),
        );
    }

    fetchLocationById(id: number): Observable<Location> {
        return this.apiService.getJson<Location>(
            '/capsule/location/' + id,
        ).pipe(
            tap(location => logger.debug('getLocationById (location)', location)),
        );
    }

    createLocation(data: LocationCreate): Observable<Location> {
        return this.apiService.postJson<Location>(
            '/capsule/location',
            data,
        ).pipe(
            tap(location => logger.debug('createLocation (location)', location)),
        );
    }

    updateLocationById(id: number, data: LocationUpdate): Observable<Location> {
        return this.apiService.patchJson<Location>(
            '/capsule/location/' + id,
            data,
        ).pipe(
            tap(location => logger.debug('updateLocationById (location)', location)),
        );
    }

    deleteLocationById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/capsule/location/' + id,
        ).pipe(
            tap(() => logger.debug('deleteLocationById (id)', id)),
        );
    }

    findLocationWidgets(params: LocationWidgetFindParams): Observable<LocationWidget[]> {
        return this.apiService.getJson<LocationWidget[]>(
            '/capsule/widget-config',
            params,
        ).pipe(
            tap(locationWidgets => logger.debug('findLocationWidgets (locationWidgets)', locationWidgets)),
        );
    }

    createLocationWidget(data: LocationWidgetCreate): Observable<LocationWidget> {
        return this.apiService.postJson<LocationWidget>(
            '/capsule/widget-config',
            data,
        ).pipe(
            tap(locationWidget => logger.debug('createLocationWidget (locationWidget)', locationWidget)),
        );
    }

    updateLocationWidgetById(id: number, data: LocationWidgetUpdate): Observable<LocationWidget> {
        return this.apiService.patchJson<LocationWidget>(
            '/capsule/widget-config/' + id,
            data,
        ).pipe(
            tap(locationWidget => logger.debug('updateLocationWidgetById (locationWidget)', locationWidget)),
        );
    }

    fetchLocationWidgetById(id: number): Observable<LocationWidget> {
        return this.apiService.getJson<LocationWidget>(
            '/capsule/widget-config/' + id,
        ).pipe(
            tap(locationWidget => logger.debug('fetchLocationWidgetById (locationWidget)', locationWidget)),
        );
    }

}
