import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { Capsule } from '../../../schemas/capsule.schemas';
import {
    Location,
    LocationFindParams,
} from '../../../schemas/location.schemas';


const logger = new Logger('CapsuleDetailComponent');

@Component({
    selector: 'app-capsule-detail',
    templateUrl: './capsule-detail.component.html',
    styleUrls: ['./capsule-detail.component.scss'],
})
export class CapsuleDetailComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    locationFindParams?: LocationFindParams;
    capsule?: Capsule;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.data.subscribe((data: {
                capsule?: Capsule;
                location?: Location;
            }) => {
                this.capsule = data.capsule;
                this.locationFindParams = {
                    capsuleId: data.capsule?.id,
                };
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onLocationClick(location: Location): void {
        this.router.navigate(['locations', location.id], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
