import { Injectable } from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    ValidationErrors,
} from '@angular/forms';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';
import { WidgetApi } from '../services/api/widget/widget.api';


const logger = new Logger('WidgetValidator');

@Injectable({providedIn: 'root'})
export class UniqueNicknameValidator implements AsyncValidator {

    private widgetId?: number;

    constructor(
        private readonly widgetApi: WidgetApi,
    ) {}

    setWidgetId(id?: number): void {
        this.widgetId = id;
    }

    validate(
        control: AbstractControl,
    ): Observable<ValidationErrors | null> {
        return this.widgetApi.findWidgets({
            nickname: control.value,
        }).pipe(
            map(isExists => {
                if (!isExists?.length) {
                    return null;
                }

                if (!this.widgetId) {
                    return {unique: true};
                }

                for (const item of isExists) {
                    if (item.id !== this.widgetId) {
                        return {unique: true};
                    }
                }

                return null;
            }),
            catchError(() => of(null)),
        );
    }

}
