import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import {
    forkJoin,
    Observable,
} from 'rxjs';
import {
    concatMap,
    map,
    mapTo,
} from 'rxjs/operators';
import { WIDGET_TYPE_SUFFIXES } from '../app.constants';
import {
    IframeWidgetCreate,
    License,
    LicenseCreate,
    Widget,
    WidgetCreate,
    WidgetPackage,
    WidgetType,
} from '../schemas/widget.schemas';
import { LocationApi } from './api/capsule/location.api';
import { LicenseApi } from './api/widget/license.api';
import { PackageApi } from './api/widget/package.api';
import { WidgetApi } from './api/widget/widget.api';


const logger = new Logger('WidgetService');

@Injectable({
    providedIn: 'root',
})
export class WidgetService {

    constructor(
        private locationApi: LocationApi,
        private licenseApi: LicenseApi,
        private packageApi: PackageApi,
        private widgetApi: WidgetApi,
    ) { }

    createLicense(data: LicenseCreate): Observable<License> {
        return this.licenseApi.createLicense(data).pipe(
            concatMap((license: License) =>
                this.widgetApi.fetchWidgetsByWidgetPackageId(license.packageId).pipe(
                    concatMap((widgets: Widget[]) => {
                        const tasks = [];

                        for (const widget of widgets) {
                            tasks.push(
                                this.locationApi.createLocationWidget({
                                    locationId: license.locationId,
                                    widgetId: widget.id,
                                }),
                            );
                        }

                        return forkJoin(tasks);
                    }),
                    mapTo(license),
                ),
            ),
        );
    }

    createIframeWidgetsPackage(data: IframeWidgetCreate): Observable<WidgetPackage> {
        const widgetCreates: WidgetCreate[] = [];

        logger.debug('createIframeWidgetsPackage (data)', data);

        data.widgetTypes.forEach((widgetType: WidgetType) => {
            const suffix = WIDGET_TYPE_SUFFIXES[widgetType.id];
            const nickname = suffix?.length
                ? data.nickname + '-' + suffix
                : data.nickname;

            widgetCreates.push({
                nickname,
                title: data.title,
                icon: data.icon,
                typeId: widgetType.id,
                manifest: {
                    uri: data.uri,
                },
            });
        });

        return this.widgetApi.findWidgets({
            idIn: widgetCreates.map(widget => widget.nickname).join(','),
        }).pipe(
            map((existWidgets: Widget[]) => {
                if (existWidgets.length) {
                    throw new Error('Nickname already used!');
                }
            }),
            concatMap(() =>
                forkJoin(widgetCreates.map(widget =>
                    this.widgetApi.createWidget(widget),
                )),
            ),
            concatMap((widgets: Widget[]) =>
                this.packageApi.createWidgetPackage({
                    title: data.title,
                    widgets: widgets.map(widget => widget.id),
                }),
            ),
        );
    }

}
