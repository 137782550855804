import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    TelegramBot,
    TelegramBotCreate,
    TelegramBotEdit,
    TelegramBotFindParams,
} from '../schemas/telegram.schemas';
import { TelegramApi } from '../services/api/telegram/telegram.api';


const logger = new Logger('TelegramFacade');

@Injectable({
    providedIn: 'root',
})
export class TelegramFacade {

    constructor(
        private telegramApi: TelegramApi,
    ) {
    }

    createTelegramBot(params: TelegramBotCreate): Observable<TelegramBot | null> {
        return this.telegramApi.createTelegramBot(params);
    }

    fetchTelegramBots(params: TelegramBotFindParams): Observable<TelegramBot[] | null> {
        return this.telegramApi.fetchTelegramBots(params);
    }

    deleteTelegramBotById(id: number): Observable<void> {
        return this.telegramApi.deleteTelegramBotById(id);
    }

    fetchTelegramBotById(id: number): Observable<TelegramBot | null> {
        return this.telegramApi.fetchTelegramBotById(id);
    }

    updateTelegramBotById(id: number, data: TelegramBotEdit): Observable<TelegramBot | null> {
        return this.telegramApi.updateTelegramBotById(id, data);
    }
}
