import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { GeoPrimitiveApi } from '../services/api/geo/geo-primitive.api';


const logger = new Logger('GeoResolver');

@Injectable({
    providedIn: 'root',
})
export class GeoPrimitiveResolver implements Resolve<schemas.GeoPrimitive | null> {

    constructor(
        private geoPrimitiveApi: GeoPrimitiveApi,
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<schemas.GeoPrimitive | null> {
        const geoPrimitiveId = Number(route.paramMap.get('geoPrimitiveId'));
        logger.debug('resolve (geoPrimitiveId)', geoPrimitiveId);

        return this.geoPrimitiveApi.fetchGeoPrimitiveById(geoPrimitiveId);
    }

}
