<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
    <div class="form-group">
        <div class="title-form-group">Название</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="title"
                   placeholder="Введите название...">
            <span class="invalid-feedback"
                  *ngIf="form.controls['title'].invalid && form.controls['title'].touched">
                Некорректно заполненное поле
            </span>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Иконка</div>
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   formControlName="icon">
            <span class="invalid-feedback"
                  *ngIf="form.controls['icon'].invalid && form.controls['icon'].touched">
                Некорректно заполненное поле
            </span>
        </div>
    </div>

    <div class="form-group">
        <div class="checkbox">
            <input type="checkbox" formControlName="captcha" id="captcha">
            <label for="captcha">
                <i></i>
                <span>Включить капчу</span>
            </label>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Плитка</div>
        <div class="form-group__items">
            <div>
                <div class="title-form-group">Строки</div>
                <div class="input-group">
                    <input type="number"
                           class="form-control"
                           formControlName="rowspan">
                    <span class="invalid-feedback"
                          *ngIf="form.controls['rowspan'].invalid && form.controls['rowspan'].touched">
                        Некорректно заполненное поле
                    </span>
                </div>
            </div>

            <div>
                <div class="title-form-group">Столбцы</div>
                <div class="input-group">
                    <input type="number"
                           class="form-control"
                           formControlName="colspan">
                    <span class="invalid-feedback"
                          *ngIf="form.controls['colspan'].invalid && form.controls['colspan'].touched">
                        Некорректно заполненное поле
                    </span>
                </div>
            </div>

            <div>
                <div class="title-form-group">Сортировка</div>
                <div class="input-group">
                    <input type="number"
                           class="form-control"
                           formControlName="ordering">
                    <span class="invalid-feedback"
                          *ngIf="form.controls['ordering'].invalid && form.controls['ordering'].touched">
                    Некорректно заполненное поле
                </span>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group">
        <div class="title-form-group">Параметры</div>
        <textarea formControlName="params" class="form-control" cols="30" rows="15"></textarea>
    </div>

    <div class="form-group">
        <button class="btn btn-primary sc-wide"
                [disabled]="cannotSubmit || form.invalid || !!formError">
            Сохранить
        </button>
        <span class="btn btn-secondary ml-3" (click)="onFormCancel()">Отмена</span>
        <div *ngIf="formError">{{ formError.message }}</div>
    </div>
</form>
