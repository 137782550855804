import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { WidgetPackage } from '../../../../schemas/widget.schemas';


const logger = new Logger('WidgetPackageDetailComponent');

@Component({
    selector: 'app-widget-package-detail',
    templateUrl: './widget-package-detail.component.html',
    styleUrls: ['./widget-package-detail.component.scss'],
})
export class WidgetPackageDetailComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    widgetPackage?: WidgetPackage;

    constructor(
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.data.subscribe((data: {
                widgetPackage?: WidgetPackage;
            }) => {
                this.widgetPackage = data.widgetPackage;
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

}
