import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { LocationFacade } from '../../../facades/location.facade';
import { Capsule } from '../../../schemas/capsule.schemas';
import { Location } from '../../../schemas/location.schemas';
import {
    License,
    Widget,
} from '../../../schemas/widget.schemas';


const logger = new Logger('LicenseDetailComponent');

@Component({
    selector: 'app-license-detail',
    templateUrl: './license-detail.component.html',
    styleUrls: ['./license-detail.component.scss'],
})
export class LicenseDetailComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    capsule?: Capsule;
    location?: Location;
    license?: License;

    constructor(
        private readonly locationFacade: LocationFacade,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.data.subscribe((data: {
                capsule?: Capsule;
                location?: Location;
                license?: License;
            }) => {
                this.capsule = data.capsule;
                this.location = data.location;
                this.license = data.license;
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onWidgetClick(widget: Widget): void {
        this.locationFacade.fetchLocationWidgets({
            locationId: this.location?.id,
            widgetId: widget.id,
        }).subscribe(locationWidgets => {
            if (locationWidgets.length) {
                const locationWidgetId = locationWidgets[0].id;

                this.router.navigate(['location-widgets', locationWidgetId, 'edit'], {relativeTo: this.route})
                    .catch(reason => logger.error('Navigate failed', reason));
            }
        });
    }

}
