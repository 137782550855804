import { Component } from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { ServiceFindParams } from '../../../../schemas/service.schemas';
import { WidgetPackage } from '../../../../schemas/widget.schemas';


const logger = new Logger('WidgetPackagePageComponent');

@Component({
    selector: 'app-widget-package-page',
    templateUrl: './widget-package-page.component.html',
    styleUrls: ['./widget-package-page.component.scss'],
})
export class WidgetPackagePageComponent {

    widgetPackageFindParams: ServiceFindParams = {
        limit: 0,
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    onWidgetPackageClick(widgetPackage: WidgetPackage): void {
        this.router.navigate([widgetPackage.id], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
