import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';
import {
    Capsule,
    CapsuleCreate,
    CapsuleFindParams,
    CapsuleUpdate,
} from '../schemas/capsule.schemas';
import { CapsuleApi } from '../services/api/capsule/capsule.api';
import { CapsuleService } from '../services/capsule.service';


const logger = new Logger('CapsuleFacade');

@Injectable({
    providedIn: 'root',
})
export class CapsuleFacade {

    constructor(
        private store$: Store,
        private capsuleApi: CapsuleApi,
        private capsuleService: CapsuleService,
    ) { }

    fetchCapsules(params: CapsuleFindParams): Observable<Capsule[]> {
        return this.capsuleApi.findCapsules(params);
    }

    fetchCapsuleById(id: number): Observable<Capsule> {
        return this.capsuleApi.fetchCapsuleById(id);
    }

    createCapsule(capsule: CapsuleCreate): Observable<Capsule> {
        return this.capsuleService.createCapsule(capsule);
    }

    updateCapsule(id: number, capsule: CapsuleUpdate): Observable<Capsule> {
        return this.capsuleService.updateCapsule(id, capsule);
    }

    deleteCapsule(capsule: Capsule): Observable<boolean> {
        return this.capsuleApi.deleteCapsuleById(capsule.id).pipe(
            map(() => true),
            catchError(() => of(false)),
        );
    }

}
