import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../../../schemas/app.schemas';
import { BreadcrumbService } from '../../../services/breadcrumb.service';


@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {

    breadcrumbs$: Observable<Breadcrumb[]> = this.breadcrumbService.breadcrumbs$;

    constructor(
        private readonly breadcrumbService: BreadcrumbService,
    ) {}

}
