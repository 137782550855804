import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { TelegramFacade } from '../facades/telegram.facade';
import { TelegramBot } from '../schemas/telegram.schemas';


const logger = new Logger('BotResolver');

@Injectable({
    providedIn: 'root',
})
export class TelegramBotResolver implements Resolve<TelegramBot | null> {

    constructor(
        private telegramFacade: TelegramFacade,
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<TelegramBot | null> {
        const botId = Number(route.paramMap.get('botId'));
        logger.debug('botId', botId);
        logger.debug('resolve (botId)', botId);

        return this.telegramFacade.fetchTelegramBotById(botId);
    }
}
