<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

  <div class="form-group">
    <div class="title-form-group">Название сервиса</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="title"
             placeholder="Введите название..."
             required>
      <span class="invalid-feedback"
            *ngIf="form.controls['title'].invalid && form.controls['title'].touched">
        Некорректно заполненное поле
        <span *ngIf="form.controls['title'].hasError('unique')">(такой уже есть)</span>
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Капсула (ID капсулы)</div>
    <div class="input-group">
      <input type="text"
             class="form-control"
             formControlName="capsuleId"
             placeholder="0">
      <span class="invalid-feedback"
            *ngIf="form.controls['capsuleId'].invalid && form.controls['capsuleId'].touched">
        Некорректно заполненное поле
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="checkbox">
      <input type="checkbox" formControlName="isPublic" id="isPublic">
      <label for="isPublic" style="margin-left: 15px">
        <i></i>
        <span>Публичный</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="title-form-group">Манифест</div>
    <textarea formControlName="manifest" class="form-control" cols="30" rows="5"></textarea>
  </div>

  <div class="form-group">
    <button class="btn btn-primary"
            [disabled]="cannotSubmit || form.invalid || !!formError">
      Сохранить
    </button>
    <button class="btn btn-secondary" (click)="onFormCancel()">Отмена</button>
    <div *ngIf="formError">{{ formError.message }}</div>
  </div>

</form>
