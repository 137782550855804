<div *ngIf="location" class="buttons">
    <button class="btn btn-primary" [routerLink]="['edit']">
        Редактировать локацию
    </button>
    <button class="btn btn-secondary" [routerLink]="['licenses', 'add']">
        Добавить виджет
    </button>
</div>

<app-license-list [params]="licenseFindParams" (licenseClick)="onLicenseClick($event)"></app-license-list>
