import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { WidgetPackageAddComponent } from './widget-package-add/widget-package-add.component';
import { WidgetPackageDetailComponent } from './widget-package-detail/widget-package-detail.component';
import { WidgetPackageEditComponent } from './widget-package-edit/widget-package-edit.component';
import { WidgetPackageFormComponent } from './widget-package-form/widget-package-form.component';
import { WidgetPackageListComponent } from './widget-package-list/widget-package-list.component';
import { WidgetPackagePageComponent } from './widget-package-page/widget-package-page.component';
import { WidgetPackageWidgetsLinkComponent } from './widget-package-widgets-link/widget-package-widgets-link.component';
import { WidgetPackageWidgetsComponent } from './widget-package-widgets/widget-package-widgets.component';


const components = [
    WidgetPackageAddComponent,
    WidgetPackageDetailComponent,
    WidgetPackageEditComponent,
    WidgetPackageFormComponent,
    WidgetPackageListComponent,
    WidgetPackagePageComponent,
    WidgetPackageWidgetsLinkComponent,
    WidgetPackageWidgetsComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
    ],
    exports: [
        ...components,
    ],
})
export class WidgetPackageModule {}
