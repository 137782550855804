<div class="geo-primitives">
    <button (click)="goToAddGeoPrimitive()" class="btn btn-primary">Добавить GEO примитив</button>

    <ui-simple-infinite-scroll (scrolled)="fetchContent()">

        <div slot="list">
            <div *ngFor="let geo of geoPrimitives" class="geo">
                <div class="geo__wrapper" (click)="goToEditGeoPrimitive(geo.id)">
                    <div class="geo__id">{{geo.id}}</div>
                    <ui-geo-primitive [geo]="geo"></ui-geo-primitive>
                </div>
                <div class="geo__tools">
                    <div class="geo__desc">Capsule: <span>{{geo.capsuleId}}</span></div>
                    <div class="geo__delete" (click)="deleteGeo(geo)">Удалить</div>
                </div>
            </div>
        </div>

        <div *ngIf="isLoading" class="loading" slot="loading">
            <ui-simple-loading></ui-simple-loading>
        </div>

    </ui-simple-infinite-scroll>
</div>
