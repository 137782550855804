import {
    Component,
    OnInit,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { RecommendationFacade } from '../../../facades/recommendation.facade';


const logger = new Logger('RecommendationAddComponent');

@Component({
    selector: 'app-recommendation-add',
    templateUrl: './recommendation-add.component.html',
    styleUrls: ['./recommendation-add.component.scss'],
})
export class RecommendationAddComponent implements OnInit {

    cannotSubmit = false;
    formError?: schemas.ResponseError;

    constructor(
        private recommendationFacade: RecommendationFacade,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {}

    onFormSubmit(formData: schemas.RecommendationCreate): void {
        logger.debug('onFormSubmit', formData);

        this.cannotSubmit = true;
        this.recommendationFacade.createRecommendation(formData).subscribe(
            () => this.router.navigate(['/recommendation']),
            error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        );
    }

    goBack(): void {
        this.router.navigate(['..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
