import { Injectable } from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Location } from '../../../schemas/location.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('RecommendationApi');

@Injectable({
    providedIn: 'root',
})
export class RecommendationApi {

    constructor(
        private apiService: ApiService,
    ) {
    }

    fetchRecommendations(params: schemas.RecommendationFindParams): Observable<schemas.Recommendation[] | null> {
        return this.apiService.getJson<schemas.Recommendation[] | null>(
            '/recommendation',
            params,
        ).pipe(
            tap(recommendations => logger.debug('fetchRecommendations (recommendations)', recommendations)),
        );
    }

    fetchRecommendationLocations(params: schemas.RecommendationFindParams): Observable<Location[] | null> {
        return this.apiService.getJson<Location[] | null>(
            '/recommendation/location',
            params,
        ).pipe(
            tap(locations => logger.debug('fetchRecommendationLocations (locations)', locations)),
        );
    }

    fetchRecommendationById(id: number): Observable<schemas.Recommendation | null> {
        return this.apiService.getJson<schemas.Recommendation | null>(
            '/recommendation/' + id,
        ).pipe(
            tap(recommendation => logger.debug('fetchRecommendationById (recommendation)', recommendation)),
        );
    }

    createRecommendation(data: schemas.RecommendationCreate): Observable<schemas.Recommendation> {
        return this.apiService.postJson<schemas.Recommendation>(
            '/recommendation',
            data,
        ).pipe(
            tap(recommendation => logger.debug('createRecommendation (recommendation)', recommendation)),
        );
    }

    updateRecommendationById(id: number, data: schemas.RecommendationUpdate): Observable<schemas.Recommendation> {
        return this.apiService.patchJson<schemas.Recommendation>(
            '/recommendation/' + id,
            data,
        ).pipe(
            tap(recommendation => logger.debug('updateRecommendation (recommendation)', recommendation)),
        );
    }

    deleteRecommendationById(id: number): Observable<void> {
        return this.apiService.deleteJson<void>(
            '/recommendation/' + id,
        ).pipe(
            tap(() => logger.debug('deleteRecommendationById (id)', id)),
        );
    }
}
