import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { Capsule } from '../schemas/capsule.schemas';
import { CapsuleApi } from '../services/api/capsule/capsule.api';


const logger = new Logger('CapsuleResolver');

@Injectable({
    providedIn: 'root',
})
export class CapsuleResolver implements Resolve<Capsule> {

    constructor(
        private capsuleApi: CapsuleApi,
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<Capsule> {
        const capsuleId = Number(route.paramMap.get('capsuleId'));

        logger.debug('resolve (capsuleId)', capsuleId);

        return this.capsuleApi.fetchCapsuleById(capsuleId);
    }

}
