import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SafePipe } from './safe.pipe';


const components = [
    SafePipe,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
    ],
    exports: [...components],
})
export class SafePipeModule {}
