import { Component } from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { map } from 'rxjs/operators';
import { ServiceFacade } from '../../../facades/service.facade';
import {
    Service,
    ServiceUpdate,
} from '../../../schemas/service.schemas';


const logger = new Logger('ServiceEditComponent');

@Component({
    selector: 'app-service-edit',
    templateUrl: './service-edit.component.html',
    styleUrls: ['./service-edit.component.scss'],
})
export class ServiceEditComponent {

    readonly service$ = this.route.data.pipe(
        map((data: { service?: Service }) => data.service),
    );

    cannotSubmit = false;
    formError?: schemas.ResponseError;

    constructor(
        private serviceFacade: ServiceFacade,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    onFormSubmit(formData: ServiceUpdate & { id: number }): void {
        logger.debug('onFormSubmit', formData);

        this.cannotSubmit = true;
        this.serviceFacade.updateService(formData.id, formData).subscribe({
            next: this.goBack.bind(this),
            error: error => {
                this.formError = error;
                this.cannotSubmit = false;
            },
        });
    }

    goBack(): void {
        this.router.navigate(['..'], {relativeTo: this.route})
            .catch(reason => logger.error('Navigate failed', reason));
    }

}
