import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
    User,
    UserFindParams,
} from '../../../schemas/user.schemas';


@Component({
    selector: 'app-user-page',
    templateUrl: './user-page.component.html',
    styleUrls: ['./user-page.component.scss'],
})
export class UserPageComponent {

    userFindParams: UserFindParams = {
        isGuest: false,
        limit: 100,
    };

    constructor(
        private router: Router,
    ) { }

    onUserClick(user: User): void {}

}
