import { Routes } from '@angular/router';
import { WidgetAddComponent } from '../modules/widget/widget-add/widget-add.component';
import { WidgetEditComponent } from '../modules/widget/widget-edit/widget-edit.component';
import { WidgetIframeFactoryComponent } from '../modules/widget/widget-iframe-factory/widget-iframe-factory.component';
import { WidgetPageComponent } from '../modules/widget/widget-page/widget-page.component';
import { WidgetResolver } from '../resolvers/widget.resolver';


export const widgetsRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: WidgetPageComponent,
    },
    {
        path: 'add',
        component: WidgetAddComponent,
        data: {
            breadcrumb: 'Добавление виджета',
        },
    },
    {
        path: 'iframe-factory',
        component: WidgetIframeFactoryComponent,
        data: {
            breadcrumb: 'Конструктор iframe виджета',
        },
    },
    {
        path: ':widgetId',
        runGuardsAndResolvers: 'always',
        resolve: {
            widget: WidgetResolver,
        },
        data: {
            breadcrumb: (data: any) => `${data.widget.title}`,
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'edit',
            },
            {
                path: 'edit',
                component: WidgetEditComponent,
                data: {
                    breadcrumb: 'Редактирование',
                },
            },
        ],
    },
];
