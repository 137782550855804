import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { LocationWidget } from '../schemas/location.schemas';
import { LocationApi } from '../services/api/capsule/location.api';


const logger = new Logger('LocationWidgetResolver');

@Injectable({
    providedIn: 'root',
})
export class LocationWidgetResolver implements Resolve<LocationWidget> {

    constructor(
        private locationApi: LocationApi,
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<LocationWidget> {
        const locationWidgetId = Number(route.paramMap.get('locationWidgetId'));

        logger.debug('resolve (locationWidgetId)', locationWidgetId);

        return this.locationApi.fetchLocationWidgetById(locationWidgetId);
    }

}
