<div class="geo-routes">
    <button routerLink="/geo/route/add" class="btn btn-primary">Добавить маршрут</button>

    <ui-simple-infinite-scroll (scrolled)="fetchContent()">

        <div slot="list">
            <div *ngFor="let route of geoRoutes" class="geo-route">
                <ui-geo-route [geoRoute]="route" (click)="goToEditGeoRoute(route)"></ui-geo-route>
                <div class="geo-route__delete" (click)="deleteGeoRoute(route)">Удалить</div>
            </div>
        </div>

        <div *ngIf="isLoading" class="loading" slot="loading">
            <ui-simple-loading></ui-simple-loading>
        </div>

    </ui-simple-infinite-scroll>
</div>
