import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Location } from '../../../schemas/location.schemas';


@Component({
    selector: 'ui-location',
    templateUrl: './location.component.html',
    styles: [':host { min-width: 0 }'],
    styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {

    @Input() location?: Location;

    constructor() { }

    ngOnInit(): void {
    }

}
