import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { Location } from '../../../../schemas/location.schemas';


const logger = new Logger('LocationFormComponent');

@Component({
    selector: 'app-location-form',
    templateUrl: './location-form.component.html',
    styleUrls: ['./location-form.component.scss'],
})
export class LocationFormComponent implements OnInit, OnChanges, OnDestroy {

    subscriptions: Subscription[] = [];
    form: FormGroup;

    @Input() location?: Location | null;
    @Input() cannotSubmit = false;
    @Input() formError?: schemas.ResponseError;

    @Output() formSubmit = new EventEmitter();
    @Output() formCancel = new EventEmitter();

    constructor() {

        this.form = new FormGroup({

            title: new FormControl('', [
                Validators.required,
            ]),

            rubric: new FormControl(''),

            icon: new FormControl(null),

            parentId: new FormControl(0, [
                Validators.pattern('\\d+'),
            ]),

            geoId: new FormControl(null, [
                Validators.pattern('[1-9]\\d*'),
            ]),

            ordering: new FormControl(''),

            lat: new FormControl(null),
            lon: new FormControl(null),

            isShowStruct: new FormControl(false),
            isShowMap: new FormControl(false),
            isEmpty: new FormControl(false),
            isPublic: new FormControl(false),
            isDynamic: new FormControl(false),
            manifest: new FormControl(''),
        });

    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.form.valueChanges.subscribe(this.onFormChange.bind(this)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.location?.currentValue) {
            const manifest = JSON.stringify(changes.location?.currentValue.manifest, null, 4);
            this.form.patchValue({
                ...changes.location?.currentValue,
                manifest,
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onFormChange(values: { [key: string]: any }): void {
        this.formError = undefined;
    }

    onFormSubmit(): void {
        const parentId = this.form.value.parentId;
        const manifest = JSON.parse(this.form.value.manifest || '{}');
        const result = {
            ...this.location,
            ...this.form.value,
            manifest,
        };

        if (parentId) {
            result.parentId = parseInt(parentId, 10);
        }

        this.formSubmit.emit(result);
    }

    onFormCancel(): void {
        this.formCancel.emit();
    }

}
