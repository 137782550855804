import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    Authorization,
    Device,
} from '../../../schemas/auth.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('DeviceAuthApi');

@Injectable({
    providedIn: 'root',
})
export class DeviceAuthApi {

    constructor(
        private apiService: ApiService,
    ) { }

    authorize(): Observable<Authorization> {
        return this.apiService.postJson<Authorization>(
            '/authentication/device/authorize',
        ).pipe(
            tap(authorization => logger.debug('authorize (authorization)', authorization)),
        );
    }

    createDevice(): Observable<Device> {
        return this.apiService.postJson<Device>(
            '/authentication/device/hash',
        ).pipe(
            tap(device => logger.debug('createDevice (device)', device)),
        );
    }

}
