import {
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { AuthFacade } from '../../facades/auth.facade';
import { CurrentUser } from '../../schemas/auth.schemas';
import { selectCurrentUser } from '../../store/selectors/auth.selectors';
import { LayoutService } from '../layout.service';


@Component({
    selector: 'app-layout-iterra-navbar',
    templateUrl: './layout-iterra-navbar.component.html',
    styles: [':host { display: block; }'],
    styleUrls: ['./layout-iterra-navbar.component.scss'],
})
export class LayoutIterraNavbarComponent {

    readonly currentUser$: Observable<CurrentUser | null> = this.store$.select(
        selectCurrentUser,
    );

    isRTL: boolean;

    @Input() sidenavToggle = true;
    @HostBinding('class.layout-navbar') hostClassMain = true;

    constructor(
        private readonly appService: AppService,
        private readonly layoutService: LayoutService,
        private readonly store$: Store,
        private readonly authFacade: AuthFacade,
    ) {
        this.isRTL = appService.isRTL;
    }

    currentBg(): string {
        return `bg-${this.appService.layoutNavbarBg}`;
    }

    toggleSidenav(): void {
        this.layoutService.toggleCollapsed();
    }

    signOut(): void {
        this.authFacade.signOut();
    }

}
