<div>

  <div class="content">
    <div *ngFor="let widget of widgets">
      <div class="widget">
        <div class="widget__info">
          <div class="widget__number">
            {{ widget.id }}
          </div>
          <div (click)="onWidgetClick(widget)" class="widget__title">
            {{ widget.title }}
          </div>
          <div class="widget__number">({{ widget.nickname }})</div>
          <div class="widget__number">({{ getWidgetType(widget.typeId) }})</div>
        </div>
        <span class="widget__delete" (click)="delete(widget)">удалить</span>
      </div>
    </div>
  </div>

</div>
