import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidenavModule } from '../../../../../vendor/libs/sidenav/sidenav.module';
import { BreadcrumbModule } from '../modules/breadcrumb/breadcrumb.module';
import { LayoutIterraNavbarComponent } from './layout-iterra-navbar/layout-iterra-navbar.component';
import { LayoutIterraSidenavComponent } from './layout-iterra-sidenav/layout-iterra-sidenav.component';
import { LayoutIterraComponent } from './layout-iterra/layout-iterra.component';
import { LayoutService } from './layout.service';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        SidenavModule,
        BreadcrumbModule,
    ],
    declarations: [
        LayoutIterraComponent,
        LayoutIterraSidenavComponent,
        LayoutIterraNavbarComponent,
    ],
    providers: [
        LayoutService,
    ],
})
export class LayoutModule {}
